import React, { Component } from 'react'
import Neu, { DropDown } from './neu'

import './styles/propertySelector.scss'
import next from '../assets/images/next.svg'
import Next from './svg/next'
import ModalContext from '../context/modal'
// import Refresh from '../'
import getSafe from '../utility/getSafe'

import API from '../api'

const DropItem = (props) => 
	<div onClick={props.onClick} className={'DropItem'}>
		<h5>{props.name}</h5>
	</div>

const list = [{ name: 'DS Sinabung' },{ name: 'DS Layur' }]

class AddPropertyModal extends Component{
	state = {
		nameInput			: '',
		addressInput	: '',
		xCoordinates	: '',
		yCoordinates	: '',
	}
	addProperty = async () => {
		try{
			const data = {
				name					: this.state.nameInput,
				address				: this.state.addressInput,
				xcoordinates	: this.state.xCoordinates,
				ycoordinates	: this.state.yCoordinates,
				latitude			: this.state.xCoordinates,
				longitude			: this.state.yCoordinates,
			}
			console.log(this.props.cowork)
			const result = this.props.cowork === true ? await API.cowork.addCowork(data) : await API.colive.addProperty(data)
			this.props.closeModal()
		}catch(e){ console.error(e) }
	}
	render = () => 
		<div 		className={'AddPropertyModal'}>
			<div 	className={'titleContainer'}>
				<h1>Add Property</h1>
			</div>
			<form onSubmit={(e) => e.preventDefault()} className={'inputContainer'}>
				<div		className={'input'}>	<input onChange={(e) => this.setState({nameInput: e.target.value})} 		type={'text'} 	placeholder={'Name'}/></div>
				<div		className={'input'}>	<input onChange={(e) => this.setState({addressInput: e.target.value})} 	type={'text'} 	placeholder={'Addres'}/></div>
				<div		className={'coordinateInputContainer'}>
					<div 	className={'input'}>	<input onChange={(e) => this.setState({xCoordinates: e.target.value})} 	type={'number'} placeholder={'X coordinates'}/></div>
					<div 	className={'separator'}/>
					<div 	className={'input'}>	<input onChange={(e) => this.setState({yCoordinates: e.target.value})}	type={'number'} placeholder={'Y coordintaes'}/></div>
				</div>
				<div className={'buttonsContainer'}><button onClick={this.addProperty} type={'submit'} className={'add'}><h5>Add</h5></button>
					<div className={'separator'}/>
					<button onClick={() => this.props.closeModal()} type={'reset'} className={'cancel'}><h5>Cancel</h5></button>
				</div>
			</form>
		</div>
}

class PropertySelector extends Component{ 
	constructor(props){
		super(props)
		this.addProperty = this.addProperty.bind(this)
		this.handleMenuClick = this.handleMenuClick.bind(this)
		// this.handleMouseDown = this.handleMouseDown.bind(this)
		this.setRef = this.setRef.bind(this)
	}
	state = {
		active: false
	}
	addProperty = () => {
		try{
			this.props.modal.setModal({ active: true, component: <AddPropertyModal cowork={this.props.cowork}/> })
		}catch(e){ console.error(e) }
	}
	handleMouseDown = (e) => {
		// console.log(this.containerRef)
		if(this.containerRef && !this.containerRef.contains(e.target)){
			e.stopPropagation()
			e.preventDefault()
			this.setState({
				active: !this.state.active
			})
		}
	}
	componentDidUpdate = (prevProps,prevState) => {
		// console.log('compDidUpdate')
		// console.log('prevState',prevState)
		// console.log(this.state)
		if(this.state.active){
			this.eventListener = document.addEventListener('mousedown', this.handleMouseDown)
		}else if(!this.state.active){
			document.removeEventListener('mousedown', this.handleMouseDown)
			this.eventListener = null
		}
	}
	handleMenuClick = (e) => {
		e.preventDefault()
		e.stopPropagation()
		this.setState({
			active: !this.state.active
		})
	}
	setRef = (ref) => this.containerRef = ref 
	render = () => 
		<Neu 
			setRef={this.setRef}
			toggle={false}
			onClick={this.handleMenuClick}
			className={`PropertySelector 
				${this.props.className ? this.props.className : ''}
				${this.state.active ? 'active': ''}`}>
			<div className={'labelContainer'}>
				<h5>{
					this.props.selected < 0 ? 
						'Choose property' : 
						getSafe(() => this.props.property[this.props.selected].name,'')}
				</h5>
			</div>
			<div className={'dropIconContainer'}>
				{/* <img src={next}/> */}
				<Next style={{
					width: 15,
					height: 15
				}}/>
			</div>
			
			<DropDown>
			{	getSafe(() => this.props.property,[]).map((object,index) => 
					<DropItem 
						onClick={() => {
							this.props.setSelected(index)
							this.setState({
								active: false
							})
						}}
						key={index}
						name={object.name}/>)}
				<div 
					onClick={ () => this.addProperty()}
					className={`DropItem addButton`}>
					<h5>Add property</h5>
				</div>
			</DropDown>
		</Neu>
}

export default (props) => 
	<ModalContext.Consumer>{
		value => <PropertySelector {...props} modal={value}/>
	}</ModalContext.Consumer>
