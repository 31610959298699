import { TotalOverviewData } from "../components/OverviewTableView"
import formatRupiah from "../utility/rupiahFormator"

export type TableMeta = {
  title:      string
  key:        string
  processor?: Function
  total?: {
    key: keyof TotalOverviewData,
    processor?: Function
  }
}

const TABLE_VIEW_BASE: TableMeta[] = [{
  title: 'Hotel',
  key: 'name'
}, {
  title: "Type",
  key: 'unitTypeName'
}]

const TOTAL_ROOM_META: TableMeta =  {
  title: "Total Room",
  key: 'totalRoom',
  total: {
    key: 'totalRoom'
  }
}

const DAILY_ROOM_BOOKED_META: TableMeta = {
  title: "Daily Room Booked",
  key: 'dailyRoomBooked',
  total: {
    key: 'totalDailyBooked'
  }
}

const DAILY_ROOM_RATE_META: TableMeta = {
  title: "Daily Room Rate",
  key: 'dailyRoomRate',
  processor: (data: any) => formatRupiah(Math.floor(data)),
}

const DAILY_REVENUE_META: TableMeta = {
  title: "Daily Revenue",
  key: 'dailyRevenue',
  processor: (data: any) => formatRupiah(Math.floor(data)) + ',00',
  total: {
    key: 'totalDailyRevenue',
    processor: (data: any) => formatRupiah(Math.floor(data))
  }
}

const MONTHLY_ROOM_BOOKED_META: TableMeta = {
  title: "Monthly Room Booked",
  key: 'monthlyRoomBooked',
  total: {
    key: 'totalMonthlyBooked'
  }
}

const MONTHLY_ROOM_RATE_META: TableMeta = {
  title: "Monthly Room Rate",
  key: 'monthlyRoomRate',
  processor: (data: any) => formatRupiah(Math.floor(data))
}

const MONTHLY_REVENUE_META: TableMeta = {
  title: "Monthly Revenue",
  key: 'monthlyRevenue',
  processor: (data: any) => formatRupiah(Math.floor(data)) + ',00',
  total: {
    key: 'totalMonthlyRevenue',
    processor: (data: any) => formatRupiah(Math.floor(data))
  }
}

const FREE_ROOM_META: TableMeta = {
  title: "Free",
  key: 'free',
  total: {
    key: 'totalFree'
  }
}

const RENOV_META: TableMeta = {
  title: "Renov",
  key: 'renov',
  total: {
    key: 'totalRenov'
  }
}

const EMPTY_ROOM_META: TableMeta = {
  title: "Empty Room",
  key: 'emptyRoom',
  total: {
    key: 'totalEmpty'
  }
}

const TOTAL_ROOM_BOOKED_META: TableMeta = {
  title: "Total Room Booked",
  key: 'totalRoomBooked',
  total : {
    key: 'totalBooked'
  }
}

const AVERAGE_ROOM_RATE_META: TableMeta = {
  title: "Average Room Rate",
  key: 'avgRoomRate',
  processor: (data: any) => formatRupiah(Math.floor(data)) + ',00',
  total: {
    key: 'averageRoomRate',
    processor: (data: any) => formatRupiah(Math.floor(data))
  }
}

const OCC_RATE_META: TableMeta = {
  title: "Occ. Rate",
  key: 'occRate',
  processor: (data: any) => String(Math.floor(data*100)) + '%',
  total: {
    key: 'averageOccRate',
    processor: (data: any) => Math.floor(data*100) + '%'
  }
}

const REVENUE_META: TableMeta = {
  title: "Total Revenue",
  key: "revenue",
  processor: (data: any) => formatRupiah(Math.floor(data)) + ',00',
  total: {
    processor: (data: any) => formatRupiah(Math.floor(data)),
    key: 'totalRevenue'
  }
}

export const TABLE_META_ALL: TableMeta[]            = [...TABLE_VIEW_BASE, TOTAL_ROOM_META, DAILY_ROOM_BOOKED_META, DAILY_ROOM_RATE_META, DAILY_REVENUE_META, MONTHLY_ROOM_BOOKED_META, MONTHLY_ROOM_RATE_META, MONTHLY_REVENUE_META, FREE_ROOM_META, RENOV_META, EMPTY_ROOM_META, TOTAL_ROOM_BOOKED_META, AVERAGE_ROOM_RATE_META, OCC_RATE_META, REVENUE_META]
export const TABLE_VIEW_TITLE_DAILY: TableMeta[]    = [...TABLE_VIEW_BASE, TOTAL_ROOM_META, DAILY_ROOM_BOOKED_META, DAILY_ROOM_RATE_META, DAILY_REVENUE_META]
export const TABLE_VIEW_TITLE_MONTHLY: TableMeta[]  = [...TABLE_VIEW_BASE,  TOTAL_ROOM_META, MONTHLY_ROOM_BOOKED_META, MONTHLY_ROOM_RATE_META, MONTHLY_REVENUE_META]
