import React, { Component } from 'react'
import ContextMenuContext from '../context/contextMenuContext'

class ContextMenuState extends Component{
	constructor(props){
		super(props)
		this.setContextMenu = this.setContextMenu.bind(this)
	}
	state = {
		active: false,
		component: null,
		x: 0,
		y: 0
	}
	setContextMenu = ({active,component,x,y} = {}) => {
		console.log('settingContextMenu')
		this.setState({
			active: active,
			component: component,
			x: x,
			y: y
		})
	}
	render = () =>
		<ContextMenuContext.Provider value={{
			setContextMenu: this.setContextMenu,
			active: this.state.active,
			component: this.state.component,
			x: this.state.x,
			y: this.state.y
		}}>
			{this.props.children}
		</ContextMenuContext.Provider>
}

export default ContextMenuState