import axios from 'axios'
import route from './route'

import authenticator from '../utility/Authenticator'


// const prod = process.env.NODE_ENV == 'production' ? true : false 
const env = process.env.NODE_ENV
const localhost = true
// console.log(prod)

// const host = 
// 	// * PRODUCTION || ALPHA
// 	(env == 'alpha' || env == 'production') ? 
// 		'https://dev.dse.id/production/api/pms' : 
// 	(env == 'development') ? 
// 		// * DEVELOPMENT
// 		'https://dev.dse.id/api/pms' : 
// 		// * LOCAL | NON EXISTENCE
// 		'http://localhost:4003/pms'

// const host = 'http://localhost:4003/pms'
const host = 
	// process.env.REACT_APP_ENV == 'local' 				? 'http://localhost:4003/pms' : 
	// process.env.REACT_APP_ENV == 'development' 	? 'https://dev.dse.id/development/api/pms' : 
																								'https://dev.dse.id/production/api/pms'

// const host = 'https://dev.dse.id/production/api/pms'

// console.log(process.env.NODE_ENV)

const send = async ({path,method,body,params,query}, raw) => {
	try{
		const user 		= authenticator.getUser()
		const uuid		= authenticator.getUUID()
		// console.log('parameter! : ',body)
		if(path instanceof Function) 	console.log(path(params))
		else if(path) 								console.log(path)
		else 													console.error('Path is empty')
		if(!user) console.error('Not loggedIn..')
		let { token } = user ? user : {} 
		const result 	= await axios({
			// headers: typeof token  === 'string' ? { 
			// 	Authorization: `Bearer ${token}` } : {},
			headers	: {
				Authorization	: token ? `Bearer ${token}` : undefined,
				// uuid				: uuid
				// 'uuid'	: uuid
				'X-Client-ID': uuid
			},
			responseType: raw ? 'blob': undefined,
			baseURL	: host,
			url			: !(path instanceof Function) ? path : path(params),
			// query: query,
			params	: query,
			method	: method,
			data		: body
		})
		// console.log(result)
		// console.log(result.data)
		if(raw){
			const url = window.URL.createObjectURL(new Blob([result.data]));
			// let url = window.URL.createObjectURL(blob);
			let a = document.createElement('a');
			a.href = url;
			a.download = 'pms.xlsx';
			a.click();
		}
		return raw ? result.data || new Error('Result data not found') :  result.data || new Error('Result data not found')
	}catch(e){ return e }
}

const api = {
	registerUUID							: ({ uuid, platform		}) 			=> send({ ...route.registerUUID, 		body: { uuid, platform }			}),
	registerFCM								: ({ token 						}) 			=> send({ ...route.registerFCM, 		body: { token }								}),
	mirrorArray								: ({ data, fileName 	})   		=> send({ ...route.mirrorArray,   	body: { data, fileName}}, true),
	user											: {
		register								: ({ email, password	}) 			=> send({ ...route.user.register, 	body: { email, password } 		}),
		login										: ({ email, password, firebaseToken	}) 			=> send({ ...route.user.login, 			body: { email, password, firebaseToken } 		}),
		bindGoogle 							: ({ email, firebaseToken }) 	=> send({ ...route.user.bindGoogle, body: { email, firebaseToken} }),
		getAllAdmin 						: () => send({ ...route.user.getAllAdminUsers })
	},
	cowork										: {
		getAllCowork						: async ({ page} = {}) 																															=> send({ ...route.cowork.getAllCowork						, params: { 																}, body: { }, query: { } }),
		addCowork								: async ({ name, address, city, description, latitude, longitude} = {}) 						=> send({ ...route.cowork.addCowork								, params: { 																}, body: { name, address, city, description, latitude, longitude } }),
		getCoworkDetail					: async ({ propertyId} = {}) 																												=> send({ ...route.cowork.getCoworkDetail					, params: { propertyId 											}, body: { }, query: { } }),
		editCowork							: async ({ propertyId, name, address, city, description, latitude, longitude} = {}) => send({ ...route.cowork.editCowork							, params: { propertyId 											}, body: { name, address, city, description, latitude, longitude } }),
		deleteCowork						: async ({ propertyId } = {}) 																											=> send({ ...route.cowork.deleteCowork						, params: { propertyId 											}, body: { } }),
		addCoworkUnit						: async ({ propertyId, name											})																	=> send({ ...route.cowork.addCoworkUnit						, params: { propertyId 											}, body: { name 						}, query: { 	} }),
		deleteCoworkUnit				: async ({ propertyId, unitId										})																	=> send({ ...route.cowork.deleteCoworkUnit				, params: { propertyId, unitId 							}, body: {  								}, query: {		} }),
		getAllCoworkUnit				: async ({ propertyId 													})																	=> send({ ...route.cowork.getAllCoworkUnit				, params: { propertyId 											}, body: {  								}, query: { 	} }),
		getCoworkUnitDetail			: async ({ propertyId, unitId										})																	=> send({ ...route.cowork.getCoworkUnitDetail			, params: { propertyId, unitId 							}, body: {  								}, query: { 	} }),
		editCoworkUnitDetail		: async ({ propertyId, unitId, name, unitTypes 	})																	=> send({ ...route.cowork.editCoworkUnitDetail		, params: { propertyId, unitId 							}, body: { name, unitTypes 	}, query: { 	} }),
		addCoworkUnitType				: async ({ propertyId, unitTypeId, name					})																	=> send({ ...route.cowork.addCoworkUnitType				, params: { propertyId, unitTypeId 					}, body: { name 						}, query: { 	} }),
		deleteCoworkUnitType		: async ({ propertyId, unitTypeId								})																	=> send({ ...route.cowork.deleteCoworkUnitType		, params: { propertyId, unitTypeId 					}, body: {  								}, query: { 	} }),
		editCoworkUnitType			: async ({ propertyId, unitTypeId, name, units 	})																	=> send({ ...route.cowork.editCoworkUnitType			, params: { propertyId, unitTypeId 					}, body: { name, units 			}, query: { 	} }),
		getCoworkUnitTypeDetail	: async ({ propertyId, unitTypeId								})																	=> send({ ...route.cowork.getCoworkUnitTypeDetail	, params: { propertyId, unitTypeId 					}, body: {  								}, query: { 	} }),
		getAllCoworkUnitType		: async ({ propertyId														})																	=> send({ ...route.cowork.getAllCoworkUnitType		, params: { propertyId 											}, body: {  								}, query: { 	} }),
		addCoworkRate						: async ({ propertyId, unitTypeId, term 				})																	=> send({ ...route.cowork.addCoworkRate						, params: { propertyId, unitTypeId, 				}, body: {  								}, query: { term	} }),
		deleteCoworkRate				: async ({ propertyId, unitTypeId, term 				})																	=> send({ ...route.cowork.deleteCoworkRate				, params: { propertyId, unitTypeId, 				}, body: {  								}, query: { term	} }),
		editCoworkRate					: async ({ propertyId, unitTypeId, term 				})																	=> send({ ...route.cowork.editCoworkRate					, params: { propertyId, unitTypeId, 				}, body: {  								}, query: { term	} }),
		getAllCoworkRate				: async ({ propertyId, unitTypeId, term 				})																	=> send({ ...route.cowork.getAllCoworkRate				, params: { propertyId, unitTypeId, 				}, body: {  								}, query: { term	} }),
		getCoworkRateDetail			: async ({ propertyId, unitTypeId, term 				})																	=> send({ ...route.cowork.getCoworkRateDetail			, params: { propertyId, unitTypeId, 				}, body: {  								}, query: { term	} }),
	},
	// COLIVE
	colive: {
		// OVERVIEW
		overview								: {
			get : ({ propertyId, page, startDate, endDate}) => send({
				...route.colive.overview.get,
				params: { propertyId },
				query: { startDate, endDate },
				body: {}
			})
		},

		// CRUD
		all											: async ({page} = {}) => {
			const {path,method} = route.colive.getAll
			const res = send({
				...route.colive.getAll,
				body: {}
			})
			console.log(res)
			return res
		},
		detail: ({propertyId}) => send({
			...route.colive.detail,
			params: {propertyId}
				// body: {
				// 	name,
				// 	address,
				// 	xcoordinates,
				// 	ycoordinates
				// }
		}),
		addProperty: ({name,address,xcoordinates,ycoordinates} = {}) => 
			send({
				...route.colive.addProperty,
				body: {
					name,
					address,
					xcoordinates,
					ycoordinates
				}
			}),
		update: {
			updateProperty: ({propertyId,name,address,coordinates}) =>	send({
				...route.colive.update.updateProperty,
				params: {propertyId},
				body: {
					name,
					address,
					coordinates
				}
			}),
			updatePropertyThumbnail: ({}) => {},
			updatePropertyPictures: ({}) => {},
		},
		deleteProperty: ({id}) => {},
		// END CRUD

		// Connect STAAH
		connectStaah: ({id, staahUsername, staahPassword, hotelId}) => {},
		disconnectStaah: ({}) => {},

		// Connect GMB
		connectGMB: ({}) => {},
		disconnectGMB: ({}) => {},

		// UNIT
		unit: {
			// UNIT TYPE
			type: {
				// CRUD
				all: ({propertyId}) => send({
					...route.colive.unit.type.all,
					params: {propertyId}
				}),
				detail: ({}) => {},
				add: ({}) => {},
				edit: ({}) => {},
				delete: ({}) => {},
				disable: ({}) => {},
				// enable: ({}) => {}
			},
			all: ({propertyId}) => send({
				...route.colive.unit.all,
				params: {propertyId}
			}),
			detail: ({}) => {},
			add: ({propertyId, name, unitType}) => send({
				...route.colive.unit.add,
				params: {propertyId},
				body:{
					name: name,
					unitType: unitType,
				}
			}),
			edit: ({propertyId, name, unitType,unitId}) => send({
				...route.colive.unit.edit,
				params: {propertyId,unitId},
				body:{
					name: name,
					unitTypeId: unitType,
				}
			}),
			delete: ({propertyId, unitId}) => send({
				...route.colive.unit.delete,
				params: {propertyId, unitId},
			}),
			disable: ({propertyId, unitId}) => send({
				...route.colive.unit.disable,
				params: {propertyId, unitId},
			}),
			// enable: ({}) => {},
		},

		// RATES
		rates: {
			all: ({propertyId}) => send({
				...route.colive.rates.all,
				params: {propertyId}
			}),
			default: {
				all: () => {},
				add: ({propertyId,unitTypeId,title,price}) => send({
					...route.colive.rates.default.add,
					params: {propertyId,unitTypeId},
					body: {title,price}
				}),
				edit: ({propertyId,unitTypeId,title,price}) => send({
					...route.colive.rates.default.edit,
					params: {propertyId,unitTypeId},
					body: {title,price}
				}),
				delete: ({}) => {},
				disable: ({}) => {},
				enable: ({}) => {}
			},
			manual: {
				getAll: ({}) => {},
				add: ({}) => {},
				edit: ({}) => {},
				delete: ({}) => {},
				disable: ({}) => {},
				enable: ({}) => {}
			},
			static: {
				getAll: ({}) => {},
				add: ({propertyId,unitTypeId,title,price,days}) => send({
					...route.colive.rates.static.add,
					params: {propertyId,unitTypeId},
					body: {title,price,days}
				}),
				edit: ({propertyId,unitTypeId,title,price,days}) => send({
					...route.colive.rates.static.edit,
					params: {propertyId,unitTypeId},
					body: {title,price,days}
				}),
				delete: ({unitTypeId,propertyId,rateId}) => send({
					...route.colive.rates.static.delete,
					params: {propertyId, unitTypeId,rateId}
				}),
				disable: ({unitTypeId,propertyId,rateId}) => send({
					...route.colive.rates.static.disable,
					params: {propertyId, unitTypeId,rateId}
				}),
				enable: ({}) => {},
			},
			dynamic: {
				getAll: ({}) => {},
				add: ({propertyId,unitTypeId,title,price,days,fallbackPrice,timeTrigger,useAvailability,command,threshold}) => send({
					...route.colive.rates.dynamic.add,
					params: {propertyId,unitTypeId},
					body: {
						title,
						price,
						days,
						fallbackPrice,
						timeTrigger,
						useAvailability,
						command,
						threshold
					}
				}),
				edit: ({propertyId,unitTypeId,title,price,days,fallbackPrice,timeTrigger,useAvailability,command,threshold}) => send({
					...route.colive.rates.dynamic.edit,
					params: {propertyId,unitTypeId},
					body: {
						title,
						price,
						days,
						fallbackPrice,
						timeTrigger,
						useAvailability,
						command,
						threshold
					}
				}),
				delete: ({unitTypeId,propertyId,rateId}) => send({
					...route.colive.rates.dynamic.delete,
					params: {propertyId, unitTypeId,rateId}
				}),
				disable: ({unitTypeId,propertyId,rateId}) => send({
					...route.colive.rates.dynamic.disable,
					params: {propertyId, unitTypeId,rateId}
				}),
				enable: ({}) => {}
			},
		},

		// nRates
		nrates: {
			get: ({propertyId,unitTypeId, startDate, endDate, monthly = false} = {}) => send({
				...route.colive.nrates.get,
				params: {propertyId},
				query: {startDate,endDate,unitTypeId,monthly}
			}),
			update: ({propertyId,unitTypeId,startDate,endDate,days,price,monthly} = {}) => send({
				...route.colive.nrates.update,
				params: {propertyId,unitTypeId},
				body:{
					startDate,
					endDate,
					days,
					price,
					monthly
				}
			}),
		},

		// ELECTRICITY
		electricity: {
			getAll: ({}) => {},
			getDetail: ({}) => {},
			add: ({}) => {},
			edit: ({}) => {},
			delete: ({}) => {},
		},

		// BOOKING
		booking: {
			all: ({propertyId, startDate, endDate}) => send({
				...route.colive.booking.all,
				params: { propertyId },
				query: { startDate, endDate },
				body: {}
			}),
			add: ({ propertyId, customer, term, roomRequests, allocate, insurance, price, checkInDate, checkOutDate, ota, otaBookingId }) => send({
				...route.colive.booking.add,
				params: { propertyId },
				body: { customer,term, roomRequests, allocate, insurance, price, checkInDate, checkOutDate, ota, otaBookingId },

			}),
			checkOut: ({propertyId, bookingId}) => send({
				...route.colive.booking.checkOut,
				params: { propertyId },
				body: { bookingId },
			}),
			delete: ({propertyId, bookingId}) => send({
				...route.colive.booking.delete,
				params: {propertyId, bookingId}
			})
		},

		// ? AVAILABILITY
		availability: {
			get: ({propertyId,startDate,endDate,unitTypeId}) => send({
				...route.colive.availability.get,
				params: {propertyId},
				query: {
					startDate,
					endDate,
					unitTypeId
				}
			}),
			assignUnit: (({ propertyId, bookingId, unitsAssignment, reAssign }) => send({
				...route.colive.availability.assignUnit,
				params: { propertyId },
				body: {
					bookingId,
					unitsAssignment,
					reAssign
				}
			}))
		},

		// REPORT
		report: {
			all: ({propertyId, startDate,endDate}) => send({
				...route.colive.report.all,
				params: { propertyId },
				query: {startDate, endDate},
				body: {}
			}),
			download: ({propertyId, startDate, endDate}, raw) => send({
				...route.colive.report.download,
				params: { propertyId },
				query: {
					startDate,
					endDate
				},
				body: {}
			},raw)
		}
	}
}

export default api