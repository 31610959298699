import React,{useContext} from "react"
import {
//   BrowserRouter as Router,
//   Switch,
  Route,
//   Link,
  Redirect,
//   useHistory,
//   useLocation
} from "react-router-dom"
import Authenticator from '../utility/Authenticator'
import AuthContext from '../context/auth'

const PrivateRoute = ({ auth, children, ...rest }) => 
	(auth.loggedIn === undefined ? null :
		<Route
			{...rest}
			render={({ location }) =>
				auth.loggedIn ? children : 
					<Redirect to={{
						pathname: "/login",
						// state: { from: location }
					}}/>
			}/>)


export default (props) =>
	<AuthContext.Consumer>
	{
		(value) => <PrivateRoute auth={value} {...props}/>
	}	
	</AuthContext.Consumer>
	