import React, { Component } from 'react'
import Neu from './neu'

import './styles/tabMenu.scss'

export class Tab extends Component{
	constructor(props){
		super(props)
	}
	render = () => 
		<Neu 
			state={false} 
			className={`Tab ${this.props.active? 'active' : ''}`}
			onClick={() => this.props.onClick()}>
			<h5>{this.props.label}</h5>
		</Neu>
}

class TabMenu extends Component{
	constructor(props){
		super(props)
	}
	render = ( ) => 
		<div className={'TabMenu'}>
		<div className={'scrollHack'}>
		{this.props.children}
		</div>
		</div>
}

export default TabMenu