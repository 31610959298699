import api from "../../api";
import method from "../../api/route/method";
import { APIRoute } from ".";

export const getColiveOverviewRoute: APIRoute = {
  path: '/analytics/getColiveOverview',
  method: 'GET'
}
export const getDownloadAllAnalyticsRoute: APIRoute = {
  path: '/analytics/getDownloadAllAnalytics',
  method: 'GET'
}
export const getAllOccRateRoute: APIRoute = {
  path: '/analytics/getAllOccRate',
  method: 'GET'
}