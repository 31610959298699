import React, { Component } from 'react'

interface IProps{
	command	: 'ADD' | 'EDIT' | 'DELETE' | 'BIND',
	index?	: number,
	data? 	: object[],
	action	: Function,
	close		: Function
}

class CoworkUnitModal extends Component<IProps>{
	constructor(props: IProps){ super(props) }
	componentDidMount = () => console.log('COWORK UNIT MODAL', this.props)
	render = () => 
		<div 
			className={'main_bg dropShadow modalPadding radius'} 
			style={{ }}>
			<div style={{}}><h2>{this.props.command}</h2></div>
			{/* {
				this.props.command === 'EDIT' ? 
				<UnitSelector 
					onChange={this.onChangeSelect}
					placeholder={'Choose unit type'}
					unit={false}
					style={{ marginLeft: 0 }}
					data={this.props.unitTypes}/> : null
			} */}
			<input 
				style={{
					marginTop			: 16,
					width					: 'auto',
					borderRadius	: 5,
					border				: 0,
					padding				: 8
				}}
				value={''}
				onChange={(e) => this.setState({ nameInput: e.target.value })}
				placeholder={'name'}/>
			<div 
				onClick={(e) => this.props.action( '', this.props.command === 'EDIT' ? 1:  1)}
				className={'cursor pointer'}
				style={{
					width						: 'auto',
					backgroundColor	: 'white',
					borderRadius		: 5,
					marginTop				: 16,
					display					: 'flex',
					justifyContent	: 'center',
					alignItems			: 'center',
					padding					: 8
				}}>
				<h5>{this.props.command}</h5>
			</div>
		</div>
}

export default CoworkUnitModal