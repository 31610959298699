import React, { Component } 		from 'react'
import '../../styles/units.scss'

import NeuToggle 								from '../../components/neuToggle'
import Neu 											from '../../components/neu'
import Pencil 									from '../../components/svg/pencil'
import Bin 											from '../../components/svg/bin'
import Plus 										from '../../components/svg/plus'

import Table, { ActionButton } 	from '../../components/table'

import ModalContext 						from '../../context/modal'

import NetworkInfoModal 				from '../../components/modals/networkInfoModal'
import ConfirmDeleteModal 			from '../../components/modals/confirmDeleteModal'
import AddUnitModal 						from '../../components/modals/addUnitModal'
import CoworkUnitTypeModal 			from '../../components/modals/cowork/CoworkUnitTypeModal'
import CoworkUnitModal					from '../../components/modals/cowork/CoworkUnitModal'
import EditUnitModal 						from '../../components/modals/editUnitModal'

import API 											from '../../api'

import getSafe 									from '../../utility/getSafe'

import Label										from '../../components/label'

const ActionContainer = (props) => 
	<div className={'actionContainer'}>
		<Neu 
			onClick={() => props.onEdit(props.index)}
			className={'actionButton'}
			state={false}>
			<Pencil/>
		</Neu>
		<Neu 
			onClick={() => props.onDelete(props.index)}
			className={'actionButton'}
			state={false}>
			<Bin/>
		</Neu>
	</div>

const DataTable = (props) => 
	<div className={'tableContainer'}>
		<div className={'tableInnerContainer'}>
		<Table {...props}/>
		</div>
	</div>

class Units extends Component{
	constructor(props){
		super(props)
		// * UNIT
		this.handleAddUnit 							= this.handleAddUnit.bind(this)
		this.handleUnitStatusChange 		= this.handleUnitStatusChange.bind(this)
		this.handleUnitDelete 					= this.handleUnitDelete.bind(this)
		this.handleUnitEdit 						= this.handleUnitEdit.bind(this)
		// *UNIT TYPE
		this.handleAddUnitType 					= this.handleAddUnitType.bind(this)
		this.handleUnitTypeStatusChange = this.handleUnitTypeStatusChange.bind(this)
		this.handleUnitTypeDelete 			= this.handleUnitTypeDelete.bind(this)
		this.handleUnitTypeEdit 				= this.handleUnitTypeEdit.bind(this)
		// * CONFIRM UNIT
		this.confirmAddUnit 						= this.confirmAddUnit.bind(this)
		this.confirmDeleteUnit 					= this.confirmDeleteUnit.bind(this)
		this.confirmEditUnit 						= this.confirmEditUnit.bind(this)
		// *CONFIRM UNIT TYPE
		this.confirmAddUnitType 				= this.confirmAddUnitType.bind(this)
		this.confirmDeleteUnitType 			= this.confirmDeleteUnitType.bind(this)
		this.confirmEditUnitType 				= this.confirmEditUnitType.bind(this)

		this.closeModal = this.closeModal.bind(this)
	}
	state = { unit: [], unitType: [], loading: false }
	// * COMPONENT LIFECYCLE
	componentDidMount 	= () => { this.loadData() }
	componentDidUpdate 	= (prevProps,prevState) => { if(prevProps != this.props) this.loadData() }
	// * HANDLE LOAD DATA
	loadData = async () => {
		try{
			console.log('Loading unit Data')
			const propertyId 			= getSafe(() => this.props.property._id, undefined)
			if(!propertyId) throw new Error('Property id is undefined')
			// * UNIT AND UNIT TYPE PROMISES
			const promises 				= Promise.all([API.cowork.getAllCoworkUnit({ propertyId: propertyId }), API.cowork.getAllCoworkUnitType({ propertyId: propertyId })])
			const results 				= await promises
			const unitResult 			= results[0]
			const unitTypeResult 	= results[1]
			if(unitResult 		instanceof Error) throw unitResult
			if(unitTypeResult instanceof Error) throw unitTypeResult
			this.setState({ unit: unitResult.data, unitType: unitTypeResult.data })
		}catch(e){ console.error(e) }
	}
	closeModal = () => this.props.modal.setModal({ active: false, component: null })
	// * CONFIRM FUNCTIONS
	confirmAddUnit = async (name,selectedIndex) => {
		try{
			console.log(this.props)
			const propertyId 			= getSafe(() => this.props.property._id, undefined)
			if(!propertyId) throw new Error('Property id is undefined')
			this.setState({ loading: true })
			this.props.modal.setModal({ active: true, component: <NetworkInfoModal loading={true}/> })
			const result = await API.cowork.addCoworkUnitType({ propertyId: propertyId, unitType: undefined, name: name })
			console.log(result)
			this.setState({loading: false})
			if(result instanceof Error){
				this.props.modal.setModal({ active: true, component: <NetworkInfoModal loading={false} closeModal={this.closeModal} error={true} errorResponse={result.response}/> })
				throw result
			}
			this.closeModal()
			this.setState({ unit: [ ...this.state.unit, result.data ] })
		}catch(e){
			this.props.modal.setModal({ active: false, component: null })
			console.error(e)
		}
	}
	confirmDeleteUnit = async (index) => {
		try{
			this.setState({ loading: true})
			this.props.modal.setModal({ active: true, component: <NetworkInfoModal loading={true}/> })
			const result = await API.cowork.deleteCoworkUnit({ propertyId: this.props.property._id, unitId: this.state.unit[index].id })
			if(result instanceof Error){
				this.props.modal.setModal({ active: true, component: <NetworkInfoModal loading={false} closeModal={this.closeModal} error={true} errorResponse={result.response}/> })
				throw result
			}
			this.closeModal()
			this.setState({ loading: false })
		}catch(e){ console.error(e) }
	}
	confirmEditUnit = async (name,unitIndex,selectedIndex) => {
		try{
			this.setState({loading: true})
			this.props.modal.setModal({ active: true, component: <NetworkInfoModal loading={true}/> })
			const result = await API.cowork.editCoworkUnitDetail({
				propertyId	: this.props.property._id,
				unitId			: this.state.unit[unitIndex].id,
				unitType		: this.state.unitType[selectedIndex]._id,
				name				: name
			})
			if(result instanceof Error){
				this.props.modal.setModal({ active: true, component: <NetworkInfoModal loading={false} closeModal={this.closeModal} error={true} errorResponse={result.response}/> })
				throw result
			}
			this.closeModal()
			this.setState({ loading: false })
		}catch(e){ console.error(e) }
	}
	confirmAddUnitType 		= async () => { }
	confirmEditUnitType 	= async (name,_id) => {
		try{
			console.log('Confirm edit unitType')
			console.log('_id',_id)
			console.log('name', name)
			this.setState({ loading: true })
			this.props.modal.setModal({ active: true, component: <NetworkInfoModal loading={true}/> })
			const result = await API.cowork.editCoworkUnitType({
				propertyId	: this.props.property._id,
				unitTypeId	: _id,
				name				: name
			})
			if(result instanceof Error){
				this.props.modal.setModal({ active: true, component: <NetworkInfoModal loading={false} closeModal={this.closeModal} error={true} errorResponse={result.response}/> })
				throw result
			}
			this.closeModal()
			this.setState({ loading: false })
		}catch(e){ console.error(e) }
	}
	confirmDeleteUnitType = async () => { }
	// * UNIT CRUD FUNCTIONS
	
	handleUnitStatusChange = async (index,disable) => {
		try{
			this.setState({ loading: true })
			this.props.modal.setModal({ active: true, component: <NetworkInfoModal loading={true}/> })
			let result
			result = await API.colive.unit.disable({ propertyId: getSafe(() => this.props.property._id, undefined),  unitId: this.state.unit[index].id })
			this.setState({ loading: false })
			if(result instanceof Error){
				this.props.modal.setModal({ active: false, component: <NetworkInfoModal loading={false} closeModal={this.closeModal} error={true} errorResponse={result.response}/> })
				throw result
			}
			this.props.modal.setModal({ active: false, component: null })
			let units 						= this.state.unit
			units[index].disabled = disable
		}catch(e){ console.error(e) }
	}
	// handleAddUnit = () => { this.props.modal.setModal({ active: true, component: <AddUnitModal unitTypes={this.state.unitType} propertyId={getSafe(() => this.props.property._id, undefined)} confirm={this.confirmAddUnit}/> }) }
	// handleUnitDelete 						= (index) => this.props.modal.setModal({ active: true, component: <ConfirmDeleteModal 	index={index} name={this.state.unit[index].name} confirm={this.confirmDeleteUnit} closeModal={this.closeModal}/> })
	// handleUnitEdit 							= (index) => this.props.modal.setModal({ active: true, component: <EditUnitModal 				placeholder={this.state.unit[index].name} unitTypes={this.state.unitType} propertyId={getSafe(() => this.props.property._id,undefined)} index={index} selectedIndex={this.state.unitType.findIndex((object) => object._id == this.state.unit[index].unitType)} confirm={this.confirmEditUnit}/>})
	
	handleUnitDelete 						= (index) => this.props.modal.setModal({ active: true, component: <CoworkUnitModal command={'DELETE'}	index={index}	data={{ unit: this.state.unit, unitType: this.state.unitType }}  action={this.confirmDeleteUnit}	close={this.closeModal}/> })
	handleUnitEdit							= (index) => this.props.modal.setModal({ active: true, component: <CoworkUnitModal command={'EDIT'}		index={index}	data={{ unit: this.state.unit, unitType: this.state.unitType }}  action={this.confirmEditUnit}  	close={this.closeModal}/> })
	handleAddUnit								= () 			=> this.props.modal.setModal({ active: true, component: <CoworkUnitModal command={'ADD'}									data={{ unit: this.state.unit, unitType: this.state.unitType }}  action={this.confirmAddUnit}   	close={this.closeModal}/> })
	handleUnitTypeStatusChange 	= () 			=> console.log('handleStatusChange')
	handleAddUnitType 					= () 			=> this.props.modal.setModal({ active: true, component: <CoworkUnitTypeModal 	command={'ADD'} 		unitTypes={this.state.unitType} propertyId={getSafe(() => this.props.property._id, undefined)} confirm={this.confirmAddUnit}/> })
	handleUnitTypeEdit 					= (index) => this.props.modal.setModal({ active: true, component: <CoworkUnitTypeModal 	command={'EDIT'} 		unitTypes={this.state.unitType} propertyId={getSafe(() => this.props.property._id, undefined)} confirm={this.confirmEditUnitType} initialValue={this.state.unitType[index].name || undefined} initialId={this.state.unitType[index]._id}/> })
	handleUnitTypeDelete 				= (index) => this.props.modal.setModal({ active: true, component: <CoworkUnitTypeModal 	command={'DELETE'} 	unitTypes={this.state.unitType} propertyId={getSafe(() => this.props.property._id, undefined)} confirm={this.confirmAddUnit}/> })
	render = () => 
		this.props.property ? 
		<div className={'Units TabPage'}>
			<DataTable 
				actions={[<ActionButton title={'Add'} onClick={this.handleAddUnit}/>]}
				title={'Unit'} 
				label={['Name','Unit Types','Actions']}
				data={this.state.unit.map(({ name, disabled, id, unitType, unitTypeName }, index) => {
					return {
						name					: name,
						'unit types'	: <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{[<Label key={index+'a'} add={index == 0 ? false : true} name={'101'}/>, <Label key={index+'b'} add={true}/>]}</div>,
						actions				: <ActionContainer index={index} id={id} onDelete={this.handleUnitDelete} onEdit={this.handleUnitEdit}/> 
					}
				})}/>
			<DataTable 
				actions={[ <ActionButton title={'Add'} onClick={this.handleAddUnitType}/> ]}
				title={'Unit Type'} 
				label={[ 'Name', 'Units', 'Actions' ]}
				data={this.state.unitType.map(({ name, disabled, id, unitType, unitTypeName }, index) => {
					return {
						name: name,
						// 'unit type': unitTypeName,
						status: 
							<NeuToggle 
								index={index}
								id={id}
								// status={disabled} 
								onChange={this.handleUnitTypeStatusChange}/>,
						actions: 
							<ActionContainer 
								index={index}
								id={id}
								onDelete={this.handleUnitTypeDelete} 
								onEdit={this.handleUnitTypeEdit}/> 
					}
				})}/>
		</div> : null
}

// export default Units
export default (props) => 
	<ModalContext.Consumer>
		{value => <Units modal={value} {...props}/>}
	</ModalContext.Consumer>