import React, { Component } from 'react'

class AddUnitModal extends Component{
	constructor(props){
		super(props)
		this.onChangeSelect = this.onChangeSelect.bind(this)
	}
	state = { nameInput: this.props.initialValue || '', selectedIndex : -1, }
	onChangeSelect = (index) => this.setState({ selectedIndex: index })
	render = () => 
		<div 
			className={'main_bg dropShadow modalPadding radius'} 
			style={{ }}>
			{ this.props.command === 'ADD' || this.props.command === 'EDIT' ? <div style={{}}><h2>{this.props.command}</h2></div> : null }
			{/* {
				this.props.command === 'EDIT' ? 
				<UnitSelector 
					onChange={this.onChangeSelect}
					placeholder={'Choose unit type'}
					unit={false}
					style={{ marginLeft: 0 }}
					data={this.props.unitTypes}/> : null
			} */}
			<input 
				style={{
					marginTop			: 16,
					width					: 'auto',
					borderRadius	: 5,
					border				: 0,
					padding				: 8
				}}
				value={this.state.nameInput}
				onChange={(e) => this.setState({ nameInput: e.target.value })}
				placeholder={'name'}/>
			<div 
				onClick={(e) => this.props.confirm( this.state.nameInput, this.props.command === 'EDIT' ? this.props.initialId : this.state.selectedIndex )}
				className={'cursor pointer'}
				style={{
					width						: 'auto',
					backgroundColor	: 'white',
					borderRadius		: 5,
					marginTop				: 16,
					display					: 'flex',
					justifyContent	: 'center',
					alignItems			: 'center',
					padding					: 8
				}}>
				<h5>{this.props.command}</h5>
			</div>
		</div>
}

export default AddUnitModal