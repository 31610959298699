import React, { Component } from 'react'
import formatRupiah from '../utility/rupiahFormator'

/*
  const renderTable = () => {
    let elements = []
    for(let i=0; i < propertyList.length - 1; i++){
      console.log('x')
      elements.push(
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16, marginBottom: 16, userSelect: 'all'}} key={i.toString()}>
          <p style={{ fontWeight: 'bold', whiteSpace: 'nowrap'}}>{ overviewData && overviewData[i]?.name}</p>
          {
            overviewData && overviewData[i]?.unitTypes.map((unitTypeOverviewData) => 
              <table>
                <thead>
                  <tr>
                    <th colSpan={2} align={"left"} style={{fontSize: 12}}>{unitTypeOverviewData.unitTypeName}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{fontSize: 12}}>Total unit</td>
                    <td style={{fontSize: 12}}>{unitTypeOverviewData.totalUnit}</td>
                  </tr>
                  <tr>
                    <td style={{fontSize: 12}}>Free</td>
                    <td style={{fontSize: 12}}>{unitTypeOverviewData.free}</td>
                  </tr>
                  <tr>
                    <td style={{fontSize: 12}}>Renov,Etc</td>
                    <td style={{fontSize: 12}}>{unitTypeOverviewData.renovEtc}</td>
                  </tr>
                  <tr>
                    <td style={{fontSize: 12}}>Total booked</td>
                    <td style={{fontSize: 12}}>{unitTypeOverviewData.totalBooked}</td>
                  </tr>
                  <tr>
                    <td style={{fontSize: 12}}><b>Available</b></td>
                    <td style={{fontSize: 12}}><b>{unitTypeOverviewData.available}</b></td>
                  </tr>
                  <tr>
                    <td style={{fontSize: 12}}>Occupancy</td>
                    <td style={{fontSize: 12}}>{Math.floor(unitTypeOverviewData.occupancy * 100)}%</td>
                  </tr>
                  <tr>
                    <td style={{fontSize: 12}}>Price</td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: 12}}>Rp. {formatRupiah(unitTypeOverviewData.price.toString())},00</td>
                  </tr>
                  <tr>
                    <td style={{fontSize: 12}}>Revenue</td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: 12}}>Rp. {formatRupiah(unitTypeOverviewData.revenue.toString())},00</td>
                  </tr>
                </tbody>
              </table>
            )
          }
        </div>
      )
    }
    return elements
  }
  */

export interface UnitTypeOverviewData {
  name:         string
  totalUnit:    number
  free:         number
  renovEtc:     number
  totalBooked:  number
  available:    number
  occupancy:    number
  price:        number
  revenue:      number
}

export interface OverviewSection{
  propertyName: string
  unitTypes: UnitTypeOverviewData[]
}

export interface OverviewGroup {
  data: OverviewSection[]
}

export const OverviewSection = ({
  propertyName,
  unitTypes
}: OverviewSection) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16, marginBottom: 16, userSelect: 'all'}}>
      <p style={{ fontWeight: 'bold', whiteSpace: 'nowrap'}}>{propertyName}</p>
      {
        unitTypes.map(({name, totalUnit, free, renovEtc, totalBooked, available, occupancy, price, revenue}, index) => 
          <table key={index}>
            <thead>
              <tr>
                <th colSpan={2} align={"left"} style={{fontSize: 12}}>{name}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{fontSize: 12}}>Total unit</td>
                <td style={{fontSize: 12}}>{totalUnit}</td>
              </tr>
              <tr>
                <td style={{fontSize: 12}}>Free</td>
                <td style={{fontSize: 12}}>{free}</td>
              </tr>
              <tr>
                <td style={{fontSize: 12}}>Renov,Etc</td>
                <td style={{fontSize: 12}}>{renovEtc}</td>
              </tr>
              <tr>
                <td style={{fontSize: 12}}>Total booked</td>
                <td style={{fontSize: 12}}>{totalBooked}</td>
              </tr>
              <tr>
                <td style={{fontSize: 12}}><b>Available</b></td>
                <td style={{fontSize: 12}}><b>{available}</b></td>
              </tr>
              <tr>
                <td style={{fontSize: 12}}>Occupancy</td>
                <td style={{fontSize: 12}}>{Math.floor(occupancy * 100)}%</td>
              </tr>
              <tr>
                <td style={{fontSize: 12}}>Price</td>
                <td style={{ whiteSpace: 'nowrap', fontSize: 12}}>{formatRupiah(Math.floor(price))},00</td>
              </tr>
              <tr>
                <td style={{fontSize: 12}}>Revenue</td>
                <td style={{ whiteSpace: 'nowrap', fontSize: 12}}>{formatRupiah(Math.floor(revenue))},00</td>
              </tr>
            </tbody>
          </table>
        )
      }
    </div>
  )
}

export const OverviewGroup = ({data}: OverviewGroup) => {
  return (
    <>
      {data.map((overviewSection, index) => <OverviewSection {...overviewSection} key={index}/>)}
    </>
  )
}

export default OverviewSection