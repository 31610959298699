import React, { Component } from 'react'
import Header from '../components/header'
import SideBar, {Item} from '../components/sidebar'

import '../styles/main.scss'

import {withRouter} from 'react-router-dom'
import Dashboard from '../pages/dashboard'
import Colive from '../pages/colive.jsx'
import CoWork from '../pages/cowork'

import getSafe from '../utility/getSafe'


import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
  Redirect
} from "react-router-dom"

const list = [{
	label			: 'Dashboard',
	pathname	: '/'
},{
	label			: 'CoLive',
	pathname	: '/colive'
},{
	label			: 'CoWork',
	pathname	: '/cowork'
}]

const switcher = (pathname) => {
	switch(pathname){
		case '/'				: return <Dashboard/>
		case '/colive'	: return <Colive/>
		default					: return <Dashboard/>
	}
}

class Authenticated extends Component{
	constructor(props){
		super(props)
		this.redirect = this.redirect.bind(this)
	}
	redirect = (pathname) => {
		this.props.history.push(pathname)
	}
	render = () => 
		<div className={'Main'}>
			<div className={'mainLeftContainer'}>
				<SideBar>
				{list.map((object,index) => 
					<Item 
						active={'/' + getSafe(() => this.props.history.location.pathname.split('/')[1], '') == object.pathname}
						key={index} 
						onClick={() => this.redirect(object.pathname)}
						label={object.label}/>)}
				</SideBar>
			</div>
			<div className={'mainRightContainer'}>
				<Header/>
				{/* <div className={'authenticatedPage'}> */}
				<Switch>
					<Route exact path={'/'}>
						<Dashboard/>
					</Route>
					<Route path={'/colive'}>
						<Colive/>
					</Route>
					<Route path={'/cowork'}>
						<CoWork/>
					</Route>
					<Redirect to={{
						pathname: "/",
						// state: { from: location }
					}}/>
				</Switch>
				{/* </div> */}
			</div>
		</div>
}

export default withRouter(Authenticated)