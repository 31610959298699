import React, { Component } from 'react'
// * COMPONENT import
import Neu, {DropDown} from '../neu'
// import Selector from '../selector'
import UnitSelector from '../unitSelector'
import Selector, { ISelectorData } from '../Selector'
import moment from 'moment'

// * UTILITY import
import getSafe from '../../utility/getSafe'
import UnitAssignmentSelector from '../UnitAssignSelector'

interface RoomRequestsData{
  unitTypeId    : string
  unitTypeName  : string
  count         : number 
  checkInDate   : string
  checkOutDate  : string
}

interface IAssignUnitModalData{
  name          : string
  bookingId     : string
  roomRequests  : RoomRequestsData[]
  
}

interface IPropsAssignUnitModal{
  data        : IAssignUnitModalData
  closeModal  : (event: React.MouseEvent<HTMLElement>) => void
  submit      : (event: React.MouseEvent<HTMLElement>, selectedUnit: any, data: any) => void
  units        : {
    _id   : string
    name  : string,
    unitTypeId: string
  }[]
}
interface IStateAssignUnitModal{
  selectedUnits: number[][]
  complete: boolean
}

class AssignUnitModal extends Component<IPropsAssignUnitModal,IStateAssignUnitModal>{
	constructor(props: IPropsAssignUnitModal){
		super(props)
    this.onChangeSelect = this.onChangeSelect.bind(this)
    console.log('AssignUnitModalData', this.props.data)
    const roomRequestLength = this.props.data.roomRequests.length
    const array: [][] = []
    for(let i = 0; i < roomRequestLength; i++) array.push([])
    this.state = {
      selectedUnits : array,
      complete      : false
    }
  }
  static defaultProps = {

  }
  parseData = ():[ISelectorData] => {
    return [{
      name: ''
    }]
  }
	onChangeSelect = (event: React.MouseEvent<HTMLElement>, roomRequestIndex:number , selected: number[]) => {
    if(selected.length <= this.props.data.roomRequests[roomRequestIndex].count && selected !== this.state.selectedUnits[roomRequestIndex] ){
      const selectedUnits = this.state.selectedUnits.map((array, index) => index === roomRequestIndex ? selected : array)
      let flag: boolean | undefined
      this.props.data.roomRequests.map(({count}, index) => {
        if(flag === undefined && selectedUnits[index].length != count) flag = false
      })
      if(flag === undefined) flag = true
      this.setState({
        selectedUnits : selectedUnits,
        complete      : flag
      })
    }
  }
	render = () => 
		<div 
			className={'main_bg dropShadow modalPadding radius'}
			style={{ width: 400, maxWidth: '80%'}}>
      <div>
        <h3>Booking Id</h3>
        <h5>{this.props.data.bookingId}</h5>
      </div>
      <div style={{marginTop: 8}}>
        <h3>Name</h3>
        <h5>{this.props.data.name}</h5>
      </div>
      <div style={{marginTop: 8}}>
        <h3>Check In</h3>
        <h5>{moment(this.props.data.roomRequests[0].checkInDate).format('YYYY-MM-DD')}</h5>
      </div>
      <div style={{marginTop: 8}}>
        <h3>Check Out</h3>
        <h5>{moment(this.props.data.roomRequests[0].checkOutDate).format('YYYY-MM-DD')}</h5>
      </div>
      {/* <div style={{marginTop: 8}}>
        <h3>Room Request</h3>
        { 
          this.props.data.roomRequests.map(({ unitTypeName, count, unitTypeId },index) => 
            <div key={index}> 
              <h5>{unitTypeName} ({count}x)</h5>
              <UnitAssignmentSelector 
                index={index}
                data={this.props.units}
                unitTypeId={unitTypeId}
                selected={this.state.selectedUnits[index]}
                onChangeSelection={this.onChangeSelect}/>
            </div>
          )
        }
      </div> */}
      <div style={{ width: 16 }}/>
      <div style={{ display : 'flex', flexDirection: 'row' }}>
        <div 
          onClick={this.props.closeModal}
          className={'cursor pointer'}
          style={{
            width           : 'auto',
            backgroundColor : 'white',
            borderRadius    : 5,
            marginTop       : 16,
            display         : 'flex',
            flex            : 1,
            justifyContent  : 'center',
            alignItems      : 'center',
            padding         : 8
          }}>
          <h5>Cancel</h5>
        </div>
        <div style={{width: 16}}/>
        <div 
          onClick={(e) => this.props.submit(e,this.state.selectedUnits, this.props.data)}
          className={'cursor pointer'}
          style={{
            width           : 'auto',
            backgroundColor : '#22304A',
            borderRadius    : 5,
            marginTop       : 16,
            display         : 'flex',
            flex            : 1,
            justifyContent  : 'center',
            alignItems      : 'center',
            color           : 'white',
            padding         : 8
          }}>
          <h5>Check Out</h5>
        </div>
      </div>
      
    </div>
}

export default AssignUnitModal