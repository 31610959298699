import axios, { AxiosRequestConfig } from 'axios'
import { getColiveAvailabilityRoute } from '../routes' 
import { API_HOST } from '../../constants'
import querystring from 'querystring'

export interface Booking{
  _id: string,
  customerName: string
  term: "DAILY" | "MONTHLY"
  checkInDate: string
  checkOutDate: string
  checkOut: {
    status: false
    timestamp?: Date
  },
  checkIn: {
    status: false
    timestamp?: Date
  },
  subscription: false,
  cancel?: boolean,
  free?: boolean,
  forcedClose?: boolean
}

export interface UnitData{
  _id: string
  name: string
  bookings: Booking[]
}

export interface UnitTypeAvailabilityData{
  _id: string,
  name: string,
  units: UnitData[]
}

export const getAvailability = async (coliveId: string, year: number, month: number): Promise<UnitTypeAvailabilityData[]|Error> => {
  try{
    const apiRoute = getColiveAvailabilityRoute(coliveId)
    const config: AxiosRequestConfig = {
      baseURL: API_HOST,
      url: apiRoute.path + '?' + querystring.stringify({
        year,
        month,
      }),
      method: apiRoute.method,
    }
    const result = await axios(config)
    return result?.data?.data
  }catch(error){
    console.error(error)
    return error
  }
}