import {GET,POST,PATCH,DELETE} from './method'

export default {
	register: {
		path: `/user/register`,
		method: POST
	},
	logout: {
		path: `/user/logout`,
		method: POST
	},
	editProfile: {
		path: `/user/`,
		method: PATCH
	},
	bindGoogle: {
		path: `/user/bindgoogle`,
		method: POST
	},
	deleteAccount: {
		path: `/user/bindgoogle`,
		method: DELETE
	},
	unbindGoogle: {
		path: `/user/unbindgoogle`,
		method: POST
	},
	getNotifications: {
		path: `/user/notification`,
		method: GET
	},
	login: {
		path: `/user/login`,
		method: POST
	},
	getAllAdminUsers: {
		path: '/user/getAllAdmin',
		method: GET
	}
}