import React, { Component } from 'react'
import './styles/neuToggle.scss'
class NeuToggle extends Component{
	state = {
		active: false
	}
	render = () => 
		<div 
			onClick={() => this.props.onChange(this.props.index,!this.props.status)} 
			className={`Neu NeuToggle ${!this.props.status ? 'active' : ''}`}>
			<div className={'toggleIndicator'}>
				<div className={'toggle'}/>
				<p className={'toggleTextOff'}>Off</p>
				<p className={'toggleTextOn'}>On</p>
			</div>
		</div>
}

export default NeuToggle