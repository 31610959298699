import React, { Component } from 'react'
import Plus 								from '../components/svg/plus'
import Minus 								from '../components/svg/minus'
import './styles/label.scss'

type Props 			= {
	name?					: string,
	add						: Boolean,
	deleteClick?	: (event: React.MouseEvent<HTMLElement>) => void
} & typeof defaultProps

const defaultProps = {
	add				: true,
}

class Label extends Component<Props>{
	static defaultProps = defaultProps
	render = () => !this.props.add ? 
		<div
			className={'Label'}
			style={{
				display					: 'flex',
				flexDirection		: 'row',
				width						: 'min-content',
				justifyContent 	: 'center',
				alignItems			: 'center',
				marginRight			: 4,
				padding					: 4,
				borderRadius		: 5,
				backgroundColor : 'rgba(34, 48, 74, 0.5)',
				color						: 'white'
			}}>
			<h5 style={{ width: 'min-content', height: 'min-content' }}>
				{this.props.name}
			</h5>
			<div
				className={'closeButton'}
				onClick={this.props.deleteClick}
			 	style={{ 
					marginLeft			: 4, 
					height					: 16, 
					width						: 16, 
					display					: 'flex', 
					flexDirection		: 'row', 
					justifyContent	: 'center', 
					alignItems			: 'center', 
					borderRadius		: 8
				}}>
				<Minus style={{height: 8, width: 8}}/>
			</div>
		</div> : 
		<div className={'closeButton'} style={{
			display					: 'flex',
			flexDirection		: 'row',
			// width						: 'min-content',
			justifyContent	: 'center',
			alignItems			: 'center',
			width						: 16,
			height					: 16,
			// padding					: 4,
			borderRadius		: '50%',
			backgroundColor : 'rgba(34, 48, 74, 0.5)'
		}}>
			<Plus style={{ 
				height	: 8, 
				width		: 8, 
				fill		: 'white'}}/>
		</div>
}

Label.defaultProps = defaultProps

export default Label