import React, { Component } from 'react'
import './styles/Notification.scss'
import Neu,{DropDown} from './neu'

import Bell from '../components/svg/bell'

import getSafe from '../utility/getSafe'

// * CONTEXT IMPORT
import CloudMessagingContext from '../context/cloudMessaging'
import { object } from 'prop-types'

const NotificationItem = ({title,content,onClick,index}) => 
	<div 
		onClick={(e) => onClick(e,index)}
		className={'NotificationItem'}>
		<div className={'notificationTitleContainer'}>
			<h4>{title}</h4>
		</div>
		<div className={'notificationContentContainer'}>
			<p>{content}</p>
		</div>
	</div>

const notifications = [{
	title: 'Booking',
	message: 'New Booking 34 Residence'
},{
	title: 'Booking',
	message: 'New Booking 34 Residence'
},{
	title: 'Booking',
	message: 'New Booking 34 Residence, New Booking 34 Resi adsads adsads djsahdsjkdksa ce New Booking 34 Residence'
}]

class Notification extends Component{
	constructor(props){
		super(props)
		this.handleMouseDown = this.handleMouseDown.bind(this)
		this.handleMenuClick = this.handleMenuClick.bind(this)
		this.setRef = this.setRef.bind(this)
		this.setDropDownRef = this.setDropDownRef.bind(this)
		this.handleNotificationClick = this.handleNotificationClick.bind(this)
	}
	state = {
		active: false
	}
	handleMouseDown = (e) => {
		if(!this.containerRef.contains(e.target) && !this.dropDownRef.contains(e.target)){
			e.stopPropagation()
			e.preventDefault()
			this.setState({
				active: !this.state.active
			})
		}
	}
	renderNotificationList = () => {
		try{
			return this.props.notifications.map(({title,body},index) => 
				<NotificationItem 
					index={index}
					onClick={this.handleNotificationClick}
					key={index}
					title={title}
					content={body}/>)
		}catch(e){
			console.error(e)
		}
	}
	componentWillUpdate = (nextProps,nextState) => {
		if(!this.state.active && nextState.active)
			this.eventListener = document.addEventListener('mousedown',this.handleMouseDown)
		if(this.state.active && !nextState.active){
			document.removeEventListener('mousedown',this.handleMouseDown)
			this.eventListener = null
		}
	}
	handleMenuClick = (e) => {
		e.preventDefault()
		e.stopPropagation()
		this.setState({
			active: !this.state.active
		})
	}
	handleNotificationClick = (e,index) => {
		e.preventDefault()
		e.stopPropagation()
		console.log('im Clicked',index)
	}
	setDropDownRef = (ref) => this.dropDownRef = ref
	setRef = (ref) => this.containerRef = ref
	render = () => 
		<Neu
			setRef={this.setRef}
			toggle={false}
			onClick={this.handleMenuClick}
			className={`Notification ${this.state.active ? 'active' : ''}`}>
			<div 
			
				className={'numberBadge'}>
				<label>{this.props.notifications.length || 0}</label>
			</div>
			<Bell/>
			<DropDown 
				setRef={this.setDropDownRef}
				style={{
					width: 320,
					maxWidth: 'calc(100vw - 100px)',
					right: 0,
					left: 'auto',
				}}>
				{this.renderNotificationList()}
			{/* {	getSafe(() => this.props.notifications, []).map(({title,body},index) => 
					<NotificationItem 
						index={index}
						onClick={this.handleNotificationClick}
						key={index}
						title={title}
						content={body}/>)} */}
				{/* <div 
					onClick={ () => this.addProperty()}
					className={`DropItem addButton`}>
					<h5>Add property</h5>
				</div> */}
			</DropDown>
		</Neu>
}

export default (props) => 
<CloudMessagingContext.Consumer>
	{({notifications}) => <Notification notifications={notifications} {...props}/>}
</CloudMessagingContext.Consumer>