import React, { Component } from 'react'
import './styles/table.scss'
// import { object } from 'prop-types'
import Neu from './neu'
import getSafe from '../utility/getSafe'
import { object, instanceOf } from 'prop-types'
import Plus from './svg/plus'
import { isArray } from 'util'

export const ActionButton = (props) =>{
	console.log(props.onClick)
	return (<Neu 
		onClick={props.onClick}
		state={false}
		className={'TableActionButton'}>
		<div className={'iconContainer'}>
			<Plus/>
		</div>
		<div className={'actionTitleContainer'}>
			<h3>{props.title}</h3>
		</div>
	</Neu>)}

// const Table = (props) => 
// 	<div 
// 		className={'Neu Table'}>
// 		<div className={'headContainer'}>
// 			<div className={'titleContainer'}>
// 				<h3>{getSafe(() => props.title, null)}</h3>
// 			</div>
// 			{getSafe(() => props.actions, []).map((component,index) => React.cloneElement(component,{
// 				key: index
// 			}))}
// 		</div>
// 		<table>
// 			<thead>
// 				<tr>
// 				{ props.numbering ? <th>No.</th>: null }
// 				{	
// 					getSafe(() => props.label,[]).map((string,index) => 
// 						<th key={index}>{string}</th>)
// 				}</tr>
// 			</thead>
// 			<tbody>
// 			{
// 					props.data && props.data instanceof Array ? props.data.map((object,index) => 
// 					<tr 
// 						key={index} 
// 						onClick={ props.onItemClick 			? (event) => props.onItemClick({e: event, index, data: object}) : undefined}
// 						onContextMenu={props.onItemClick 	? (event) => props.onItemClick({e: event, index, data: object}) : undefined}
// 						// onClick={(event) => props.onItemClick({e: event, index, data: object})}
// 					>
// 					{ props.numbering ? <td>{index + 1}</td>: null }
// 					{
// 						props.label.map((string,indx) => 
// 							<td key={indx}>{getSafe(() => object[string.toLowerCase()],'')}</td>)
// 					}</tr>) : undefined
// 			}</tbody>
// 		</table>
// 	</div>

// Table.defaultProps = {
// 	numbering: true
// }

class Table extends Component{
	componentDidMount = () => {
		console.log('onItem', this.props.onItemClick)
	}
	render = () => 
		<div 
			className={'Neu Table'}>
			<div className={'headContainer'}>
				<div className={'titleContainer'}>
					<h3>{getSafe(() => this.props.title, null)}</h3>
				</div>
				{getSafe(() => this.props.actions, []).map((component,index) => React.cloneElement(component,{
					key: index
				}))}
			</div>
			<table>
				<thead>
					<tr>
					{ this.props.numbering ? <th>No.</th>: null }
					{	
						getSafe(() => this.props.label,[]).map((string,index) => 
							<th key={index} style={{ textAlign: 'left' }}>{string}</th>)
					}</tr>
				</thead>
				<tbody>
				{
					this.props.data && this.props.data instanceof Array ? this.props.data.map((object,index) => 
					<tr 
						key={index} 
						onClick={ this.props.onItemClick 	? (event) => this.props.onItemClick({e: event, index, data: object, _id: object['booking id']}) : undefined}
						onContextMenu={ this.props.onItemClick 	? (event) => this.props.onItemClick({e: event, index, data: object, _id: object['booking id']}) : undefined}
						// onClick={(event) => props.onItemClick({e: event, index, data: object})}
					>
					{ this.props.numbering ? <td>{index + 1}</td>: null }
					{
						this.props.label.map((string,indx) => 
							<td key={indx}>{getSafe(() =>  isArray(object[string.toLowerCase()]) ? object[string.toLowerCase()].toString() : object[string.toLowerCase()] ,'')}</td>)
					}</tr>) : undefined
				}</tbody>
			</table>
		</div>
}

export default Table