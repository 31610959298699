import React, { Component } from 'react'

// * UTILITY import
import getSafe from '../../utility/getSafe'
import UnitAssignmentSelector from '../UnitAssignSelector'
import Neu from '../neu'

const DeleteBookingModal = ({onCancel, onConfirm}) => {

  return (
    <div
      className={'main_bg dropShadow modalPadding radius'}
      style={{
				width						: 'min-content',
				// maxWidth				: 'calc(90% - 48px)',s
				backgroundColor	: '#e0e5ec',
				padding					: 24, 
				borderRadius		: 5,
				maxHeight				: '80vh',
				overflowY				: 'auto'
			}}>
      <h3>Confirm delete booking ?</h3>
      <div style={{display: 'flex', flexDirection: 'row', width: 'min-content', marginTop: 24 }}>
        <Neu style={{ marginRight: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 24, paddingRight: 24 }} onClick={() => onCancel()}>
          <h5>Cancel</h5>
        </Neu>
        <Neu style={{ marginRight: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 24, paddingRight: 24, backgroundColor: '#2c2c2c', color: 'white' }} onClick={onConfirm}>
          <h5>Confirm</h5>
        </Neu>
      </div>
    </div>
  )
}

export default DeleteBookingModal