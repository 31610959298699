export default {
	GET : 'GET',
	POST: 'POST',
	PATCH: 'PATCH',
	DELETE: 'DELETE'
}

export const GET = 'GET'
export const POST = 'POST'
export const PATCH = 'PATCH'
export const DELETE = 'DELETE'