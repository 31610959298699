import { OverviewSection } from "../../components/OverviewSection"
import { OverviewData } from "../../pages/colive/all/nOverviewAll"

// export interface UnitTypeOverviewData {
//   name:         string
//   totalUnit:    number
//   free:         number
//   renovEtc:     number
//   totalBooked:  number
//   available:    number
//   occupancy:    number
//   price:        number
//   revenue:      number
// }

// export interface OverviewSection{
//   propertyName: string
//   unitTypes: UnitTypeOverviewData[]
// }

const transformOverviewDataToOverviewGroupData = (overviewData: OverviewData): OverviewSection[]  => {
  console.log('overviewData ', overviewData)
  const derrived: OverviewSection[] = overviewData.property.map((property) => {
    return ({
      propertyName: property.name,
      unitTypes: property.roomTypes.map((rt) => ({
        name:         rt.unitTypeName,
        totalUnit:    rt.totalRoom,
        free:         rt.free,
        renovEtc:     rt.renov,
        totalBooked:  rt.totalRoomBooked,
        available:    rt.emptyRoom,
        occupancy:    rt.occRate,
        price:        rt.rate || 0,
        revenue:      rt.revenue,
      }))
    })
  })
  return derrived
}

export default transformOverviewDataToOverviewGroupData