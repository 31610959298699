import React, { Component } from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
  Redirect
} from "react-router-dom"
import '../styles/pages.scss'
// Page
import Login from './login'
// Component
import PrivateRoute 		from './privateRoute'
import Authenticated 		from './authenticated'
import Modal 						from '../components/modal'
import ContextMenuLayer from '../components/contextMenuLayer'

const env = process.env.REACT_APP_ENV
console.log(env)

const Index = () => 
	<div className={'Pages'}>
		<Modal/>
		<ContextMenuLayer/>
		{/* <Router basename={'/pms/'}> */}
		<Router >
			<Switch>
			<Route path={'/login'}>
				<Login/>
			</Route>
			<PrivateRoute path={['/','/colive']}>
				<Authenticated/>
			</PrivateRoute>
			<PrivateRoute path={['*']}>
				<Redirect to={{
					pathname: "/",
					// state: { from: location }
				}}/>
			</PrivateRoute>
			</Switch>
		</Router>
		{	
			env !== 'production' ? 
			<div style={{
				position: "fixed",
				top: 0,
				left: 0,
				backgroundColor: 'rgba(0,0,0,0.5)',
				borderRadius: 5
			}}>
				<h3 style={{ color: 'white', padding: 4,}}>{env}</h3>
			</div> : null
		}
	</div>

export default Index