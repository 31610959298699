import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Authenticator{
	getUser = () => {
		try{
			const cookie 	= cookies.get('auth')
		
			if(!cookie) 	return null
			const id 			= cookie.id
			const name 		= cookie.name
			const token 	= cookie.token
			const role 		= cookie.role
			const auth 		= { id, name, token, role }
			return auth
		}catch(e){
			console.error(e)
			return e
		}
	}
	isLoggedIn = () => {
		try{
			const cookie = cookies.get('auth')
			if(cookie) return true
			return false
		}catch(e){
			console.error(e)
			return false
		}
	}
	writeCookie = (auth) => {
		try{
			console.log(auth)
			const { id, token, role, name } = auth
			if(!id) 		throw new Error('id not found')
			if(!name) 	throw new Error('name not found')
			if(!token)	throw new Error('token not found')
			if(!role)		throw new Error('role')
			cookies.set('auth', JSON.stringify(auth), { maxAge: 2147483647 })
			return true
		}catch(e){
			console.error(e)
			return e
		}
	}
	addListener = (func) => {
		try{
			return cookies.addChangeListener(func)
		}catch(e){ return e }
	}
	removeListener = () => {
		try{
			return cookies.removeChangeListener()
		}catch(e){ console.error(e) }
	}
	deleteCookie = () => {
		try{
			cookies.remove('auth')
			return true
		}catch(e){ return e }
	}
	getUUID = () => {
		try{
			const uuid = cookies.get('UUID')
			if(!uuid) return undefined
			if(uuid && !uuid._id){ 
				cookies.remove('UUID')
				return undefined
			}
			return uuid._id
		}catch(err){ console.error(err) }
	}
}

const authenticator = new Authenticator()

export default authenticator