import { APIRoute } from ".";

export const getColiveAvailabilityRoute = (coliveId: string): APIRoute => ({
  path: `/availability/getAvailability/${coliveId}`,
  method: 'GET'
})

export const cancelBookingRoute = (coliveId: string): APIRoute => ({
  path: `/availability/cancelBooking/${coliveId}`,
  method: 'POST'
})
export const markAsFreeRoute = (coliveId: string): APIRoute => ({
  path: `/availability/markAsFree/${coliveId}`,
  method: 'POST'
})
export const markAsForcedCloseRoute = (coliveId: string): APIRoute => ({
  path: `/availability/markForcedClose/${coliveId}`,
  method: 'POST'
})