import React, { Component } from 'react'
import DaysSelector from '../daysSelector'
import UnitSelector from '../unitSelector'

import '../styles/ratesModal.scss'

class RatesModal extends Component{
	constructor(props){
		super(props)
		this.handleDaysChange = this.handleDaysChange.bind(this)
		this.handleTermChange = this.handleTermChange.bind(this)
	}
	state = {
		// unitTypes: [],
		term						: null,
		unitTypeInput		: {},
		startDateInput	: '',
		endDateInput		: '',
		priceInput			: '',
		days						: []
	}
	// componentDidUpdate = () => console.log(this.state)
	handleDaysChange 			= (data) 	=> this.setState({days: data})
	handleTermChange 			= (index) => this.setState({term:  index == 0 ? 'DAILY' : index == 1 ? 'MONTHLY' : null})
	handleUnitTypeChange 	= (index) => this.setState({unitTypeInput: this.props.unitTypes[index]})
	handleStartDateChange = (e) 		=> this.setState({startDateInput: e.target.value})
	handleEndDateChange 	= (e) 		=> this.setState({endDateInput: e.target.value})
	handlePriceChange 		= (e) 		=> this.setState({priceInput: e.target.value})
	handleSave 						= ( ) 		=> this.props.save({
		propertyId	: this.props.propertyId,
		unitTypeId	: this.state.unitTypeInput._id,
		days				: this.state.days,
		startDate		: this.state.startDateInput,
		endDate			: this.state.endDateInput,
		price				: this.state.priceInput,
		monthly			: this.state.term == 'MONTHLY' ? true : false
	})
	render = () => 
		<div 
			className={'RatesModal dropShadow'}
			style={{
				width: 'min-content',
				backgroundColor: '#E0E5EC',
				padding: 16,
				borderRadius: 5
			}}>
			<h1>Rates Modal</h1>
			<div style={{marginTop: 16, marginBottom: 16}}>
				<UnitSelector 
					placeholder={'Choose Term'}
					onChange={this.handleTermChange}
					data={[{
						name: 'Daily'
					},{
						name: 'Monthly'
					}]}
					style={{margin: 0}}/>
			</div>
			<div style={{marginTop: 16, marginBottom: 16}}>
				<UnitSelector 
					onChange={this.handleUnitTypeChange}
					data={this.props.unitTypes}
					style={{margin: 0}}/>
			</div>
			<div 
				className={'dataInput'}
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginTop: 8
				}}>
				<div>
					<h4>Start date</h4>
					<div style={{
						borderRadius: 5
					}}>
						<input
							className={'clearButtonHack'}
							onChange={this.handleStartDateChange}
							type={'date'} 
							style={{
								borderRadius: 5,
								padding: '8px 8px',
								border: '0px'
							}}/>
					</div>
				</div>
				<div style={{width: '32px'}}/>
				<div>
					<h4>End date</h4>
					<div style={{
						borderRadius: 5
					}}>
						<input 
							onChange={this.handleEndDateChange}
							className={'clearButtonHack'}
							type={'date'} 
							style={{
								borderRadius: 5,
								padding: '8px 8px',
								border: '0px'
							}}/>
					</div>
				</div>
			</div>
			<div style={{
				marginTop: 16
			}}>
				<DaysSelector
					onChange={this.handleDaysChange}
					initialData={this.state.days}/>
			</div>
			<div style={{
				marginTop: 16
			}}>
				<h4>Price</h4>
				<div style={{
					borderRadius: 5
				}}>
					<input 
						onChange={this.handlePriceChange}
						style={{
							borderRadius: 5,
							padding: '8px 8px',
							border: '0px'
						}}/>
				</div>
			</div>
			<div 
				style={{
					marginTop: 16,
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					justifyContent: 'flex-end'
				}}>
				<div 
					onClick={this.handleSave}
					className={'Neu'} 
					style={{
						width: 'min-content',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '0px 16px'
					}}>
					<h4>Save</h4>
				</div>
				<div style={{width: 16}}/>
				<div 
					onClick={this.props.cancel}
					className={'Neu'} 
					style={{
						width: 'min-content',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '0px 16px'
					}}>
					<h4>Cancel</h4>
				</div>
			</div>
		</div>
}

export default RatesModal
