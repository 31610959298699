import React, { Component, ReactNode } from 'react'

import moment, { Moment } from 'moment'
// * CONTEXT import
import ModalContext from '../context/modal'

import './styles/sliderTable.scss'

const dummy = [{
	date: '2020-01-01',
	price: 100000
},{
	date: '2020-01-02',
	price: 100000
},{
	date: '2020-01-03',
	price: 100000
},{
	date: '2020-01-04',
	price: 100000
},{
	date: '2020-01-05',
	price: 100000
},{
	date: '2020-01-06',
	price: 100000
},{
	date: '2020-01-07',
	price: 100000
}]

class SliderTable extends Component{
	constructor(props){
		super(props)
		this.handleScrollEvent = this.handleScrollEvent.bind(this)
	}
	state = {
		month: '',
		year: '',
	}
	handleScrollEvent = (e) => {
		// console.log(e)
		let element = e.target
		console.log('scrollLeft' ,element.scrollLeft)
		const index = Math.ceil(element.scrollLeft / 49)
		// console.log('index',index)
		const momentViewDates = moment(this.state.viewDates[index])
		const month = momentViewDates.month()
		const year = momentViewDates.year()
		if(this.state.month != month || this.state.year != year){
			this.setState({
				month: month,
				year: year
			})	
		}
		// console.log('scrollWidth' ,element.scrollWidth)
		// console.log('clientWidth' ,element.clientWidth)
		if (element.scrollWidth - element.scrollLeft === element.clientWidth) {
			// console.log('END RIGHT')
			if(this.props.handleRightLoad)
				this.props.handleRightLoad()
		}
		if (element.scrollLeft === 0) {
			// console.log('END Left')
			if(this.props.handleLeftLoad)
				this.props.handleLeftLoad()
    }
	}
	calculateDays = () => {
		const momentStartDate = moment(this.props.startDate,'YYYY-MM-DD',true)
		const momentEndDate = moment(this.props.endDate,'YYYY-MM-DD',true)
		const numberOfDays = momentEndDate.diff(momentStartDate, 'days') + 1
		let dateArray = []		
		const bufferDate = moment(momentStartDate)
		for(let i=0; i < numberOfDays; i++){
			dateArray.push(bufferDate.clone().add(i,'days').format('YYYY-MM-DD'))
		}
		this.setState({
			viewDates: dateArray,
		})
	}
	componentDidMount = () => {
		this.calculateDays()
	}
	componentDidUpdate = (prevProps, {month,year}) => {
		if(this.props != prevProps)
			this.calculateDays()
	}
	renderTableHead = () => {
		let arr = []
		const momentStartDate = moment(this.props.startDate,'YYYY-MM-DD',true)
		const momentEndDate = moment(this.props.endDate,'YYYY-MM-DD',true)
		const numberOfDays = momentEndDate.diff(momentStartDate, 'days') + 1
		let dateArray = []		
		const bufferDate = moment(momentStartDate)
		for(let i=0; i < numberOfDays; i++){
			dateArray.push(bufferDate.clone().add(i,'days').format('YYYY-MM-DD'))
		}
		dateArray.map((dateString,index) => {
			let momentDate = moment(dateString,"YYYY-MM-DD",true)
			arr.push(
				<th key={index}>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#2c2c2c'
					}}>
						<h3 style={{color: 'white'}}>{momentDate.format('DD')}</h3>
					</div>
				</th>
			)
		})
		return arr
	}
	renderTablePrice = () => {
		// console.log('RENDER TABLE PRICE')
		let arr = []
		const momentStartDate = moment(this.props.startDate,'YYYY-MM-DD',true)
		const momentEndDate = moment(this.props.endDate,'YYYY-MM-DD',true)
		const numberOfDays = momentEndDate.diff(momentStartDate, 'days') + 1
		let dateArray = []		
		const bufferDate = moment(momentStartDate)
		for(let i=0; i < numberOfDays; i++){
			dateArray.push(bufferDate.clone().add(i,'days').format('YYYY-MM-DD'))
		}
		const {data} = this.props
		if(!data)
			return null
		data.map((object,unitTypeIndex) => 
			arr.push(
				<tr key={unitTypeIndex}>
					{this.renderTablePrice2(dateArray,unitTypeIndex)}
				</tr>)
		)
		// console.log(arr)
		return arr
	}
	renderTablePrice2 = (dateArray,unitTypeIndex) => {
		let arr = []
		dateArray.map((stringDate,index) => {
			const {data} = this.props
			const {rates} = data[unitTypeIndex]
			const priceIndex = rates.findIndex((object) => object.date == stringDate)
			const price = priceIndex != -1 ? rates[priceIndex].price : 'empty'
			arr.push(
				<td key={index}>
					<div style={{
						width: 48,
						minWidth: 48,
						height: 32,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center'
					}}>
						<h5 style={{fontSize: 12}}>{price}</h5>
					</div>
				</td>
			)
		})
		return arr
	}
	renderTableLabel = () => {
		try{
			const {data} = this.props
			let arr = []
			data.map(({name},index) => 
				arr.push(
					<div 
						key={index} 
						style={{
							height:32, 
							display: 'flex', 
							justifyContent: 'center', 
							alignItems: 'center',
							// backgroundColor: 'red'
						}}>
						<h4>{name}</h4>
					</div>)
			)
			return arr
		}catch(e){
			console.error(e)
		}
	}
	render = () => 
		<div 
			className={'Neu SliderTable'} 
			style={{
				width: 500,
				maxWidth: 'calc(90% - 16px)',
				height: 'min-content',
				margin: 16,
				display: 'flex',
				flexDirection: 'row',
				padding: 8,
				position: 'relative'
			}}
			>
			<div style={{
				height: '100%',
				width: 160,
			}}>
				<div style={{height: 72, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<h2></h2>
				</div>
				{this.renderTableLabel()}
				
			</div>
			<div style={{
				position: 'absolute',
				top:8,
				right:8,
				width: '100%',
				height: 32,
				display: 'flex',
				justifyContent:'center',
				alignItems: 'center'
			}}>
				<h3>{this.state.year || ''} {moment(this.state.month+1, 'MM').format('MMMM') || ''}</h3>
			</div>
			<div 
				style={{
					width: 660,
					overflowX: 'auto',
				}}
				onScroll={this.handleScrollEvent}>
			<table 
				style={{
					width: 'min-content',
					height: 'min-content',
				}}>
					<thead>
						<tr>
							<th colSpan={29}>
							</th>
						</tr>
						<tr>{this.renderTableHead()}</tr>
					</thead>
					<tbody>
						{this.renderTablePrice()}
					</tbody>
				</table>
			</div>
		</div>
}

export default SliderTable
