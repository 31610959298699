
import React, { Component } from 'react'

import Table from '../components/table'
import API from '../api'

import moment from 'moment'


class Dashboard extends Component{
	componentDidMount = async () => {
		console.log('getting admin')
		const users = await API.user.getAllAdmin()
		console.debug('PMS users', users)
		this.setState({
			data: users.data
		})
	}
	render = () => 
		<div style={{
			marginLeft: 24
		}}>
			<Table 
				onItemClick={this.handleItemClick}
				title={'Users'}
				numbering={true}
				label={['Username', 'Email', 'Role', 'Created At']}
				data={this.state?.data?.map(({ name, email, role, createdAt }) => {
					return ({
						username: name,
						email: email,
						role: role,
						'created at': moment(createdAt).format('YYYY-MM-DD')
					})
				})}/>
		</div>
}

export default Dashboard