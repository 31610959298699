import React, { Component } from 'react'
import CloudMessagingContext from '../context/cloudMessaging'
import messaging from '../services/initFCM'
import Cookies 							from 'universal-cookie'

import API from '../api'
import { string } from 'prop-types'

const cookies = new Cookies()


interface firebaseMessage{
	data: {
		'firebase-messaging-msg-data': {
			data					: any,
			notification	:  any
		}
	}
}

const saveTokenToCookie = (token: String, refreshed: Boolean = false) => {
	try{
		let oldFcm

		if(refreshed){
			oldFcm = cookies.get('fcm')
			if(!oldFcm) console.error('Old fcm not found creating new fcm')
			try{
				oldFcm = oldFcm instanceof Object ? oldFcm : JSON.parse(oldFcm)
				const fcm 		= {
					registered	: false,
					token				: token,
					createdAt		: refreshed && oldFcm && oldFcm.createdAt ? oldFcm.createdAt : new Date(),
					updatedAt		: new Date()
				}
				cookies.set('fcm', JSON.stringify(fcm), { maxAge: 2147483647 })

			}catch(e){ cookies.remove('fcm') }
		}
		const fcm 		= {
			registered	: false,
			token				: token,
			createdAt		: refreshed && oldFcm && oldFcm.createdAt ? oldFcm.createdAt : new Date(),
			updatedAt		: new Date()
		}
		cookies.set('fcm', JSON.stringify(fcm), { maxAge: 2147483647 })
	}catch(error){ console.error(error) }
}

const sendTokenToServer = async (token: string): Promise<Boolean> => {
	try{
		console.debug('Registering token to server...')
		const result = await API.registerFCM({ token })
		console.debug(result)
		if(result && result.data && result.data.message == "FCM token registered"){
			console.log('Token registered')
			return true
		}
			
		return false
	}catch(error){ 
		console.error(error) 
		return false
	}
}

const setFcmTokenRegistered = () => {
	try{
		const stringObject =  cookies.get('fcm')
		if(!stringObject) throw Error('fcm cookies not found ??')
		console.log(stringObject)
		const json = stringObject instanceof Object ? stringObject : JSON.parse(stringObject)
		json.registered = true
		json.updatedAt = new Date()
		cookies.set('fcm', JSON.stringify(json), { maxAge: 2147483647 })
	}catch(error){ console.error(error) }
}

const checkIfTokenEquals = (token: string): boolean => {
	try{
		let fcm = cookies.get('fcm')
		if(!fcm) return false
		fcm = fcm instanceof Object ? fcm : JSON.parse(fcm)
		if(fcm.token == token) return true
		return false
	}catch(error){ 
		console.error(error)
		return false
	}
}

const checkIfTokenRegistered = (): Boolean => {
	try{
		let fcm = cookies.get('fcm')
		if(!fcm) throw new Error('FCM cookies not found')
		fcm  = fcm instanceof Object ? fcm : JSON.parse(fcm)
		if(fcm.registered === true)
			return true
		return false
	}catch(error){ 
		if(error && error.message && error.message == 'FCM cookies not found')
			console.debug('FCM cookies not found')
		console.error(error) 
		return false
	}
}

class CloudMessaging extends Component{
	state = {
		notifications: [],
	}
	handleMessagingData = (firebaseMessage: firebaseMessage) => {
		try{
			console.log(firebaseMessage)
			const {data: firebaseMessageData} = firebaseMessage
			const {'firebase-messaging-msg-data': firebaseMessagingMessageData} = firebaseMessageData
			const {data} 					= firebaseMessagingMessageData
			const {notification} 	= firebaseMessagingMessageData
			console.log(data)
			console.log(notification)

			// TODO DECIDE WHAT TO DO WITH DATA
			this.setState({
				notifications: [ 
					notification,
					...this.state.notifications
				]
			})
		}catch(e){ console.error(e) }
	}
	componentDidMount = async () => {
		try{
			await messaging.requestPermission()
			.then(async () => {
				const token = await messaging.getToken()
				if(!checkIfTokenEquals(token)){
					saveTokenToCookie(token) 
					if(await sendTokenToServer(token)) 	
						setFcmTokenRegistered()
				}else if(!checkIfTokenRegistered()){
					if(await sendTokenToServer(token))
						setFcmTokenRegistered()
					else console.error('Unable to send fcm token to server')
				}else{ /* DO NOTHING */ }
			})
			.catch((err) =>  console.error("Unable to get permission to notify.", err))
			
			messaging.onTokenRefresh(() => {
				messaging.getToken().then(async (refreshedToken) => {
					console.debug('Token refreshed.')
					saveTokenToCookie(refreshedToken, true)
					if(await sendTokenToServer(refreshedToken)) setFcmTokenRegistered()
				}).catch((err) => { console.error('Unable to retrieve refreshed token ', err) })
			})

			messaging.onMessage((payload) => {
				console.log(',MEssage recieved')
				console.log(`Message received. ${payload}`);
				// ...
			})
			navigator.serviceWorker.addEventListener("message", (message) => this.handleMessagingData(message))
		}catch(error){ console.error(error) }
	}
	render = () =>
		<CloudMessagingContext.Provider value={{
			notifications: this.state.notifications
		}}>
			{this.props.children}
		</CloudMessagingContext.Provider>
}

export default CloudMessaging