import React, { Component } from 'react'
import Plus from './svg/plus'

const DragIndicator= (props) => 
	<div style={{
		position: 'absolute',
		top: 0,
		height: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: `rgba(121, 152, 188,${props.active ? '0.5':'0'})`,
		transition: 'background 300ms ease'
	}}>
		<Plus style={{
			width: '30%',
			height: '30%',
			fill: 'black'
		}}/>
	</div>

class DragAndDrop extends Component{
	constructor(props){
		super(props)
		this.state = {
			dragEnter: false
		}
		this.inputElement = React.createRef()
	}
	handleFiles = (fileList, fn = ()=>{}) => {
		let count = fileList.length
		let arr = []
		for(let i = 0; i < count; i++){
			const blob = URL.createObjectURL(fileList[i])
			arr.push(blob)
		}
		fn(arr)
	}
	doDrop = (event) => {
		// console.log('Do drop')
		event.stopPropagation()
		event.preventDefault()
		this.setState({
			dragEnter: false
		})
		let dt 		= event.dataTransfer
		let files = dt.files
		this.handleFiles(files,this.props.handleDrop)
	}
	onDragEnter = (event) => {
		console.log("Drag Enter")
		this.target = event.target
		// document.getElementById('output').textContent = ''
		this.setState({
			dragEnter: true
		})
		event.stopPropagation()
		event.preventDefault()
	}
	onDragLeave = (event) => {
		if(event.target == this.target){
			console.log('On Drag leave')
			this.setState({
				dragEnter: false
			})
			event.preventDefault()
			event.stopPropagation()
		}
	}
	onDragOver = (event) => {
		console.log('Drag Over')
		event.stopPropagation()
		event.preventDefault()
	}
	handleClick = (event) => {
		console.log('handleClick')	
		console.log(this.inputElement)
		this.inputElement.current.click(event)
	}
	handleChange = (event) => {
		console.log('handle Change')
		const fileList = event.target.files
		this.handleFiles(fileList,this.props.handleDrop)
	}
	render = () => 
		<div id="output" 
			className={`Neu ${this.props.className ? this.props.className : '' }`}
			style={{
				position: 'relative',
				width: 150,
				height: 150,
				backgroundColor: 'white',
				borderRadius: 5,
				overflow: 'hidden',
				...this.props.style
			}}
			onClick={this.handleClick}
			onDragLeave={this.onDragLeave}
			onDragEnter={this.onDragEnter}
			onDragOver={this.onDragOver}
			onDrop={this.doDrop}>
			<input 
				multiple={this.props.multiple || false}
				type="file"
				ref={this.inputElement}
				onChange={this.handleChange}
				accept="image/png,image/jpeg"
				style={{display: 'none',}}/> 
			{this.props.children}
			<DragIndicator active={this.state.dragEnter}/>
		</div>
}

DragAndDrop.defaultProps = {
	multiple: false
}

export default DragAndDrop