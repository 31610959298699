import React, { Component } from 'react'
import AvailabilityTable from '../../components/availabilityTable'

import API from '../../api/index'

import ModalContext from '../../context/modal'
import ContextMenuContext from '../../context/contextMenuContext'

import NetworkInfoModal from '../../components/modals/networkInfoModal'
import {ContextMenu, ContextMenuI} from '../../components/contextMenuLayer'

import moment from 'moment'

import '../../styles/coliveBooking.scss'
import AvailabilityDetailModal from '../../components/modals/availabilityDetailModal'

class Availability extends Component{
	constructor(props){
		super(props)
		this.closeModal 					= this.closeModal.bind(this)
		this.handleNextMonthLoad 	= this.handleNextMonthLoad.bind(this)
		this.handlePrevMonthLoad 	= this.handlePrevMonthLoad.bind(this)
		this.handleOption 				= this.handleOption.bind(this)
		this.handleAddBookingMenu = this.handleAddBookingMenu.bind(this)
		this.handleCheckOutMenu 	= this.handleCheckOutMenu.bind(this)
		this.handleShowDetailMenu = this.handleShowDetailMenu.bind(this)
		this.showDetail						= this.showDetail.bind(this)
	}
	handleAddBookingMenu = () => {
		
	}
	handleCheckOutMenu = () => {

	}
	handleShowDetailMenu = () => {

	}
	handleOption = (options,x,y) => {
		this.props.contextMenu.setContextMenu({
			active: true,
			component: 
				<ContextMenu>{
					options && options instanceof Array && options.length > 0 ? 
					<>{	
						options.map((object) => 
							<ContextMenuI 
								label={object.label} 
								onClick={object.onClick}/>)
					}</> : null
				}</ContextMenu>,
			x,y
		})
	}
	showDetail = (booking) => {
		try{
			// console.log('SHOW DETAIL BOOKING OBJECT', booking)
			const bookingId = booking.bookingId
			console.log('state data', this.state.data)
			let dailyFlag = true
			let bookingIndex = this.state.data.daily.findIndex(({ _id }) => bookingId == _id)
			if(bookingIndex == -1){
				dailyFlag = false
				bookingIndex = this.state.data.monthly.findIndex(({ _id }) => bookingId == _id)
			}
			if(bookingIndex == -1)
				throw new Error('Booking data not found')
			const bookingObject = dailyFlag ? this.state.data.daily[bookingIndex] : this.state.data.monthly[bookingIndex]
			console.log('MY FOUND BOOKING ', bookingObject)
			const name 					= bookingObject?.customer?.name 
			const bookingDate  	= bookingObject?.bookingDate
			const roomRequests 	= bookingObject.roomRequests
			const checkInDate 	= roomRequests.checkInDate
			const checkOutDate 	= roomRequests.checkOutDate
			const term 					= bookingObject.term
			let units	= []
			roomRequests.map((roomRequest) => {
				units.push(...roomRequest.units)
			})
			this.props.modal.setModal({
				active: true,
				component: <AvailabilityDetailModal data={bookingObject} units={this.props.property.units}/>
			})
		}catch(error){
			console.error(error)
		}
	}
	closeModal = () => this.props.modal.setModal({active: false,component: null})
	handleNextMonthLoad = () => {
		const start = moment(this.state.startDate, 'YYYY-MM-DD').add(1,'month').startOf('month')
		console.log('MY FUCKING START', start.format('YYYY-MM-DD'))
		const end = start.clone().endOf('month')
		console.log('MY FUCKING END', end.format('YYYY-MM-DD'))

		if(this.state.startDate && this.state.endDate)
			// this.loadData({startDate: this.state.startDate, endDate: moment(this.state.endDate, 'YYYY-MM-DD').add(1,'day').endOf('month').format('YYYY-MM-DD')})
			this.loadData({
				startDate: start.format('YYYY-MM-DD'),
				endDate: end.format('YYYY-MM-DD')
			})
	}
	handlePrevMonthLoad = () => {
		// if(this.state.startDate && this.state.endDate)
		// 	this.loadData({startDate: moment(this.state.startDate, 'YYYY-MM-DD').subtract(1,'day').startOf('month').format('YYYY-MM-DD'), endDate: this.state.endDate})
		const start = moment(this.state.startDate, 'YYYY-MM-DD').subtract(1,'month').startOf('month')
		console.log('MY FUCKING START', start.format('YYYY-MM-DD'))
		const end = start.clone().endOf('month')
		console.log('MY FUCKING END', end.format('YYYY-MM-DD'))

		if(this.state.startDate && this.state.endDate)
			// this.loadData({startDate: this.state.startDate, endDate: moment(this.state.endDate, 'YYYY-MM-DD').add(1,'day').endOf('month').format('YYYY-MM-DD')})
			this.loadData({
				startDate: start.format('YYYY-MM-DD'),
				endDate: end.format('YYYY-MM-DD')
			})
	}
	state = { 
		loading: false
	}
	loadData = async ({startDate,endDate} = {}) => {
		try{
			console.log('loadData startDate', startDate)
			console.log('loadData EndDate', endDate)
			if(this.state.loading == false && this.props.property && this.props.property._id){
				this.setState({loading: true})
				this.props.modal.setModal({ active:true, component: <NetworkInfoModal loading={true}/> })
				const result = await API.colive.availability.get({
					propertyId	: this.props.property._id,
					startDate		: startDate ? startDate : undefined,
					endDate			: endDate 	? endDate 	: undefined
				})
				console.log('Availability result :', JSON.stringify(result))
				if(result instanceof Error){
					this.props.modal.setModal({
						active		: true,
						component	: 
							<NetworkInfoModal 
								loading				={false} 
								closeModal		={this.closeModal}
								error					={true}
								errorResponse	={result.response}/>
					})
					this.setState({ loading : false })
				}else{
					this.props.modal.setModal({ active: false, component: null })
					if(result && result.data && result.endDate && result.startDate)
						this.setState({loading: false, data: result.data, startDate: result.startDate, endDate: result.endDate}, ()=> console.log('MY FUCKING STATE', this.state.data))
					else throw new Error('Result variables data incomplete')
				}
				console.log('result AVA', result)
			}
		}catch(e){ 
			console.error(e)
		}
	}
	componentDidMount(){
		if(this.props.property && this.props.property._id)
			this.loadData()
	}
	componentDidUpdate(prevProps, prevState){
		if(prevProps.property !== this.props.property)
			this.loadData()
	}
	next = () => {
		try{

		}catch(error){
			console.error(error)
		}
	}
	prev = () => {
		try{

		}catch(error){
			console.error(error)
		}
	}
	render = () => 
		<div 
			className={'TabPage Availability'} 
			style={{
				flexDirection	: 'column',
				overflow			: 'visible'
			}}>
			<div 
				className={'Neu'} 
				style={{
					overflow		:'visible',
					width				: 'min-content',
					height			: 'min-content',
					maxWidth		: 'calc(100% - 32px)',
					margin			: '16px 16px'
				}}>
				<AvailabilityTable
					optionMenu={this.handleOption}
					handleNextMonthLoad={this.handleNextMonthLoad}
					handlePrevMonthLoad={this.handlePrevMonthLoad}
					showDetail={this.showDetail}
					startDate={this.state.startDate}
					endDate={this.state.endDate}
					next={this.handleNextMonthLoad}
					prev={this.handlePrevMonthLoad}
					data={this.state.data}/>
			</div>			
		</div>
}

export default (props) => 
	<ContextMenuContext.Consumer>{
		(contextMenuValue) => 
			<ModalContext.Consumer>
				{value => <Availability	modal={value} contextMenu={contextMenuValue} {...props}/>}
			</ModalContext.Consumer>
	}</ContextMenuContext.Consumer>
