import firebase from 'firebase/app';
import 'firebase/messaging';

import {config} from '../configs/firebaseConfig'

const initializedFirebaseApp = firebase.initializeApp(config)
const messaging = initializedFirebaseApp.messaging()

messaging.usePublicVapidKey(
	// Project Settings => Cloud Messaging => Web Push certificates
  "BNu9DJ0hwpJA3MmocsB2VAWXgruHzWGX-PW3Ep85iSuCreMTdmXkqK7NkAPlBod-dFRhG4Jlxfwz-D5iKZ-KsDU"
)
export default messaging