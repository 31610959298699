import React, { Component } from 'react'
import qs from 'query-string'

import PropertySelector from '../components/propertySelector'
import TabMenu, {Tab} 	from '../components/tabMenu'

import Overview 				from './cowork/overview.jsx'
import Detail 					from './cowork/detail'
import Units 						from './cowork/units'
import Rates 						from './cowork/rates.jsx'
import Electricity 			from './cowork/electricity.jsx'
import Booking 					from './cowork/booking.jsx'
import Reports 					from './cowork/reports.jsx'
import Availability 		from './cowork/availability.jsx'

import NetworkInfoModal from '../components/modals/networkInfoModal'
import LoadIndicator 		from '../components/loadIndicator'

import API from '../api'

import '../styles/subPage.scss'

// * CONTEXT IMPORT
import ModalContext from '../context/modal'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	Redirect,
	withRouter
} from "react-router-dom"

const tabs = [
	{ name: 'Overview' 			},
	{ name: 'Units' 				},
	{ name: 'Detail' 				},
	{ name: 'Rates' 				},
	{ name: 'Booking' 			},
	{ name: 'Reports' 			},
	{ name: 'Availability' 	}
]

class CoWork extends Component{
	constructor(props){
		super(props)
		this.changeTab 				= this.changeTab.bind(this)
		this.setSelected 			= this.setSelected.bind(this)
		this.getCoworkData 		= this.getCoworkData.bind(this)
		this.refreshProperty 	= this.refreshProperty.bind(this)
	}
	state = {
		selected			: -1,
		propertyData	: [],
		tabSelected		: 0,
		loading				: false
	}
	setSelected = (index) => {
		try{
			const { title,history } = this.props
			const propertyLength 		= this.state.propertyData.length
			if(index > propertyLength-1 || index < 0) throw new Error('Selected index out of bounds')
			this.setState({ selected: index })
		}catch(e){ console.error(e) }
	}
	changeTab = (index) => {
		try{
			const { title,history } = this.props
			this.setState({ tabSelected: index })
			history.push(`/cowork/${tabs[index].name.toLowerCase()}`)
		}catch(e){ console.error(e) }
	}
	getCoworkData = async () => {
		try{
			this.setState({ loading: true })
			const result = await API.cowork.getAllCowork()
			this.setState({ loading: false })
			if(result instanceof Error) throw result
			const { data } =  result
			let propertyId
			if(this.state.selected >= 0){
				const {_id} = this.state.propertyData[this.state.selected]
				propertyId = _id
			}
			const index = data.findIndex((object) => object._id == propertyId)
			this.setState({ selected: index, propertyData: data })
		}catch(e){ console.error(e) }
	}
	componentDidMount = async () => {
		// TODO ! USE THIS AWESOME QUERY PROPERTY
		console.log('Query')
		console.log(qs.parse(this.props.location.search))
		this.getCoworkData()
	}
	componentDidUpdate = (prevProps,prevState) => {
		if(this.props.location.search !== prevProps.location.search){
			console.log('Query is changing')
			console.log(this.props)
		}
	}
	refreshProperty = () => { this.getCoworkData() }
	render = () => 
		<div className={'Cowork SubPage'}>
			<div style={{
				display				: 'flex', 
				flexDirection	: 'row', 
				alignItems		: 'center',
				paddingTop		: 8
			}}>
				<PropertySelector
					cowork				={true}
					loading				={this.state.loading}
					selected			={this.state.selected}
					setSelected		={this.setSelected}
					property			={this.state.propertyData}
					getCoworkData	={this.getCoworkData}/>
				<div 
					className={'cursor pointer'}
					style={{ width: 40, height: 40, display: 'flex', justifyContent	: 'center', alignItems: 'center', marginLeft: 8 }}
					onClick={this.refreshProperty}>
					<LoadIndicator style={{ height: '50%', width: '50%' }} className={this.state.loading ? 'animation rotate' : ''}/>
				</div>
			</div>
			<TabMenu>{
				tabs.map((object,index) => 
					<Tab
						key			={index}
						active	={this.props.history.location.pathname.includes(object.name.toLowerCase())}
						label		={object.name}
						onClick	={() => this.changeTab(index)}/>
				)
			}</TabMenu>
			<Switch>
				<Route 					path={'/cowork/overview'}>
					<Overview 		property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route 					path={'/cowork/units'}>
					<Units 				property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route 					path={'/cowork/detail'}>
					<Detail 			property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route 					path={'/cowork/rates'}>
					<Rates 				property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route 					path={'/cowork/booking'}>
					<Booking			property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route 					path={'/cowork/reports'}>
					<Reports 			property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route 					path={'/cowork/electricity'}>
					<Electricity 	property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route 					path={'/cowork/availability'}>
					<Availability property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route 					path={'/cowork/'}>
					<Redirect 		to={{pathname: '/cowork/overview'}}/>
				</Route>
			</Switch>
		</div>
}

const CoworkWithContext = (props) => 
	<ModalContext.Consumer>
	{value => <CoWork {...props} modal={value}/>}
	</ModalContext.Consumer>

export default withRouter(CoWork)