import axios, { AxiosRequestConfig } from 'axios'
import { getColiveOverviewRoute } from '../routes' 
import { API_HOST } from '../../constants'
import method from '../../api/route/method'
import querystring from 'querystring'

export interface ColiveUnitTypeOverviewData{
  unitTypeId: string,
  unitTypeName: string,
  totalUnit: number,
  free: number,
  renovEtc: number
  totalBooked: number,
  available: number,
  occupancy: number,
  price: number,
  revenue: number
}

export interface GetColiveOverviewData{
  coliveId: string
  name: string,
  unitTypes: ColiveUnitTypeOverviewData[]
}

export const getColiveOverview = async ({ propertyId, startDate }: { propertyId: string, startDate: string }): Promise<GetColiveOverviewData | Error> => {
  try{
    const config: AxiosRequestConfig = {
      baseURL: API_HOST,
      url: getColiveOverviewRoute.path + '?' + querystring.stringify({
        propertyId,
        startDate,
      }),
      method: getColiveOverviewRoute.method,
    }
    const result = await axios(config)
    return result?.data?.data
  }catch(error){
    console.error(error)
    return error
  }
}


// "data": {
//   "coliveId": "5eb64befae6b0c056956ebfe",
//   "name": "CoLive Marina Airport",
//   "unitTypes": [
//       {
//           "unitTypeId": "5ebbfa1c0ffc7f057567dbba",
//           "unitTypeName": "Premium",
//           "totalUnit": 8,
//           "totalBooked": 8,
//           "available": 0,
//           "occupancy": 0,
//           "price": 138000,
//           "revenue": 7265060
//       },
//       {
//           "unitTypeId": "5ebbfa1c0ffc7f057567dbbb",
//           "unitTypeName": "Suite",
//           "totalUnit": 13,
//           "totalBooked": 11,
//           "available": 2,
//           "occupancy": 0.15384615384615385,
//           "price": 158000,
//           "revenue": 23368600
//       }
//   ]
// }