import React, { Component } from 'react'
import './styles/header.scss'
import Neu,{DropDown} from '../components/neu'
import menu from '../assets/images/menu.svg'
import close from '../assets/images/close.svg'
import ProfileCard from '../components/profileCard'
import Bell from '../components/svg/bell'
import getSafe from '../utility/getSafe'
import SidebarState from '../context/sideBar'

import Notification from '../components/notification'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
  Redirect
} from "react-router-dom"


class SideHam extends Component{
	constructor(props){
		super(props)
	}
	handleClick = (e) => {
		if(!this.props.sidebar.active){
			e.preventDefault()
			e.stopPropagation()
			this.props.sidebar.setActive()
		}
	}
	render = () => 
		<Neu 
			toggle={false}
			onClick={this.handleClick}
			className={`menuContainer ${this.props.sidebar.active ? 'active' : ''}`}>
			<img className={'E'} src={menu}/>
			<img className={'X'} src={close}/>
		</Neu>
}

const SideNav = (props) =>
	<SidebarState.Consumer>{
		({active,setActive}) => 
			<SideHam sidebar={{active,setActive}} {...props}/>
	}</SidebarState.Consumer>

class Header extends Component{
	constructor(props){
		super(props)
	}
	state = {
		on: false
	}
	render = () => 
		<header className={'Header'}>
			<div 
				className={'linkTitleContainer'}>
				<Switch>
					<Route exact path={'/'}>
						<h1>Dashboard</h1>
					</Route>
					<Route path={'/colive'}>
						<h1>CoLive</h1>
					</Route>
					<Route path={'/cowork'}>
						<h1>CoWork</h1>
					</Route>
					<Route path={'*'}>
						<h1>PMS</h1>
					</Route>
				</Switch>
			</div>
			<Notification/>
			<ProfileCard/>
			<SideNav/>
		</header>
}

export default Header