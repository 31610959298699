import React, { Component } from 'react'
import ModalContext from '../context/modal'
import './styles/modal.scss'

class Modal extends Component{
	constructor(props){
		super(props)
		this.closeModal = this.closeModal.bind(this)
		this.setWrapperRef = this.setWrapperRef.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
		this.innerRef = null
	}
	setWrapperRef = (ref) => {
		this.innerRef = ref
	}
	handleClickOutside = (e) => {
		if(this.innerRef && !this.innerRef.contains(e.target)){
			e.stopPropagation()
			e.preventDefault()
			this.props.modal.setModal({
				active: false,
				component: null
			})
		}
	}
	closeModal = () => {
		console.log('closing modal')
		this.props.modal.setModal({
			active: false,
			component: null
		})
	}
	render = () => 
		<div 
			onMouseDown={this.handleClickOutside}
			className={`Modal ${this.props.modal.active ? 'active' : ''}`}>
			<div 	
				ref={ref => this.setWrapperRef(ref)}
				className={'innerModal'}>{
				this.props.modal.component ? 
					React.cloneElement(this.props.modal.component, {
						closeModal: this.closeModal,
					},null) : null
			}</div>
		</div>
}
export default (props) => 
	<ModalContext.Consumer>{
		value => <Modal modal={value} {...props}/>
	}</ModalContext.Consumer>