import React, { Component } from 'react'
// * COMPONENT import
import Neu, {DropDown} from '../neu'
// import Selector from '../selector'
import UnitSelector from '../unitSelector'

// * API import
import API from '../../api'

// * UTILITY import
import getSafe from '../../utility/getSafe'

class EditUnitModal extends Component{
	constructor(props){
		super(props)
		// this.handleSelect = this.handleSelect.bind(this)
		this.onChangeSelect = this.onChangeSelect.bind(this)
	}
	state = {
		nameInput: this.props.placeholder,
		selectedIndex :this.props.selectedIndex,
	}
	// handleSelect = (index) => {
	// 	console.log('handleSELECT', index)
	// 	this.setState({
	// 		selectedIndex: index
	// 	})
	// }
	onChangeSelect = (index) => this.setState({
		selectedIndex: index
	})
	render = () => 
		<div 
			className={'main_bg dropShadow modalPadding radius'} 
			style={{

			}}>
			<UnitSelector 
				// setSelected={this.handleSelect}
				// selected={this.state.selectedIndex}
				initialIndex={this.props.selectedIndex}
				onChange={this.onChangeSelect}
				unit={false}
				style={{
					marginLeft: 0,
				}}
				data={this.props.unitTypes}/>
			<div style={{width: '100%'}}>
				<input 
					style={{
						marginTop: 16,
						width: 'calc(100% - 16px)',
						borderRadius: 5,
						border: 0,
						padding: 8
					}}
					onChange={(e) => 	this.setState({nameInput: e.target.value})}
					value={this.state.nameInput}
					placeholder={'name'}/>
			</div>
			<div>
				<div 
					onClick={(e) => this.props.confirm(this.state.nameInput,this.props.index,this.state.selectedIndex)}
					className={'cursor pointer'}
					style={{
						width: 'auto',
						backgroundColor: 'white',
						borderRadius: 5,
						marginTop: 16,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: 8
					}}>
					<h5>Edit</h5>
				</div>
				<div style={{width: 16}}/>
				<div 
					onClick={this.props.closeModal}
					className={'cursor pointer'}
					style={{
						width: 'auto',
						backgroundColor: 'white',
						borderRadius: 5,
						marginTop: 16,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: 8
					}}>
					<h5>Cancel</h5>
				</div>
			</div>
			

		</div>
}

export default EditUnitModal