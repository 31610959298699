import React, { Component, useState, CSSProperties } from 'react'
import './styles/neu.scss'

interface IPropsDropDown{
	children?		: string
	className?	: string
	style?			: CSSProperties
	setRef?			: any
}

interface IPropsNeu{
	setRef?			: (instance: React.RefObject<HTMLInputElement> | null) => void
	state?			: Boolean
	toggle?			: Boolean
	style? 			: CSSProperties
	className?	: string
  onClick?		: (event: React.MouseEvent<HTMLElement>) => void
}

interface IStateNeu{
	active: Boolean
}


export const DropDown = ({children,className,style,setRef} : IPropsDropDown) => 
	<div 
		ref={setRef}
		onClick={(e) => {
			e.preventDefault()
			// e.stopPropagation()
		}}
		style={{
			// height: props.children.length * 40
			...style
		}}
		className={`DropDown ${className ? className : ''}`}>
		{children}
	</div>


class Neu extends Component<IPropsNeu,IStateNeu>{
	constructor(props:IPropsNeu){
		super(props)
		this.state = {
			active: false
		}
	}
	static defaultProps: IPropsNeu = {
		state		: true,
		toggle	: true
	}
	xRef: any
	eventListener: any
	handleMouseDown = (e: MouseEvent) => {
		if(this.xRef && !this.xRef.contains(e.target) && this.state.active){
			e.stopPropagation()
			e.preventDefault()
			this.setState({ active: false })
		}
	}
	componentDidUpdate = () => {
		if(this.state.active){
			this.eventListener = document.addEventListener('mousedown', this.handleMouseDown)
		}else if(!this.state.active){
			document.removeEventListener('mousedown', this.handleMouseDown)
			this.eventListener = null
		}
	}
	myRef = (ref: any) => {
		this.xRef = ref
		if(this.props.setRef instanceof Function) this.props.setRef(ref)
	}
	toggle:(bool?:boolean) => any = (bool = false) => 
		this.setState({ 
			active: !this.state.active
		},() => !bool && !this.props.state ? setTimeout(() => this.toggle(true),200) : false)
	render = () => 
		<div 
			ref={this.myRef}
			// {...this.props}
			style={{ ...this.props.style }}
			className={`Neu 
			${this.props.toggle && this.state.active ? 'active' : 'inactive'} 
			${this.props.className ? this.props.className : ''}`}
			onClick={(e) => {
				try{
					this.toggle()
					if(this.props.onClick)
						this.props.onClick(e)
				}catch(e){
					console.error(e)
				}
			}}
			// onClick={this.props.onClick}
			>
			{this.props.children}
		</div>
}


export default Neu