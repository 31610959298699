import {GET,POST,PATCH,DELETE} from './route/method'

import colive from './route/colive.js'
import cowork from './route/cowork.js'
import user 	from './route/user.js'
// import report from './route/report.js'

const route = {
	registerUUID	: {
		path				: '/uuid/register',
		method			: 'POST'
	},
	mirrorArray		: {
		path				: '/mirrorArray',
		method			: 'POST'
	},
	registerFCM 	: {
		path				: '/fcm/register',
		method			: 'POST'
	},
	user					: user,
	bugReport: {

	},
	colive				: colive,
	cowork				: cowork,
	dashboard: {
		
	},
}
// console.log(route)
export default route