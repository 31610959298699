import React, { Component, PureComponent } from 'react'
import ContextMenuContext from '../context/contextMenuContext'

export const ContextMenuI = (props) => 
	<div 
		className={'hover tableItem whitening '}
		onClick={props.onClick}
		style={{
			height: 32, 
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			paddingLeft: 16,
			width: '100%'}}>
		<h5>{props.label}</h5>
	</div>

export class ContextMenu extends PureComponent{
	render = () => {
		return(<div 
			className={'dropShadow'}
			style={{
				// width:'min-content', 
				width: 128,
				height: 'min-content', 
				backgroundColor: 'white',
				overflow: 'hidden',
				borderRadius: 5,
				zIndex: 9999999}}>
			{this.props.children}
		</div>)
	}
}

class ContextMenuLayer extends Component{
	constructor(props){
		super(props)
		this.closeContextMenu 	= this.closeContextMenu.bind(this)
		this.setWrapperRef 			= this.setWrapperRef.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
		this.innerRef = null
	}
	setWrapperRef = (ref) => {
		this.innerRef = ref
	}
	handleClickOutside = (e) => {
		if(this.innerRef && !this.innerRef.contains(e.target)){
			e.stopPropagation()
			e.preventDefault()
			this.props.contextMenu.setContextMenu({
				active: false,
				component: null
			})
		}
	}
	closeContextMenu = () => 
		this.props.contextMenu.setContextMenu({
			active: false,
			component: null
		})
	
	render = () => 
		<div 
			className={'ContextMenuLayer'}
			onMouseDown={this.handleClickOutside}
			style={{
				display: this.props.contextMenu.active ? 'flex' : 'none',
				position: "fixed",
				top: 0,
				left: 0,
				width: '100vw',
				height: '100vh',
				// backgroundColor: 'rgba(0,0,0,0.2)',
				zIndex: 999999
			}}>
			<div 	
				ref={ref => this.setWrapperRef(ref)}
				style={{
					position: 'absolute',
					top: this.props.contextMenu.y,
					left: this.props.contextMenu.x,
					width: 'min-content',
					maxWidth: '100%',
					height: 'min-content',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>{
				// this.props.contextMenu.component ? 
				// 	React.cloneElement(this.props.contextMenu.component, {
				// 		closeContextMenu: this.closeContextMenu,
				// 	},null) : null
					this.props.contextMenu.component ? 
						this.props.contextMenu.component : null
			}</div>
		</div>
}

export default (props) => 
<ContextMenuContext.Consumer>
{ (value) => <ContextMenuLayer {...props} contextMenu={value}/> }
</ContextMenuContext.Consumer>