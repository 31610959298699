import React, { Component } from 'react'
import formatRupiah from '../utility/rupiahFormator'

export interface OverviewRoomTypeData {
  _id:                string
  name:               string
  unitTypeName:       string
  totalRoom:          number
  dailyRoomBooked:    number
  dailyRoomRate:      number
  dailyRevenue:       number
  monthlyRoomBooked:  number
  monthlyRoomRate:    number
  monthlyRevenue:     number
  free:               number
  renov:              number
  emptyRoom:          number
  totalRoomBooked:    number
  avgRoomRate:        number
  occRate:            number
  revenue:            number

  rate?:              number
}

export interface TotalOverviewData {
  totalRoom:            number
  totalDailyBooked:     number
  totalDailyRevenue:    number
  totalMonthlyBooked:   number
  totalMonthlyRevenue:  number
  totalFree:            number
  totalRenov:           number
  totalEmpty:           number
  totalBooked:          number
  averageRoomRate:      number
  averageOccRate:       number
  totalRevenue:         number
}

export interface OverviewTableData {
  roomTypes: OverviewRoomTypeData[]
  total: TotalOverviewData
}

interface OverviewTableView {
  data: OverviewTableData
}

const TABLE_VIEW_TITLE: string[] = ["Hotel", "Type", "Total Room", "Daily Room Booked", "Daily Room Rate", "Daily Revenue", "Monthly Room Booked", "Monthly Room Rate", "Monthly Revenue", "Free", "Renov", "Empty Room", "Total Room Booked", "Average Room Rate", "Occ. Rate", "Total Revenue"]

const OverviewTableView = ({ data } : OverviewTableView) => {
  return (
    <div style={{ marginLeft: 24, marginBottom: 24, marginRight: 24}}>
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
          {
            TABLE_VIEW_TITLE.map((title, index) => <th style={{ whiteSpace: 'normal', border: '1px solid #2c2c2c', paddingLeft: 8, paddingRight: 8 }} key={index}>
              {title}
            </th>)
          }
          </tr>
        </thead>
        <tbody>
        {
          // data per room types
          data.roomTypes.map(({name, unitTypeName, totalRoom, dailyRoomBooked, dailyRoomRate, dailyRevenue, monthlyRoomBooked, monthlyRoomRate, monthlyRevenue, free, renov, emptyRoom, totalRoomBooked, avgRoomRate, occRate, revenue}, index) => 
            <tr key={index}>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{name}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{unitTypeName}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{totalRoom}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{dailyRoomBooked}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{formatRupiah(Math.floor(dailyRoomRate))}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{formatRupiah(Math.floor(dailyRevenue))},00</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{monthlyRoomBooked}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{formatRupiah(Math.floor(monthlyRoomRate))}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{formatRupiah(Math.floor(monthlyRevenue))},00</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{free}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{renov}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{emptyRoom}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{totalRoomBooked}</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{formatRupiah(Math.floor(avgRoomRate))},00</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{Math.floor(occRate*100)}%</td>
              <td style={{ whiteSpace: 'nowrap', border: '1px solid #2c2c2c'}}>{formatRupiah(Math.floor(revenue))},00</td>
            </tr>)
          }
          {/* {ROW CONTAINS TOTAL CALCULATION} */}
          <tr>
            <td style={{border: '1px solid #2c2c2c', fontWeight: 'bold' }}>TOTAL atau AVERAGE</td>
            <td></td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{data.total.totalRoom}</td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{data.total.totalDailyBooked}</td>
            <td></td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{formatRupiah(Math.floor(data.total.totalDailyRevenue))}</td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{data.total.totalMonthlyBooked}</td>
            <td></td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{formatRupiah(Math.floor(data.total.totalMonthlyRevenue))}</td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{data.total.totalFree}</td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{data.total.totalRenov}</td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{data.total.totalEmpty}</td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{data.total.totalBooked}</td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{formatRupiah(Math.floor(data.total.averageRoomRate))}</td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{Math.floor(data.total.averageOccRate*100)}%</td>
            <td style={{ border: '1px solid #2c2c2c', fontWeight: 'bold'}}>{formatRupiah(Math.floor(data.total.totalRevenue))}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default OverviewTableView