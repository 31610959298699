import React, { Component } from 'react'
import moment from 'moment'
import formatrupiah from '../../utility/rupiahFormator'

// bookingId
// name,
// bookingDate
// checkInDate
// checkOutDate
// units
// term

class AvailabilityDetailModal extends Component<any,any>{
  constructor(props: any){
    super(props)
    console.log('this.props.data', this.props.data)
  }
  getUnits = (): any => {
    try{
      let units:any[] = []

      this.props.data.roomRequests.map((roomRequest: any) => {
        roomRequest.units.map((unitId:any) => {
          units.push(unitId)
        })
      })
      return (
        <>{
          units.map((unit) => {
            return (<h5>{this.props.units.find((unitObject:any) => unitObject._id == unit).name.toString()}</h5>)
          })
        }</>
      )
    }catch(error){
      console.error(error)
      return null
    }
  }
  render = () =>
    <div 
      className={'main_bg dropShadow modalPadding radius'} 
      style={{ width: 400, maxWidth: '80%'}}>
        <div>
          <h3>Booking Id</h3>
          <h5>{this.props.data._id}</h5>
        </div>
        <div style={{marginTop: 8}}>
          <h3>Name</h3>
          <h5>{this.props.data.customer.name}</h5>
        </div>
        <div style={{marginTop: 8}}>
          <h3>Check In</h3>
          <h5>{moment(this.props.data.roomRequests[0].checkInDate).format('YYYY-MM-DD')}</h5>
        </div>
        <div style={{marginTop: 8}}>
          <h3>Check Out</h3>
          <h5>{moment(this.props.data.roomRequests[0].checkOutDate).format('YYYY-MM-DD')}</h5>
        </div>
        <div style={{marginTop: 8}}>
          <h3>Units</h3>
          <h5>{this.getUnits()}</h5>
        </div>
        <div style={{marginTop: 8}}>
          <h3>Price</h3>
          <h5>Rp&nbsp;{formatrupiah(this.props.data.price.toString())},00</h5>
        </div>
      <div style={{ width: 16 }}/>
      <div style={{ display : 'flex', flexDirection: 'row' }}>
      </div>
    </div>
}

export default AvailabilityDetailModal