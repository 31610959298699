import axios, { AxiosRequestConfig } from 'axios'
import { cancelBookingRoute } from '../routes' 
import { API_HOST } from '../../constants'

export const cancelBooking = async (coliveId: string, bookingId: string, status: boolean): Promise<any> => {
  try{
    const apiRoute = cancelBookingRoute(coliveId)
    const config: AxiosRequestConfig = {
      baseURL: API_HOST,
      url: apiRoute.path,
      method: apiRoute.method,
      data: {
        bookingId: bookingId,
        status: status
      }
    }
    const result = await axios(config)
    return result?.data
  }catch(error){
    console.error(error)
    return error
  }
}