import React, { PureComponent, CSSProperties } from 'react'
import Neu from './neu'

export interface IPropsUnitAssignmentSelector{
  index   : number
  data    : {
    _id   : string
    name  : string
    unitTypeId : string
  }[]
  unitTypeId: string
  selected: number[]
  onChangeSelection: (event: React.MouseEvent<HTMLElement>,roomRequestIndex: number, selected: number[]) => void 
}


const Unit = ({name, selected, onClick, style = {}} : { name: string, selected: boolean, onClick : (event: React.MouseEvent<HTMLElement>) => void, style?: CSSProperties }) => 
  <Neu
    className={'cursor hover'}
    state={false}
    style={{ 
      display                 : 'flex',
      justifyContent          : 'center',
      alignItems              : 'center',
      borderTopLeftRadius     : 8,
      borderBottomLeftRadius  : 8,
      borderTopRightRadius    : 8,
      borderBottomRightRadius : 8,
      height                  : 24,
      minWidth                : 42,
      backgroundColor         : selected ? '#2c2c2c' : 'transparent',
      color                   : selected ? 'white' : 'black',
      ...style
    }}
    onClick={onClick}>
    <h5>{name}</h5>
  </Neu>

class UnitAssignmentSelector extends PureComponent<IPropsUnitAssignmentSelector>{
  handleUnitClick = (event:React.MouseEvent<HTMLElement>, index: number) => {
    try{
      let selected: number[] = [...this.props.selected]
      const flag = this.props.selected.findIndex((number => number == index))
      if(flag !== -1){
        selected.splice(flag,1)
      }else{
        selected.push(index)
        selected.sort()
      }
      // console.log('selected', selected)
      this.props.onChangeSelection(event, this.props.index ,selected)
    }catch(error){ console.error(error) }
  }
  render = () => 
    <div className={''} style={{ display: 'flex', flexDirection: 'row', padding: 8, width: '100%', overflowX: 'auto'}}>
    <>
    {
      this.props?.data?.map(({ name, unitTypeId }, index) => 
        <Unit 
          key={index}
          style={{marginRight: 12}}
          name={name}
          selected={this.props.selected.includes(index)} 
          onClick={(event) => this.handleUnitClick(event, index)}/>)
    }
    </>
    {/* Scroll hack */}
    <div style={{minWidth: 1, height: 1}}/>
    </div>
}

export default UnitAssignmentSelector