import React, { Component } from 'react'

import LoadIndicator from '../loadIndicator'
import getSafe from '../../utility/getSafe'

class NetworkInfoModal extends Component{
	componentDidMount = async () => {
		if(this.props.loading === true){
			await this.timeout(1000)
			this.animate() 
		}
	}
	timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms))
	componentDidUpdate 	= () => { if(this.props.loading == false && this.state.loadingText.length > 0) this.setState({ loadingText: '' }) }
	animate = () => {
		if(this.state.loadingText.length == 0) 			this.setState({ loadingText: '.'													 })
		else if(this.state.loadingText.length >= 5) this.setState({ loadingText: ''														 })
		else 																				this.setState({ loadingText: this.state.loadingText + ' .' })
	}
	state = { loadingText: '' }
	parseErrorResponse = () => {
		try{
			// console.log(this.props.errorResponse)
			if(this.props.errorResponse){
				const { data } = this.props.errorResponse
				if(!data) return this.props.errorResponse
				else 			return data.message
			}else return 'Error'
		}catch(e){ 
			console.warn(e)
			return 'Error'
		}
	}
	render = () =>	
		<div style={{
			width						: 512,
			maxWidth				: '80%',
			height					: 'min-content',
			backgroundColor	: '#E3EDF7',
			boxShadow				: '4.5px 4.5px 8px rgba(163, 177, 198,0.6)',
			borderRadius		: 5,
			display					: 'flex',
			flexDirection		: 'column',
			justifyContent	: 'center',
			alignItems			: 'center',
			paddingTop			: 24,
			paddingBottom		: 24,
			transition			: 'all 300ms ease'
		}}>
			<LoadIndicator className={`${this.props.loading ? `animation rotate`: ''}`} style={{
				width		: 72,
				height	: 72,
			}}/>
			<h5 style={{
				width			: this.props.loading ? 80 : 'auto',
				marginTop	: 24}}>{
					this.props.loading ? `Loading ${this.state.loadingText}` : this.parseErrorResponse()}</h5>
			<div 
				className={'cursor pointer'} 
				style={{
					marginTop				: 24,
					width						: '50%',
					height					: !this.props.loading ? 32 : 0,
					backgroundColor	: 'red',
					display					: 'flex',
					boxShadow				: '4.5px 4.5px 8px rgba(163, 177, 198,0.6)',
					justifyContent	: 'center',
					alignItems			: 'center',
					borderRadius		: 5,
					transition			: 'height 300ms ease'
				}}
				onClick={this.props.closeModal}>
				<h5 style={{color: 'white'}}>OK</h5>
			</div>
		</div>
}

export default NetworkInfoModal

{/* <NetworkInfoModal 
	loading={false} 
	closeModal={this.closeModal}
	error={true}
	errorDetail={{}}/> */}