import React, { Component, useState, useEffect } from 'react'
import moment, { Moment } from 'moment'
import { getAvailability, UnitTypeAvailabilityData } from '../../v2/functions/getAvailability'
import Neu from '../../components/neu'
import { number } from 'prop-types'
import Left from '../../components/svg/left'
import Right from '../../components/svg/right'

const knowCheckOut = (x: {
  status: boolean,
  timestamp?: Date
} | undefined) => {
  if(x === undefined) 
    return false
  else if(x.status === true){
    if(x.timestamp !== undefined) return true
    else return false
  }
  return false
}

const NAvailability = ({property}: {property: any}) => {
  const [selectedDate, setSelectedDate] = useState<Moment>(moment().startOf('month'))
  const [unitTypeAvailData, setUnitTypeAvailData] = useState<UnitTypeAvailabilityData[] | undefined>(undefined)
  const loadData = async () => {
    try{
      setUnitTypeAvailData(undefined)
      const result = await getAvailability(property._id, Number(selectedDate.format('YYYY')), Number(selectedDate.format('MM')))
      if(result instanceof(Error)) throw result
      setUnitTypeAvailData(result)
      console.log(result)
    }catch(error){
      console.error(error)
    }
  }
  const prevMonth = () => setSelectedDate(selectedDate.clone().subtract(1, 'month'))
  const nextMonth = () => setSelectedDate(selectedDate.clone().add(1, 'month'))
  useEffect(() => {
    loadData()
  }, [selectedDate, property])

  const colorify = (date: Moment) => {
    if(moment().format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) return '#ccc'
    switch(date.day()){
      case 0:
        return 'rgb(237, 50, 55)'
      case 5:
      case 6:
        return 'green'
      default: 
        return '#2c2c2c'
    }
  }
  const dateHeader = () => {
    const numberOfDays = selectedDate.daysInMonth()
    const elements = []
    elements.push(<td key={-1}><div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>⏲</div></td>)
    for(let i=0; i < numberOfDays ; i++){
      elements.push(
        <td key={i}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  width: 48, height: 48, backgroundColor: colorify(selectedDate.clone().add(i, 'days')), color: 'white'}}>
            <label>{i+1}</label>
            <label>{selectedDate.clone().add(i, 'days').format('ddd')}</label>
          </div>
        </td>
      )
    }
    return elements
  }
  const calculateFontColor = (free: boolean | undefined, cancel: boolean | undefined, forcedClose: boolean | undefined) => {
    if(free === true || cancel === true || forcedClose === true)
      return 'white'
    else return 'black'
  }
  const calculateBGcolor = (free: boolean | undefined, cancel: boolean | undefined, forcedClose: boolean | undefined, monthly: boolean | undefined) => {
    if(free === true) return 'green'
    if(forcedClose === true) return 'black'
    if(cancel === true) return 'red'
    if(monthly === true) return '#ccc'
    else return 'white'
  }
  const renderBookingItem = (length: number, monthly: boolean, bookingId: string, customerName: string, leftOverflow?: number, setMonthlyFlag?: () => void, checkOut?: boolean, forcedClosed?: boolean, free?: boolean, cancel?: boolean): JSX.Element => {
    return (
      <>
        {
          monthly === true && checkOut === false && setMonthlyFlag !== undefined ? 
          <div key={bookingId} 
            className={'dropShadow'} 
            style={{ 
              position: 'absolute', 
              width: (48 * selectedDate.daysInMonth()), 
              backgroundColor: forcedClosed === true ? 'black' : 'white', 
              height: '70%', 
              top: '50%', 
              left: leftOverflow !== undefined ? `0%` : '50%', 
              transform: 'translate(0,-50%)', 
              borderRadius: 4, 
              borderTopLeftRadius: leftOverflow !== undefined ? 0 : undefined,
              borderBottomLeftRadius: leftOverflow !== undefined ? 0 : undefined,
              zIndex: 9
            }} 
            onContextMenu={(event) => {
              event.preventDefault()
              event.stopPropagation()
              console.log('hello')
            }}>
            <h5 style={{ maxHeight: '100%', maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: calculateFontColor(free, cancel, forcedClosed) }}>{customerName}</h5>
          </div> : null
        }
        <div key={bookingId} 
          className={'dropShadow'} 
          style={{ 
            position: 'absolute', 
            width: (48 * length) - 12 - (leftOverflow !== undefined ? (48 * (leftOverflow)) -24 : 0), 
            backgroundColor:  calculateBGcolor(free,cancel,forcedClosed,monthly),
            height: '70%', 
            top: '50%', 
            left: leftOverflow !== undefined ? `0%` : '50%', 
            transform: 'translate(0,-50%)', 
            borderRadius: 4, 
            borderTopLeftRadius: leftOverflow !== undefined ? 0 : undefined,
            borderBottomLeftRadius: leftOverflow !== undefined ? 0 : undefined,
            zIndex: 9
          }} 
          onContextMenu={(event) => {
            event.preventDefault()
            event.stopPropagation()
            console.log('hello')
          }}>
          <h5 style={{ maxHeight: '100%', maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: calculateFontColor(free, cancel, forcedClosed)}}>{customerName}</h5>
        </div>
      </>
    )
  }
  const RenderBookings = (index: number, index2: number, xbool: boolean): JSX.Element[] => {
    let elements: JSX.Element[] = []
    if(unitTypeAvailData === undefined) return []
    const unitBookings = unitTypeAvailData[index]?.units[index2].bookings
    console.log('unitBookings', unitBookings)
    const numberOfDays = selectedDate.daysInMonth()
    const start = selectedDate.clone().startOf('month')
    let monthlyFlag: any = false
    const setMonthlyFlag = () => {
      console.log('setting monthly flag')
      monthlyFlag = true
    }
    const RenderBookingItems = (i: number, setMonthlyFlag: () => void) => {
      let elements: JSX.Element[] = []
      unitBookings.map(({checkInDate, checkOutDate, _id, customerName, term, checkOut, forcedClose, free, cancel }) => {
        if(i === 0){
          if(moment(checkInDate) < selectedDate.clone().startOf('month'))
            return elements.push(renderBookingItem(moment(knowCheckOut(checkOut) ? moment(checkOut.timestamp) : checkOutDate).diff(moment(checkInDate), 'days'), term === 'MONTHLY', _id, customerName, selectedDate.clone().startOf('month').diff(moment(checkInDate), 'days'), setMonthlyFlag, checkOut.status, forcedClose, free, cancel))
        }
        if(checkInDate === start.clone().add(i, 'days').format('YYYY-MM-DD'))
          return elements.push(renderBookingItem(moment(knowCheckOut(checkOut) ? moment(checkOut.timestamp) : checkOutDate).diff(moment(checkInDate), 'days'), term === 'MONTHLY', _id, customerName, undefined, setMonthlyFlag, checkOut.status, forcedClose, free, cancel))
      })
      return elements
    }
    console.log('myNumberOfDays', numberOfDays)
    for(let i=0; i<numberOfDays; i++){
      elements.push(
        <td key={Math.random()} style={{ position: 'relative', borderRight: i + 1 === numberOfDays ? undefined : '1px solid #2c2c2c', borderBottom: xbool? undefined:'1px solid #2c2c2c', backgroundColor: monthlyFlag === true ? 'pink': undefined }}>
          {RenderBookingItems(i, setMonthlyFlag)}
        </td>
      )
    }
    return elements
  }
  const RenderUnitTypeGroup = () => {
    const numberOfDays = selectedDate.daysInMonth()
    const elements: JSX.Element[] = []
    if(unitTypeAvailData !== undefined)
      unitTypeAvailData?.map((unitTypeAvailabilityData: UnitTypeAvailabilityData, index) => {
        elements.push(
          <tr key={'b' + index.toString() + 'x'}>
            <td colSpan={numberOfDays+1} style={{ backgroundColor: '#2c2c2c'}}><h3 style={{ whiteSpace: 'nowrap', color: 'white' }}>{unitTypeAvailabilityData.name}</h3></td>
          </tr>
        )
        unitTypeAvailabilityData.units.map(({name}, index2) => {
          elements.push(
            <tr key={'a'+ index.toString() +'x' + index2.toString()}>
              <td align={'center'} style={{ borderRight: '1px solid #2c2c2c', borderBottom: unitTypeAvailabilityData.units.length - 1 === index2 ?  '0px' : '1px solid #2c2c2c', height: 24, zIndex: 111}}>
                <div style={{ width: '100%', height: '100%' , zIndex: 10}}>
                  <label style={{ whiteSpace: 'nowrap', color: '#2c2c2c' }}>{name}</label>
                </div>
              </td>
              {RenderBookings(index, index2, index2+1 === unitTypeAvailabilityData.units.length)}
            </tr>
          )
        })
      })
    return elements
  }
  const renderMonth = () => selectedDate.format('MMMM')
  return (
    <div style={{ overflow: 'scroll' }}>
      <Neu style={{ width: 'min-content', height: 'min-content', padding: 16, margin: 16}} toggle={false}>
        <div style={{ width: '100%', height: 32, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 8 }}>
          <Neu
            onClick={prevMonth}
            style={{ width: 24, height: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#2c2c2c'}}
            state={false}>
            <Left style={{height: '50%', width: '50%', fill: '#FFF'}}/>
          </Neu>
          <h5 style={{marginLeft: 12, marginRight: 12}}>{renderMonth()}</h5>
          <Neu 
            onClick={nextMonth}
            style={{ width: 24, height: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#2c2c2c'}}
            state={false}>
            <Right style={{height: '50%', width: '50%', fill: '#FFF'}}/>
          </Neu>
        </div>
        <table cellSpacing={0} cellPadding={0} style={{ border: '1px solid #2c2c2c', borderRadius: 8, overflow: 'hidden' }}>
          <thead>
            <tr>
              {dateHeader()}
            </tr>
          </thead>
          <tbody>
          {RenderUnitTypeGroup()}
          </tbody>
        </table>
      </Neu>
    </div>
  )
}

export default NAvailability