import React, { Component } from 'react'
import '../styles/electricityAddModal.scss'
import UnitSelector from '../unitSelector'
import DragAndDrop from '../../components/DragAndDrop'
import Plus from '../../components/svg/plus'

const InputImage = (props) => 
	<div className={`InputImage ${props.src ? 'available' : ''}`}>{
		props.src ? 
			<img src={props.src} alt={props.alt || ''} style={{
				objectFit: 'cover'
			}}/> : 
			<div className={'inputPlus'}>
				<Plus/>
			</div>
	}</div>

class ElectricityAddModal extends Component{
	constructor(props){
		super(props)
	}
	state = {
		thumbnail: null
	}
	handleDrop = (blobArray) => {
		if(blobArray.length > 0)
			this.setState({
				thumbnail: blobArray[0],
			})
	}
	render = () => 
		<div className={'ElectricityAddModal'}>
			<div className={'titleContainer'}>
				<h1>Add Electricity</h1>
			</div>
			<UnitSelector className={'unitSelector'}/>
			<div className={'formInputContainer'}>
				<div className={'inputContainer'}>
					<input placeholder={'KWH id'}/>
				</div>
				<div className={'inputContainer'}>
					<input placeholder={'Number'} type={'text'}/>
				</div>
				<DragAndDrop 
					className={'photoDrop'}
					handleDrop={this.handleDrop}>
					<InputImage src={this.state.thumbnail}/>
				</DragAndDrop>
			</div>
			<div className={'buttonsContainer'}>
					<div 
						className={'buttonContainer'}
						onClick={this.handleSave}>
						<h3>Save</h3>
					</div>
					<div 
						className={'buttonContainer'}
						onClick={this.handleCancel}>
						<h3>Cancel</h3>
					</div>
				</div>
		</div>
}

export default ElectricityAddModal