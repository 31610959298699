import React, { Component } from 'react'
import Table, {ActionButton} from '../../components/table'
import '../../styles/rates.scss'
import Neu from '../../components/neu'
import NeuToggle from '../../components/neuToggle'
import UnitSelector from '../../components/unitSelector'
import Camera from '../../components/camera'

import Pencil from '../../components/svg/pencil'
import Bin from '../../components/svg/bin'
import Plus from '../../components/svg/plus'

import getCameraStream from '../../utility/getCameraStream'
import ModalContext from '../../context/modal'

import '../../styles/electricity.scss'

import ElectricityAddModal from '../../components/modals/electricityAddModal'

const AddElectricityModal = () => <div><h5>SIX BELOW</h5></div>

class Electricity extends Component<{modal?:any},{}>{
	constructor(props:{modal?:any}){
		super(props)
		// this.handleActionClick = this.handleActionClick.bind(this)
		this.invokeAddModal = this.invokeAddModal.bind(this)
	}
	componentDidMount(){

	}
	componentDidUpdate(){
		
	}
	handleActionClick = () => {

	}
	staticActions = [
		<ActionButton
			onClick={this.handleActionClick}
			title={'Add'}/>	
	]
	invokeAddModal = (e:Event) => {
		console.log('Add modal invoked')
		this.props.modal.setModal({
			active: true,
			component: <ElectricityAddModal/>
		})
	}
	render = () => 
		<div className={'Electricity TabPage'}>
			<div className={'meterContainer'}>
				<div className={'tableContainer'}>
					<div className={'tableInnerContainer'}>
						<Table 
							// actions={this.staticActions}
							title={'Event Log'} 
							label={['Unit','KwH','Last Modifier','Status','Actions']}
							data={[]}/>
					</div>
				</div>
			</div>
		</div>
}

export default (props: {modal?: any}) => 
	<ModalContext.Consumer>{
		value => <Electricity {...props} modal={value}/>
	}</ModalContext.Consumer>
	