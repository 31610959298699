import React, { Component } from 'react'
import SidebarContext from '../context/sideBar'

class SidebarState extends Component{
	constructor(props){
		super(props)
		this.setActive = this.setActive.bind(this)
	}
	timeout = false
	state = {
		active: false,
	}
	preventTimeout = () => {
		this.timeout = true
		setTimeout(() => this.timeout=false,200)
	}
	setActive = () => {
		if(!this.timeout){
			this.preventTimeout()
			this.setState({
				active: !this.state.active
			})
		}
	}
	render = () => 
		<SidebarContext.Provider value={{
			active: this.state.active,
			setActive: this.setActive
		}}>
			{this.props.children}
		</SidebarContext.Provider>
}

export default SidebarState