import {GET,POST,PATCH,DELETE} from './method'

export default {
	overview : {
		get : {
			path:  ({propertyId}) => `/colive/${propertyId}/overview`,
			method: GET
		}
	},
	getAll: {
		path: '/colive',
		method: GET
	},
	detail: {
		path: ({propertyId}) => `/colive/${propertyId}`,
		method: GET
	},
	addProperty: {
		path: '/colive',
		method: POST
	},
	update: {
		updateProperty: {
			path: ({propertyId}) => `/colive/${propertyId}`,
			method: PATCH	
		},
		updateThumbnail: {
			path: ({propertyId}) => `/colive/${propertyId}/thumbnail`,
			method: PATCH	
		},
		updatePictures: {
			path: ({propertyId}) => `/colive/${propertyId}/pictures`,
			method: PATCH	
		}
	},
	report : {
		all: {
			path: ({propertyId}) => `/colive/${propertyId}/report`,
			method: GET	
		},
		download: {
			path: ({propertyId}) => `/colive/${propertyId}/report/download`,
			method: GET	
		}
	},
	unit: {
		type: {
			all: {
				path: ({propertyId}) => `/colive/${propertyId}/unit/type/`,
				method: GET
			},
			detail: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/unit/type/${unitTypeId}`,
				method: GET
			},
			add: {
				path: (propertyId) => `/colive/${propertyId}/unit/type`,
				method: POST
			},
			edit: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/unit/type/${unitTypeId}`,
				method: PATCH
			},
			delete: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/unit/tpye/${unitTypeId}`,
				method: DELETE
			},
			disable: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/unit/type/${unitTypeId}/disable`,
				method: POST
			},
		},
		all: {
			path: ({propertyId}) => `/colive/${propertyId}/unit`,
			method: GET
		},
		detail: {
			path: (propertyId,unitId) => `/colive/${propertyId}/unit/${unitId}`,
			method: GET
		},
		add: {
			path: ({propertyId}) => `/colive/${propertyId}/unit`,
			method: POST
		},
		edit: {
			path: ({propertyId,unitId}) => `/colive/${propertyId}/unit/${unitId}`,
			method: PATCH
		},
		delete: {
			path: ({propertyId,unitId}) => `/colive/${propertyId}/unit/${unitId}`,
			method: DELETE
		},
		disable: {
			path: ({propertyId,unitId}) => `/colive/${propertyId}/unit/${unitId}/disable`,
			method: POST
		},	
	},
	rates: {
		all: {
			path: ({propertyId}) => `/colive/${propertyId}/rate`,
			method: GET
		},
		default: {
			all: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/rate/${unitTypeId}/default`,
				method: GET
			},
			detail: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/rate/${unitTypeId}/default`,
				method: GET
			},
			add: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/rate/${unitTypeId}/default`,
				method: POST
			},
			edit: {
				path: ({propertyId,unitTypeId,}) => `/colive/${propertyId}/rate/${unitTypeId}/default`,
				method: PATCH
			},
			delete: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/rate/${unitTypeId}/default`,
				method: DELETE
			},
			disable: {
				path: ({propertyId,unitTypeId,rateId}) => `/colive/${propertyId}/rate/${unitTypeId}/default/disable`,
				method: POST
			}
		},
		static: {
			all: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/rate/${unitTypeId}/static`,
				method: GET
			},
			detail: {
				path: ({propertyId,unitTypeId,rateId}) => `/colive/${propertyId}/rate/${unitTypeId}/static/${rateId}`,
				method: GET
			},
			add: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/rate/${unitTypeId}/static`,
				method: POST
			},
			edit: {
				path: ({propertyId,unitTypeId,rateId}) => `/colive/${propertyId}/rate/${unitTypeId}/static/${rateId}`,
				method: PATCH
			},
			delete: {
				path: ({propertyId,unitTypeId,rateId}) => `/colive/${propertyId}/rate/${unitTypeId}/static/${rateId}`,
				method: DELETE
			},
			disable: {
				path: ({propertyId,unitTypeId,rateId}) => `/colive/${propertyId}/rate/${unitTypeId}/static/${rateId}/disable`,
				method: POST
			}
		},
		dynamic: {
			all: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/rate/${unitTypeId}/dynamic`,
				method: GET
			},
			detail: {
				path: ({propertyId,unitTypeId,rateId}) => `/colive/${propertyId}/rate/${unitTypeId}/dynamic/${rateId}`,
				method: GET
			},
			add: {
				path: ({propertyId,unitTypeId}) => `/colive/${propertyId}/rate/${unitTypeId}/dynamic`,
				method: POST
			},
			edit: {
				path: ({propertyId,unitTypeId,rateId}) => `/colive/${propertyId}/rate/${unitTypeId}/dynamic/${rateId}`,
				method: PATCH
			},
			delete: {
				path: ({propertyId,unitTypeId,rateId}) => `/colive/${propertyId}/rate/${unitTypeId}/dynamic/${rateId}`,
				method: DELETE
			},
			disable: {
				path: ({propertyId,unitTypeId,rateId}) => `/colive/${propertyId}/rate/${unitTypeId}/dynamic/${rateId}/disable`,
				method: POST
			}
		}
	},
	nrates: {
		get: {
			path: ({propertyId,unitTypeId,startDate,endDate}) => `/colive/${propertyId}/rate`,
			method: GET
		},
		update: {
			path: ({propertyId,unitTypeId,}) => `/colive/${propertyId}/rate/${unitTypeId}`,
			method: PATCH
		}
	},
	booking: {
		all: {
			path: ({propertyId}) => `/colive/${propertyId}/booking`,
			method: GET
		},
		detail: {

		},
		add: {
			path: ({propertyId}) => `/colive/${propertyId}/booking/manual`,
			method: POST
		},
		checkOut: {
			path: ({propertyId}) => `/colive/${propertyId}/booking/checkOut`,
			method: POST
		},
		delete: {
			path: ({propertyId, bookingId}) => `/colive/${propertyId}/booking/${bookingId}`,
			method: DELETE
		}
	},
	availability: {
		get: {
			path: ({propertyId}) => `/colive/${propertyId}/availability`,
			method: GET
		},
		assignUnit: {
			path: ({propertyId}) => `/colive/${propertyId}/availability/assign`,
			method: POST
		}
	}
}