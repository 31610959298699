import React, { Component } from 'react'
import Table, { ActionButton } from '../../components/table'
import moment from 'moment'

import API from '../../api'
import getSafe from '../../utility/getSafe'
import formatRupiah from '../../utility/rupiahFormator'

import Neu from '../../components/neu'
import Left from '../../components/svg/left'
import Right from '../../components/svg/right'
import Excel from 'exceljs'
import { saveAs } from 'file-saver';

class Reports extends Component{
	constructor(props){
		super(props)
		this.downloadXls = this.downloadXls.bind(this)
		this.state = {
			month : moment().month(),
			year 	: moment().year(),
			data	: undefined
		}
	}
	prev = () => {
		try{
			let month = this.state.month - 1
			let year = this.state.year
			if(month < 0){
				month = 11
				year -= 1 
			}
			this.setState({ month, year })
		}catch(error){ console.error(error) }
	}
	next = () => {
		try{
			let month = this.state.month + 1
			let year = this.state.year
			if(month > 11){
				month = 0
				year += 1 
			}
			this.setState({ month, year })
		}catch(error){ console.error(error) }
	}
	staticActions = [
		<ActionButton 
			onClick={this.downloadXls}
			title={'Download'}/>
	]
	componentDidMount(){
		if(this.props.property && this.props.property._id) this.loadData()
	}
	componentDidUpdate(prevProps, prevState){
		// if(this.props.property && (this.props.property._id !== undefined || getSafe(() => prevProps.property._id, undefined) !== this.props.property._id))
		// 	this.loadData()
		// else if(this.state.month !== undefined && this.state.year !== undefined)
		// 		if(getSafe(() => prevState.month, undefined) !== this.state.month || getSafe(() => prevState.year, undefined) !== this.state.year || this.state.data === undefined)
		// 			this.loadData()
		if(this.state.reload === true){
			this.loadData()
			this.setState({ reload: false })
			return
		}
		if((this.props.property && prevProps.property !== this.props.property) || (this.state.startDate !== prevState.startDate) || (this.state.month !== prevState.month) || (this.state.year !== prevState.year)) this.loadData()
	}
	// * API
	loadData = async () => {
		try{
			if(this.props.property && this.props.property._id){
				const dateString = `${this.state.year}-${this.state.month + 1}-01`
				// console.log('dateString', dateString)
				const date = moment(dateString,'YYYY-MM-DD')
				const startDate = date.format('YYYY-MM-DD')
				const endDate = date.endOf('month').format('YYYY-MM-DD')
				console.log('startDate', startDate)
				console.log('endDate', endDate)
				const result = await API.colive.report.all({ propertyId: this.props.property._id ,startDate, endDate})
				console.log('Booking load data result', result)
				if(result instanceof Error)	throw result
				if(result && result.data)
					this.setState({
						data: result.data,
						// page: result.page
					})
			}
		}catch(e){ 
			console.error(e)
			this.setState({
				data: []
			})
		}
	}
	downloadXls = async () => {
		try{
			// console.log('Download')
			// if(this.props.property && this.props.property._id){
			// 	const dateString 	= `${this.state.year}-${this.state.month+1}-01`
			// 	const date 				= moment(dateString,'YYYY-MM-DD')
			// 	const startDate 	= date.format('YYYY-MM-DD')
			// 	const endDate 		= date.endOf('month').format('YYYY-MM-DD')
			// 	console.log('startDate', startDate)
			// 	console.log('endDate', endDate)
			// 	const result = await API.colive.report.download({ propertyId: this.props.property._id, startDate, endDate }, true)
			// 	if(result instanceof Error)	throw result
			// }
			if(this.props.property && this.props.property._id && this.state.data !== undefined){
				const workbook = new Excel.Workbook()
				workbook.creator = 'Me'
				workbook.lastModifiedBy = 'Her'
				workbook.created = new Date()
				workbook.modified = new Date()
				workbook.lastPrinted = new Date()
				const worksheet = workbook.addWorksheet('Report')
				let rowIndex = 1
				let row = worksheet.getRow(rowIndex)
				let cell = row.getCell(1)
				// cell.value = moment(selectedDate,'YYYY-MM-DD').format('dddd, DD-MMM-YYYY')
				rowIndex++

				const keys = ['booking date', 'booking time', 'check in', 'check out', 'name', 'unit', 'count', 'unit types', 'ota', 'ota booking id', 'price']
				const xlsData = this.parseBookingData().map((dataObject) => {
					const buffer = []
					keys.forEach(key => {
						const value = dataObject[key]
						if (key === 'unit') buffer.push(value instanceof Array ? value.join(', ') : '')
						else buffer.push(dataObject[key])
					})
					return buffer
				});

				(['Booking Date', 'Booking Time', 'Check in Date', 'Check out Date', 'Name', 'Unit', 'Count', 'Unit Type', 'OTA', 'OTA Booking Id', 'Price']).forEach((myLabel, index) => {
					row = worksheet.getRow(rowIndex)
					cell = row.getCell(index + 1)
					cell.value = myLabel
				})
				rowIndex++

				// const {
				// 	totalRoom,
				// 	totalDailyBooked,
				// 	totalDailyRevenue,
				// 	totalMonthlyBooked,
				// 	totalMonthlyRevenue,
				// 	totalFree,
				// 	totalRenov,
				// 	totalEmpty,
				// 	totalBooked,
				// 	averageRoomRate,
				// 	averageOccRate,
				// 	totalRevenue
				// } = overviewData.total
				
				xlsData.forEach((rowData) => {
					rowData.forEach((value, index) => {
						row = worksheet.getRow(rowIndex)
						cell = row.getCell(index + 1)
						cell.value = value
					})
					rowIndex++
				})

				// write to a new buffer
				workbook.xlsx.writeBuffer().then((data) => {
					var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
					saveAs(blob, "pms.xlsx")
				});
			}
		}catch(error){ console.error(error) }
	}
	getUnitCount = (bookingObject) => {
		// let total = 0
		let total = bookingObject?.roomRequests.map(({count}) => count).reduce((acc, a) => acc + a, 0)
		
		return total
	}
	parseBookingData = () => {
		try{			
			// console.log('PROPS test', this.props.property)
			return this.state.data instanceof Array ?  this.state.data.map((bookingObject) => {
				// console.log(bookingObject)
				let checkInDate
				let checkOutDate
				if(!checkInDate && bookingObject && bookingObject.roomRequests) 	checkInDate 	= moment(bookingObject.roomRequests[0].checkInDate).format('YYYY-MM-DD')
				if(!checkOutDate && bookingObject && bookingObject.roomRequests) 	checkOutDate 	= moment(bookingObject.roomRequests[0].checkOutDate).format('YYYY-MM-DD')
				const roomRequests = bookingObject.roomRequests
				console.log(bookingObject)
				return ({
					'booking date'		: moment(bookingObject.bookingDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
					'booking time'		: moment(bookingObject.createdAt).format('HH:mm'),
					'check in' 				: checkInDate,
					'check out' 			: checkOutDate,
					'name'						: bookingObject.customer.name,
					'unit'						: !this.isAssigned(roomRequests) ? '' : this.parseUnit(roomRequests),
					'count'						: this.getUnitCount(bookingObject),
					'unit types'			: roomRequests.map(({unitTypeId}) 	=> this.props.property.unitTypes.find(({_id}) => _id == unitTypeId).name).toString(),
					'ota'							: bookingObject.ota,
					'ota booking id'	: bookingObject.channelRef,
					'price'						: bookingObject.price
				})
			}) : null
		}catch(error){ console.error(error) }
	}
	isAssigned = (array) => {
		try{
			array.map(({unitsAssigned}) => {
				if(!unitsAssigned)
					throw ''
			})
			return true
		}catch(error){
			return false
		}

	}
	parseUnit = (array) => {
		try{
			let buff = []
			array.map(({units}) => {
				units.map((unitId) => {
					console.log('unitIdX', unitId)
					const index = this.props.property.units.findIndex(({_id}) => _id == unitId)
					console.log('units', this.props.property.units)
					if(index == -1)
						console.error('Unit not found')
					else{
						buff.push(this.props.property.units[index].name)
					}
				})
			})
			console.log('buff', buff)
			return buff
		}catch(error){
			console.error(error)
			return []
		}
	}
	render = () => 
		this.props.property ? 
		<div className={'TabPage Reports'}>
			<div style={{width: '100%', overflow: 'auto'}}>
			<div style={{ marginTop: 16, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
				<Neu
					onClick={this.prev}
					style={{ width: 24, height: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#2c2c2c', marginLeft: 12}}
					state={false}>
					<Left style={{height: '50%', width: '50%', fill: '#FFF'}}/>
				</Neu>
				<h2 style={{marginLeft: 12, marginRight: 12}}>{`${moment().month(this.state.month).format('MMMM')} ${this.state.year}`}</h2>
				<Neu 
					onClick={this.next}
					style={{ width: 24, height: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#2c2c2c'}}
					state={false}>
					<Right style={{height: '50%', width: '50%', fill: '#FFF'}}/>
				</Neu>
			</div>
			<div className={'tableContainer'} style={{overflow: 'visible'}}>
				<div className={'tableInnerContainer'}>
				<Table 
					actions={[
						<ActionButton 
							onClick={this.downloadXls}
							title={'Download'}/> 
						]}
					numbering={false}
					title={'Reports'} 
					// label={['Booking Id','Guest Name', 'Date', 'Unit Types', 'Unit', 'Due Date','Invoice Mail','Status','Actions']}
					label={['Booking Date', 'Booking Time','Check In','Check Out','Name', 'Unit', 'Count', 'Unit Types',  'OTA', 'OTA Booking Id', 'Price']}
					data={this.parseBookingData()}/>
				</div>
			</div>
			</div>
		</div> : null
}

export default Reports
{/* <a href={getFile.url}
download={getFile.saveAsFileName}>
</a> */}