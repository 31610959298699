import React, { Component } from 'react'
import moment from 'moment'
import SliderTable from '../../components/sliderTable.jsx'
import RateTable from '../../components/rateTable'
import Neu from '../../components/neu'
// * CONTEXT import
import ModalContext from '../../context/modal'

// * API import
import API from '../../api'
import RatesModal from '../../components/modals/ratesModal.jsx'
import NetworkInfoModal from '../../components/modals/networkInfoModal.js'
import getSafe from '../../utility/getSafe.js'

class Rates extends Component{
	constructor(props){
		super(props)
		this.handleCancel = this.handleCancel.bind(this)
		this.handleSave 	= this.handleSave.bind(this)
		this.handleNav		= this.handleNav.bind(this)
		this.state = {
			daily				: {
				startDate	: moment().startOf('month').format('YYYY-MM-DD'),
				endDate		: moment().endOf('month').format('YYYY-MM-DD'),
				data			: undefined,
			},
			monthly			: {
				startDate	: moment().startOf('year').format('YYYY-MM-DD'),
				endDate		: moment().endOf('year').format('YYYY-MM-DD'),
				data			: undefined,
			}
		}
	}
	componentDidMount = () => {
		if(this.props.property) this.loadData()
	}
	componentDidUpdate = (prevProps, prevState) => {
		let changeOfPropertyId 	= false
		let changeOfDaily 			= false
		let changeOfMonthly 		= false
		if(this.props.property && this.props.property._id && this.props.property._id !== getSafe(() => prevProps.property._id, undefined)) 																								changeOfPropertyId 	= true
		// if((this.state.daily && this.state.daily.data === undefined) || (this.state.monthly && this.state.monthly.data === undefined))																										changeOfPropertyId	= true
		if(this.state.daily.startDate 	!== getSafe(() => prevState.daily.startDate, undefined) 	|| this.state.daily.endDate 	!== getSafe(() => prevState.daily.endDate, undefined))		changeOfDaily 			= true
		if(this.state.monthly.startDate !== getSafe(() => prevState.monthly.startDate, undefined) || this.state.monthly.endDate !== getSafe(() => prevState.monthly.endDate, undefined))	changeOfMonthly 		= true
		if(changeOfPropertyId) this.loadData()
		if(changeOfDaily || changeOfMonthly) this.loadData(changeOfDaily, changeOfMonthly)
	}
	loadData = async (daily = true ,monthly = true) => {
		try{
			if(this.props.property && this.props.property._id){
				const { _id: propertyId } = this.props.property
				let resultDaily
				let resultMonthly
				if(daily)		resultDaily 	= API.colive.nrates.get({propertyId, startDate: this.state.daily.startDate, endDate: this.state.daily.endDate})
				if(monthly)	resultMonthly = API.colive.nrates.get({propertyId, monthly: true})
				if(daily 		&& resultDaily 		instanceof Promise) resultDaily 	= await resultDaily
				if(monthly 	&& resultMonthly 	instanceof Promise) resultMonthly = await resultMonthly
				if(resultDaily 		instanceof Error)	throw resultDaily
				if(resultMonthly 	instanceof Error)	throw resultMonthly

				// if(daily)		console.log('resultDaily'		, resultDaily)
				// if(monthly)	console.log('resultMonthly'	, resultMonthly)

				// const {data,startDate,endDate} = resultDaily
				// const {data: dataMonthly,startDate: startDateMonthly, endDate: endDateMonthly} = resultMonthly
				this.setState({
					daily		: daily 	? {
						...this.state.daily,
						data			: daily 	? resultDaily.data 		: this.state.daily.data
					} : this.state.daily,
					monthly	: monthly ? {
						...this.state.monthly,
						data			: monthly ? resultMonthly.data 	: this.state.monthly.data
					}: this.state.monthly
				})
			}
		}catch(e){ console.error(e) }
	}
	handleNav = (term = 'DAILY', next = true) => {
		try{
			let momentStartDaily
			let momentStartMonthly
			if(term === 'DAILY'){
				if(next)
					momentStartDaily = moment(this.state.daily.startDate).add(1, 'month')
				else
					momentStartDaily = moment(this.state.daily.startDate).subtract(1, 'month')
			}else if(term === 'MONTHLY'){
				if(next)
					momentStartMonthly = moment(this.state.monthly.startDate).add(1, 'year')
				else
					momentStartMonthly = moment(this.state.monthly.startDate).subtract(1, 'year')
			}else throw new Error('Inconclusive operation')

			this.setState({
				daily				: term !== 'DAILY' ? this.state.daily : {
					startDate	: momentStartDaily.startOf('month').format('YYYY-MM-DD'),
					endDate		:	momentStartDaily.endOf('month').format('YYYY-MM-DD'),
					data			: this.state.daily.data
				},
				monthly			: term !== 'MONTHLY' ? this.state.monthly : {
					startDate	: momentStartMonthly.startOf('year').format('YYYY-MM-DD'),
					endDate		: momentStartMonthly.endOf('year').format('YYYY-MM-DD'),
					data			: this.state.monthly.data
				}
			}, () => console.log('Updated state', this.state))
		}catch(error){ console.error(error) }
	}
	handleUpdateClick = () => {
		this.props.modal.setModal({
			active: true,
			component: 
				<RatesModal 
					propertyId={this.props.property._id}
					cancel={this.handleCancel}
					save={this.handleSave}
					unitTypes={this.state.daily.data.map(({unitTypeId:_id,name}) => ({_id,name}))}/>
		})
	}
	handleSave = async ({propertyId,unitTypeId,startDate,endDate,days,price,monthly}) => {
		try{
			this.setState({loading: true})
			this.props.modal.setModal({
				active: true,
				component: 
					<NetworkInfoModal 
						loading={true}/>
			})
			const result = await API.colive.nrates.update({
				propertyId,
				unitTypeId,
				startDate,
				endDate, 
				days,
				price,
				monthly
			})
			console.log('UPDATE RESULT', result)
			if(result instanceof Error){
				console.log(result.response)
				this.setState({loading: false})
				this.props.modal.setModal({
					active: false,
					component: 
						<NetworkInfoModal 
							loading={true} 
							closeModal={this.closeModal}
							error={true}
							errorResponse={result.response}/>
				})
			}
				
			this.setState({loading: false})
			this.props.modal.setModal({
				active: false,
				component: null
			})
			console.log(result)
		}catch(e){ console.error(e) }
	}
	handleCancel = () => this.props.modal.setModal({
		active: false,
		component: null
	})
	render = () => 
		this.props.property ? 
		<div className={'TabPage Rate'} style={{overflowX: 'hidden'}}>  
			<Neu
				onClick={this.handleUpdateClick}
				style={{
					marginLeft: 16,
					marginTop: 8,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: 'min-content',
					padding: '0px 16px',
				}}
				state={false}>
				<h5>Update</h5>
			</Neu>
			<div style={{width: '100%', maxWidth: '100%'}}>
				<RateTable 
					startDate={this.state.daily && this.state.daily.startDate ? this.state.daily.startDate : undefined} 
					endDate={this.state.daily && this.state.daily.endDate ? this.state.daily.endDate : undefined} 
					data={this.state.daily && this.state.daily.data ? this.state.daily.data : undefined} 
					term={'DAILY'}
					prev={this.handleNav}
					next={this.handleNav}/>
			</div>
			<div style={{ width: '100%',maxWidth: '100%', marginLeft: 16 }}>
				<div 
					style={{

					}}>
					<h3>Start from :</h3> 
					{/* <input type={number}/> */}
				</div>
				<div
					style={{

					}}>
					<h3>End at :</h3>
				</div>
			</div>
		</div> : null
}

export default (props) => 
	<ModalContext.Consumer>
	{value => <Rates modal={value} {...props}/>}
	</ModalContext.Consumer>
