import React, { Component } from 'react'
import ModalContext from '../context/modal'
// import Neu from '../components/neu'

class Modal extends Component{
	constructor(props){
		super(props)
		this.setModal = this.setModal.bind(this)
	}
	state = {
		active: false,
		component: null,
	}
	setModal = ({active,component} = {}) => {
		// if(!active instanceof Boolean)
		// 	throw new Error('Active is not a boolean')
		// if(!component instanceof Component)
		// 	throw new Error('Component is not a valid react component')
		this.setState({
			active: active,
			component: component
		})
	}
	render = () =>
		<ModalContext.Provider value={{
			setModal: this.setModal,
			active: this.state.active,
			component: this.state.component
		}}>
			{this.props.children}
		</ModalContext.Provider>
}

export default Modal
	