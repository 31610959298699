import {GET,POST,PATCH,DELETE} from './method'

export default {
	addCowork								: { path: `/cowork`																																												, method: POST 		},
	getAllCowork						: { path: '/cowork'																																												, method: GET 		},
	getCoworkDetail					: { path: ({ propertyId 									}) => `/cowork/${propertyId}`																		, method: GET 		},
	editCowork							: { path:	({ propertyId 									}) => `/cowork/${propertyId}`																		, method: PATCH, 	},
	deleteCowork						: { path: ({ propertyId 									}) => `/cowork/${propertyId}`																		, method: DELETE 	},
	addCoworkUnit						: { path: ({ propertyId 									}) => `/cowork/${propertyId}/unit`															, method: POST 		},
	deleteCoworkUnit 				: { path: ({ propertyId, unitId 					}) => `/cowork/${propertyId}/unit/${unitId}`										, method: DELETE 	},
	getAllCoworkUnit				: { path: ({ propertyId 									}) => `/cowork/${propertyId}/unit/`															, method: GET 		},
	getCoworkUnitDetail 		: { path: ({ propertyId, unitId 					}) => `/cowork/${propertyId}/unit/${unitId}`										, method: GET 		},
	editCoworkUnitDetail		: { path: ({ propertyId, unitId 					}) => `/cowork/${propertyId}/unit/${unitId}`										, method: PATCH 	},
	addCoworkUnitType				: { path: ({ propertyId 									}) => `/cowork/${propertyId}/unitType/`													, method: POST, 	},
	deleteCoworkUnitType		: { path: ({ propertyId, unitTypeId 			}) => `/cowork/${propertyId}/unitType/${unitTypeId}`						, method: DELETE, },
	editCoworkUnitType			: { path: ({ propertyId, unitTypeId 			}) => `/cowork/${propertyId}/unitType/${unitTypeId}`						, method: PATCH 	},
	getAllCoworkUnitType		: { path: ({ propertyId, 						 			}) => `/cowork/${propertyId}/unitType/`													, method: GET 		},
	getCoworkUnitTypeDetail	: { path: ({ propertyId, unitTypeId 			}) => `/cowork/${propertyId}/unit/${unitTypeId}`								, method: GET 		},
	addCoworkRate						: { path: ({ propertyId, unitTypeId, term	}) => `/cowork/${propertyId}/rates/${unitTypeId}?term=${term}`	, method: POST 		},
	deleteCoworkRate				: { path: ({ propertyId, unitTypeId, term	}) => `/cowork/${propertyId}/rates/${unitTypeId}?term=${term}`	, method: DELETE 	},
	editCoworkRate					: { path: ({ propertyId, unitTypeId, term	}) => `/cowork/${propertyId}/rates/${unitTypeId}?term=${term}`	, method: PATCH, 	},
	getAllCoworkRate				: { path: ({ propertyId, unitTypeId, term	}) => `/cowork/${propertyId}/rates/${unitTypeId}?term=${term}`	, method: GET 		},
	getCoworkRateDetail			: { path: ({ propertyId, unitTypeId, term	}) => `/cowork/${propertyId}/rates/${unitTypeId}?term=${term}`	, method: GET 		},
}