import React, { Component } from 'react'

import UnitSelector from '../../components/unitSelector'

import ModalContext from '../../context/modal'
import UnitAssignmentSelector from '../UnitAssignSelector'
import { runInThisContext } from 'vm'


class AddBookingModal extends Component{
	constructor(props){
		super(props)
	}
	state = {
		selectedTermIndex			: -1,
		selectedUnitTypeIndex	: -1,
		selectedUnits					: [],
		unitTypes							: this.props?.property?.unitTypes?.map(({name, _id}) => ({ name: name, _id})),
		activeUnits						: this.props?.property?.units?.map(({name, _id}) => ({
			_id,
			name: name
		}))
	}
	componentDidMount = () => {
		console.log(this.props.property)
		console.log(this.unitTypes)
	}
	closeModal = () => {
		this.props.modal.setModal({
			active		: false,
			component	: null
		})
	}
	handleTermChange = (value) => {
		try{
			this.setState({ selectedTermIndex: value })
		}catch(error){ console.error(error) }
	}
	handleUnitTypeChange = (value) => {
		try{
			if(value !== this.state.selectedUnitTypeIndex){
				const activeUnits = this.props?.property?.units?.filter(({ name, unitType }) =>{
					if(unitType == this.state.unitTypes[value]._id) return true
					return false
				})
				this.setState({
					selectedUnitTypeIndex	: value,
					activeUnits						: activeUnits,
					selectedUnits					: [],					
				})
			}
		}catch(error){ console.error(error) }
	}
	onChangeUnitSelect = (event, index, selected) => {
		try{
			this.setState({ selectedUnits: selected })
		}catch(error){ console.error(error) }
	}
	handleNameChange = (event) => {
		try{
			this.setState({ name: event.target.value })
		}catch(error){ console.error(error) }
	}
	handleEmailChange = (event) => {
		try{
			this.setState({ email: event.target.value })
		}catch(error){ console.error(error) }
	}
	handlePhoneChange = (event) => {
		try{
			this.setState({ phone: event.target.value })
		}catch(error){ console.error(error) }
	}
	handlePriceChange = (event) => {
		try{
			this.setState({ price: event.target.value })
		}catch(error){ console.error(error) }
	}
	handleInsuranceChange = (event) => {
		try{
			this.setState({ insurance: event.target.value })
		}catch(error){ console.error(error) }
	}
	prepareData = () => {
		try{
			console.log(this.state)
			const data = { 
				name					: this.state.name,
				email					: this.state.email,
				phone					: this.state.phone,
				price					: this.state.price,
				insurance			: this.state.insurance,
				term					: this.state.selectedTermIndex == 1 ? "MONTHLY" : "DAILY" , 
				selectedUnits : this.state.selectedUnits.map((value) => this.state.activeUnits[value]), 
				unitType			: this.state.unitTypes[this.state.selectedUnitTypeIndex], 
				checkInDate		: this.state.checkInDate, 
				checkOutDate	: this.state.checkOutDate,
				ota						: this.state.ota,
				otaBookingId	: this.state.otaBookingId
			}
			this.props.submit(data)
		}catch(error){ console.error(error) }
	}
	handleCheckInChange = (event) => {
		try{
			this.setState({ checkInDate: event.target.value })
		}catch(error){ console.error(error) }
	}
	handleCheckOutChange = (event) => {
		try{
			this.setState({ checkOutDate: event.target.value })
		}catch(error){ console.error(error) }
	}
	handleOTAChange = (event) => {
		try{
			this.setState({ ota: event.target.value })
		}catch(error){ console.error(error) }
	}
	handleOTABookingIdChange = (event) => {
		try{
			this.setState({ otaBookingId: event.target.value })
		}catch(error){ console.error(error) }
	}
	render = () => 
		<div 
			className={'main_bg dropShadow modalPadding radius'} 
			style={{
				width						: 'min-content',
				maxWidth				: 'calc(90% - 48px)',
				backgroundColor	: '#e0e5ec',
				padding					: 24, 
				borderRadius		: 5,
				maxHeight				: '80vh',
				overflowY				: 'auto'
			}}>
			<div style={{ marginBottom: 8 }}>
				<h1>Add Booking</h1>
			</div>
			<div style={{width: '100%'}}>
				<UnitSelector 
					selected={this.state.selectedTermIndex}
					placeholder={'Choose Term'}
					onChange={this.handleTermChange}
					data={[{ name: 'Daily' },{ name: 'Monthly' }]}
					style={{ marginLeft: 0, maxWidth: '100%', backgroundColor: '#E0E5EC' }}/>
			</div>
			<div style={{ width: '100%', marginTop: 16 }}>
				<UnitSelector 
					placeholder={'Choose Unit Type'}
					onChange={this.handleUnitTypeChange}
					data={this.state.unitTypes}
					selected={this.state.selectedUnitTypeIndex}
					style={{ marginLeft: 0, maxWidth: '100%', backgroundColor: '#E0E5EC' }}/>
			</div>
			<div style={{ width: '100%', marginTop: 16, maxWidth: '512px' }}>
				<UnitAssignmentSelector 
					index={0}
					data={this.state.activeUnits}
					// unitTypeId={this.unitTypes[0]._id}
					selected={this.state.selectedUnits}
					onChangeSelection={this.onChangeUnitSelect}/>
			</div>
			{/* <div 
				style={{
					display				: 'flex', 
					flexDirection	: 'row', 
					flexWrap			: 'wrap',
					marginTop			: 16,
					marginBottom	: 4,
					width					: '100%'
				}}> */}
				<div style={{marginRight: 16, flex : 1, marginBottom: 4, marginTop: 4}}>
					<h4>Check In</h4>
					<input type={'date'} style={{padding: '8px', borderRadius: 5, border: 0, marginTop: 4, marginBottom: 4, width: '100%'}} onChange={this.handleCheckInChange} value={this.state.checkInDate}/>
				</div>
				{/* <div style={{width: 16}}></div> */}
				<div style={{marginRight: 16, flex : 1}}>
					<h4>Check Out</h4>
					<input type={'date'} style={{padding: '8px', borderRadius: 5, border: 0, marginTop: 4, marginBottom: 4, width: '100%'}} onChange={this.handleCheckOutChange} value={this.state.checkOutDate}/>
				</div>
			{/* </div> */}
			<div style={{flex : 1, marginBottom: 8 , marginRight: 16}}>
				<h4>Name(*)</h4>
				<input type={'text'} style={{padding: '8px', borderRadius: 5, border: 0, marginTop: 4, marginBottom: 4, width: '100%'}} onChange={this.handleNameChange} value={this.state.name}/>
			</div>
			<div style={{flex : 1, marginBottom: 16 , marginRight: 16}}>
				<h4>Email</h4>
				<input type={'email'} style={{padding: '8px', borderRadius: 5, border: 0, marginTop: 4, marginBottom: 4, width: '100%'}} onChange={this.handleEmailChange} value={this.state.email}/>
			</div>
			<div style={{flex : 1, marginBottom: 16 , marginRight: 16}}>
				<h4>Phone</h4>
				<input type={'phone'} style={{padding: '8px', borderRadius: 5, border: 0, marginTop: 4, marginBottom: 4, width: '100%'}} onChange={this.handlePhoneChange} value={this.state.phone}/>
			</div>
			<div style={{flex : 1, marginBottom: 8 , marginRight: 16}}>
				<h4>Price(*)</h4>
				<input type={'number'} style={{padding: '8px', borderRadius: 5, border: 0, marginTop: 4, marginBottom: 4, width: '100%'}} onChange={this.handlePriceChange} value={this.state.price}/>
			</div>
			<div style={{flex : 1, marginBottom: 16 , marginRight: 16}}>
				<h4>OTA</h4>
				<input type={'text'} style={{padding: '8px', borderRadius: 5, border: 0, marginTop: 4, marginBottom: 4, width: '100%'}} onChange={this.handleOTAChange} value={this.state.ota}/>
			</div>
			<div style={{flex : 1, marginBottom: 16 , marginRight: 16}}>
				<h4>OTA Booking Id</h4>
				<input type={'text'} style={{padding: '8px', borderRadius: 5, border: 0, marginTop: 4, marginBottom: 4, width: '100%'}} onChange={this.handleOTABookingIdChange} value={this.state.otaBookingId}/>
			</div>
			<div style={{flex : 1, marginBottom: 16 , marginRight: 16}}>
				<h4>Insurance(Monthly*)</h4>
				<input type={'number'} style={{padding: '8px', borderRadius: 5, border: 0, marginTop: 4, marginBottom: 4, width: '100%'}} onChange={this.handleInsuranceChange} value={this.state.insurance}/>
			</div>
			<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
				<div className={'Neu'}
					onClick={this.prepareData}
					style={{
						width						: 'min-content',
						display					: 'flex',
						justifyContent	: 'center',
						alignItems			: 'center',
						padding					: '0px 16px',
						backgroundColor	: '#2c2c2c'
					}}>
					<h5 style={{whiteSpace: 'nowrap', color: 'white'}}>Add Booking</h5>	
				</div>
				<div style={{width: 16}}/>
				<div 
					className={'Neu'}
					onClick={this.closeModal}
					style={{
						width						: 'min-content',
						display					: 'flex',
						justifyContent	: 'center',
						alignItems			: 'center',
						padding					: '0px 16px'
					}}>
					<h5>Cancel</h5>
				</div>
			</div>
		</div>
}

AddBookingModal.defaultProps = {
	submit				: () => {},
	propertyId		: null,
	units					: [],
	selectedUnit	: -1
}

export default (props) => 
	<ModalContext>{
		(value) => <AddBookingModal modal={value} {...props}/>
	}</ModalContext>