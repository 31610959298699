import React, { Component } from 'react'
// import Neu from '../components/neu'
import '../styles/login.scss'
import logo from '../assets/images/dsLogo.svg'
import Neu from '../components/neu'

import Key from '../components/svg/key'
import Email from '../components/svg/email'
import Gear from '../components/svg/gear'
import profile from '../assets/images/profile.svg'

import API from '../api'

import AuthContext from '../context/auth'
import ModalContext from '../context/modal'
import {Redirect} from 'react-router-dom'

import Authenticator from '../utility/Authenticator'
import NetworkInfoModal from '../components/modals/networkInfoModal'

import firebase from "firebase/app";
import "firebase/auth";

import {firebaseConfig} from '../firebaseConfig'

class Login extends Component{
	constructor(props){
		super(props)
		this.closeModal = this.closeModal.bind(this)
	}
	state={
		emailInput: '',
		passwordInput: '',
		emailLoading: false,
		googleLoading: false,
		loading: false,
		redirect: false
	}
	// componentDidMount = () => console.log(this.props.modal)
	closeModal = () => this.props.modal.setModal({active: false, component: null})
	login = async (firebaseBool = false) => {
		try{
			if(this.state.loading)
				return
			let profile
			if(firebaseBool){
				try{
					if (firebase.apps.length === 0)	firebase.initializeApp(firebaseConfig)
					const provider 	= new firebase.auth.GoogleAuthProvider()
					const result 		= await firebase.auth().signInWithPopup(provider)
					profile 				= await firebase.auth().currentUser.getIdToken(true)
				}catch(error){ console.error(error) }
			}
			this.setState({loading: true})
			this.props.modal.setModal({active: true, component: <NetworkInfoModal loading={true}/>})
			const result = await API.user.login(!firebaseBool ? {email: this.state.emailInput, password: this.state.passwordInput} : { firebaseToken: profile })
			console.log('RESULT LOGIN', result)
			this.setState({loading: false})
			if(result instanceof Error){
				this.props.modal.setModal({active: true, component: 
					<NetworkInfoModal 
						loading={false} 
						closeModal={this.closeModal}
						error={true}
						errorResponse={result.response}/>
				})
				throw result
			}else
				this.closeModal()
				// this.props.modal.setModal({active: false, component: 
				// 	<NetworkInfoModal 
				// 		loading={false} 
				// 		closeModal={this.closeModal}
				// 		error={false}
				// 		errorDetail={{}}/>
				// })
			
			const {data} = result
			if(!data)
				throw new Error('Data property not found')
			// const {data: realData} = data
			// if(!realData)
			// 	throw new Error('real data')
			Authenticator.writeCookie(data)
			this.setState({
				loading		: false,
				redirect	: true
			})
		}catch(e){
			console.error(e)
			console.log(e.response)
			// console.log(e.message)
		}
	}
	handleKeyDown = async (e) => {
		try{
			if(e.key === 'Enter')
				this.login()
		}catch(e){
			console.error(e)
		}
	}
	handleHandleInput = async (e,inputVar) => {
		try{
			if (e.key === 'Enter') 
				this.login()
			const string = e.target.value
			this.setState(inputVar === 'password' ? {
				passwordInput: string
			} : {
				emailInput: string
			})
		}catch(e){
			console.error(e)
		}
	}
	render = () => 
		this.state.redirect ? <Redirect to={{
			pathname: "/",
			// state: { from: location }
		}}/> :
		<div className={'Login'}>
			{/* Modal */}
			<div className={'loginModal'} style={{
				height: 'min-content',
				paddingBottom: 32
			}}>
				{/* Title */}
				<div className={'titleContainer'}>
					<div className={'logoContainer'}>
						<img src={logo} alt={'DS'}/>
					</div>
					<div className={'titleHeadingContainer'}>
						<h1>DS PMS</h1>
					</div>
				</div>
				{/* Input email */}
				<div className={'inputContainer'}>
					<div className={'iconContainer'}>
						<Email/>
					</div>
					<input 
						onKeyDown={this.handleKeyDown}
						onChange={(e) => this.handleHandleInput(e,'email')}
						type={'email'}
						placeholder={'Email'}
						required/>
				</div>
				{/* Input password */}
				<div className={'inputContainer'}> 
					<div className={'iconContainer'}>
						<Key/>
					</div>
					<input 
						onKeyDown={this.handleKeyDown}
						onChange={(e) => this.handleHandleInput(e,'password')}
						placeholder={'Password'}
						type={'password'}
						minLength={8}
						required/>
				</div>
				{/* Login buttons container */}
				<div className={'buttonsContainer'}>
					<Neu 
						state={true}
						onClick={() => this.login(true)}
						className={'button'}>
						<div className={'iconContainer'}>
							<img src={'https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'}/>
						</div>
						{/* <div className={`loadingStateContainer ${this.state.googleLoading ? 'active': ''}`}>
							<Gear/>
						</div> */}
						<label>Google</label>
					</Neu>
					<Neu 
						state={false}
						onClick={() => this.login()}
						className={'button'}>
						<div className={'iconContainer'}>
							<img src={profile}/>
						</div>
						<label>Login</label>
						{/* <div className={`loadingStateContainer ${this.state.emailLoading ? 'active': ''}`}>
							<Gear/>
						</div> */}
					</Neu>
				</div>
			</div>
		</div>
}

export default (props) => 
	<ModalContext.Consumer>{ modal =>
	<AuthContext.Consumer>{ auth => 
		<Login modal ={modal} auth={auth} {...props}/>
	}</AuthContext.Consumer>
	}</ModalContext.Consumer>
	