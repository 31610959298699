import React, { Component } from 'react'

import API from '../../api'
import Neu from '../../components/neu'

import Table from '../../components/table'
import { object } from 'prop-types'

import ModalContext from '../../context/modal'
import AddBookingModal from '../../components/modals/addBookingModal'
import Selector from '../../components/Selector.tsx'
import moment from 'moment'
import Left 	from '../../components/svg/left'
import Right 	from '../../components/svg/right'
import getSafe from '../../utility/getSafe'
import ContextMenuContext from '../../context/contextMenuContext'
import {ContextMenu, ContextMenuI} from '../../components/contextMenuLayer'

import AssignUnitModal from '../../components/modals/assignUnitModal'
import DeleteBookingModal from '../../components/modals/deleteBookingModal.jsx'

import CheckOutModal from '../../components/modals/checkOutModal'
import NetworkInfoModal from '../../components/modals/networkInfoModal'
import EditBookingModal from '../../components/modals/editBookingModal'
import { cancelBooking, markAsFree, markAsForcedClose } from '../../v2'


// Unassigned placeholder component
const Unassigned = () => 
	<Neu style={{backgroundColor: 'rgb(237, 50, 55)', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'min-content', height: 24, padding: '0 12px'}} state={false}>
		<h4 style={{color: 'white'}}><b>Unassigned</b></h4>
	</Neu>

class Booking extends Component{
	constructor(props){
		super(props)
		this.handleAddBookingClick 	= this.handleAddBookingClick.bind(this)
		this.handleItemClick 				= this.handleItemClick.bind(this)
		this.closeModal							= this.closeModal.bind(this)
		this.confirmUnitAssign 			= this.confirmUnitAssign.bind(this)
		this.assignUnit							= this.assignUnit.bind(this)
		this.state = {
			month : moment().month(),
			year 	: moment().year(),
			data	: undefined
		}
	}
	// * LIFECYCLE
	componentDidMount = () => {
		if(this.props.property && this.props.property._id) this.loadData()
	}
	componentDidUpdate(prevProps,prevState){
		if(this.state.reload === true){
			this.loadData()
			this.setState({
				reload: false
			})
			return
		}
		// if(this.props.property && (this.props.property._id !== undefined || getSafe(() => prevProps.property._id, undefined) !== this.props.property._id))
		// 	this.loadData()
		// else if(this.state.month !== undefined && this.state.year !== undefined)
		// 		if(getSafe(() => prevState.month, undefined) !== this.state.month || getSafe(() => prevState.year, undefined) !== this.state.year || this.state.data === undefined)
		// 			this.loadData()
		if((this.props.property && prevProps.property !== this.props.property) || (this.state.startDate !== prevState.startDate) || (this.state.month !== prevState.month) || (this.state.year !== prevState.year)) this.loadData()
	}
	// * API
	loadData = async () => {
		try{
			// console.log('load data')
			// console.log(this.state)
			if(this.props.property && this.props.property._id){
				const dateString = `${this.state.year}-${(this.state.month+1)<10 ? '0': ''}${this.state.month + 1}-01`
				const date = moment(dateString,'YYYY-MM-DD')
				const startDate = date.format('YYYY-MM-DD')
				const endDate = date.endOf('month').format('YYYY-MM-DD')
				const result = await API.colive.booking.all({ propertyId: this.props.property._id ,startDate, endDate})
				// console.log('Booking load data result', result)
				if(result instanceof Error)	throw result
				if(result && result.data)
					this.setState({
						data: result.data,
						// page: result.page
					})
			}
		}catch(e){ 
			console.error(e)
			this.setState({
				data: []
			})
		}
	}
	handleAddBookingClick = () => {
		this.props.modal.setModal({
			active		: true,
			component : <AddBookingModal property={this.props.property} submit={this.addBooking}/>
		})
	}
	addBooking = async ({name,email,phone,price,insurance,term, selectedUnits, unitType, checkInDate, checkOutDate, ota, otaBookingId}) => {
		try{
			const customer = {
				name,
				email,
				phone
			}
			const roomRequests = [{
				unitTypeId: unitType._id,
				count: selectedUnits.length
			}]
			const allocate = selectedUnits.map(({_id}) => {
				return ({
					unitId: _id,
					typeId: unitType._id
				})
			})
			// console.log('unitType', unitType)
			// console.log('selectedUnits', selectedUnits)
			// console.log(roomRequests)
			this.props.modal.setModal({
				active: false,
				component: null
			})
			const result = await API.colive.booking.add({ propertyId: this.props.property._id, checkInDate, checkOutDate, customer, roomRequests, term, allocate, insurance, price, ota, otaBookingId }) 
			this.setState({ reload: true })
			// console.log('Add Booking result', result)
		}catch(error){ console.error(error) }
	}
	isAssigned = (array) => {
		try{
			array.map(({unitsAssigned}) => {
				if(!unitsAssigned)
					throw ''
			})
			return true
		}catch(error){
			return false
		}
	}
	parseUnit = (array) => { 
		try{
			let buff = []
			array.map(({units}) => {
				units.map((unitId) => {
					// console.log('unitIdX', unitId)
					const index = this.props.property.units.findIndex(({_id}) => _id == unitId)
					// console.log('units', this.props.property.units)
					if(index == -1)
						console.error('Unit not found')
					else{
						buff.push(this.props.property.units[index].name)
					}
				})
			})
			// console.log('buff', buff)
			return buff
		}catch(error){
			console.error(error)
			return []
		}
	}
	renderAvailStatus = ({free, cancel, forcedClose}) => {
		if(free === true)
			return (
				<Neu style={{backgroundColor: 'green', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'min-content', height: 24, padding: '0 12px'}} state={false}>
					<h4 style={{color: 'white'}}><b>Free</b></h4>
				</Neu>
			)
		if(cancel === true)
			return (
				<Neu style={{backgroundColor: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'min-content', height: 24, padding: '0 12px'}} state={false}>
					<h4 style={{color: 'white'}}><b>Cancel</b></h4>
				</Neu>
			)
		if(forcedClose === true)
			return (
				<Neu style={{backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'min-content', height: 24, padding: '0 12px'}} state={false}>
					<h4 style={{color: 'white'}}><b>Closed</b></h4>
				</Neu>
			)
		else 
			return undefined
	}
	parseBookingData = (term = 'DAILY') => {
		// console.log('my term', term)
		return (
			this.state.data ? 
			this.state.data.filter(({term: termX}) => term == termX).map(({ _id, customer, checkOut, checkIn, roomRequests, bookingDate, price, origin, ota, term, insurance, free, cancel, forcedClose }) => {
				return ({
					'booking id'			: _id,
					'guest name'			: customer.name,
					'check in date'		: checkIn !== undefined && checkIn.status == true ? 
						<Neu style={{backgroundColor: 'green', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'min-content', height: 24, padding: '0 12px'}} state={false}>
							<h4 style={{color: 'white'}}><b>{roomRequests.map(({checkOutDate}) => moment(checkOutDate).format('YYYY-MM-DD')).toString()}</b></h4>
						</Neu> : roomRequests.map(({checkInDate}) 	=> moment(checkInDate).format('YYYY-MM-DD')).toString(),
					'check out date'	: checkOut !== undefined && checkOut.status == true ? 
						<Neu style={{backgroundColor: 'rgb(237, 50, 55)', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'min-content', height: 24, padding: '0 12px'}} state={false}>
							<h4 style={{color: 'white'}}><b>{roomRequests.map(({checkOutDate}) => moment(checkOutDate).format('YYYY-MM-DD')).toString()}</b></h4>
						</Neu> : roomRequests.map(({checkOutDate}) => moment(checkOutDate).format('YYYY-MM-DD')).toString(),
					'unit types'			: roomRequests.map(({unitTypeId}) 	=> this.props.property?.unitTypes?.find(({_id}) => _id == unitTypeId)?.name).toString(),
					'origin'					: origin == 'STAAH' ? ota : 'MANUAL',
					'insurance'				: term == 'DAILY' ? undefined : insurance ? insurance.amount : 'null',
					'booking date'		: moment(bookingDate).format('YYYY-MM-DD'),
					'unit'						: !this.isAssigned(roomRequests) ? <Unassigned/> : this.parseUnit(roomRequests),
					'avail'						: this.renderAvailStatus({free,cancel, forcedClose}),
					free,
					cancel,
					forcedClose
				})
			}) : []
		)
	}
	prev = () => {
		try{
			let month = this.state.month - 1
			let year = this.state.year
			if(month < 0){
				month = 11
				year -= 1 
			}
			this.setState({ month, year })
		}catch(error){ console.error(error) }
	}
	next = () => {
		try{
			let month = this.state.month + 1
			let year = this.state.year
			// console.log(pre)
			if(month > 11){
				month = 0
				year += 1 
			}
			this.setState({ month, year })
		}catch(error){ console.error(error) }
	}
	confirmUnitAssign = async ({bookingId, unitsAssignment, reAssign}) => {
		try{
			const params = {
				propertyId: this.props.property._id,
				bookingId,
				unitsAssignment,
				reAssign: false
			}
			const result = await API.colive.availability.assignUnit(params)
			console.debug('assign result', result)
		}catch(error){ console.error(error) }
	}
	confirmEdit = async ({bookingId, }) => {
		try{
			
		}catch(error){ console.error(error) }
	}
	handleCheckIn = async () => {
		try{

		}catch(error){ console.error(error) }
	}
	handleEdit = async () => {
		try{
			this.props.modal.setModal({
				active: true,
				component: <EditBookingModal onCancel={this.closeModal} onConfirm={this.confirmEdit}/>
			})
			this.props.contextMenu.setContextMenu({
				active		: false,
				component	: undefined
			})
		}catch(error){ console.error(error) }
	}
	confirmDelete = async ({bookingId}) => {
		try{
			const params = {
				propertyId: this.props.property._id,
				bookingId,
			}
			const promise = API.colive.booking.delete(params)
			this.props.modal.setModal({
				active: true,
				component: <NetworkInfoModal loading/>
			})
			const result = await promise
			if(result instanceof Error)
				this.props.modal.setModal({
					active: true,
					component: <NetworkInfoModal errorResponse={result}/>
				})
			this.closeModal()
			this.setState({ reload: true })
		}catch(error){ console.error(error) }
	}
	handleDelete = async ({index, _id}) => {
		try{
			this.props.modal.setModal({
				active: true,
				component: <DeleteBookingModal onCancel={() => this.props.modal.setModal({active: false, component: undefined})} onConfirm={() => this.confirmDelete({bookingId: _id})}/>
			})
			this.props.contextMenu.setContextMenu({
				active		: false,
				component	: undefined
			})
		}catch(error){ console.error(error) }
	}
	handleUnitAssign = ({index, _id}) => {
		try{
			// console.log('Handle Unit assign')
			// console.log('data', this.state.data[index])
			const bookingObject = this.state.data.find(({_id: x_id}) => x_id == _id )
			console.log('mm _id', _id)
			console.log('xx object', bookingObject)
			if(bookingObject === undefined) throw new Error('State data not found')
			const data = {
				name          : bookingObject && bookingObject.customer ? bookingObject.customer.name : '',
				bookingId     : bookingObject._id,
				roomRequests  : bookingObject.roomRequests.map(({unitTypeId,count,checkInDate,checkOutDate,_id}) => ({
					roomRequestId	: _id,
					unitTypeId    : unitTypeId,
					unitTypeName  : this.props.property && this.props.property.unitTypes ? this.props.property.unitTypes.find(({_id}) => _id == unitTypeId).name || 'ERROR' : 'ERROR',
					count         : count,
					checkInDate   : checkInDate,
					checkOutDate  : checkOutDate,
				}))
			}
			this.props.modal.setModal({
				active: true,
				component: 
					<AssignUnitModal 
						data={data}
						submit={this.assignUnit}
						units={this.props.property.units.map(({ _id, name, unitTypeId }) => ({ _id, name, unitTypeId }))}/>
			})
			this.props.contextMenu.setContextMenu({
				active		: false,
				component	: undefined
			})
		}catch(error){ console.error(error) }
	}
	handleItemClick = ({e, index, data, _id}) => {
		e.stopPropagation()
		e.preventDefault()
		// console.log('click')
		if(e.type == 'contextmenu') this.handleContextMenu({ index, data, x: e.clientX, y: e.clientY, _id })
	}
	assignUnit = async (event, selectedUnits, data) => {
		try{
			// const unitsAssignment = [{
			// 	roomRequestId : "5ebaa3710e287b0547322e51",
			// 	unitsId 			: ["5e60c1dc3249b407b08632c4"] 
			// }]
			// console.log('selectedUnits', selectedUnits)
			// console.log('units', this.props.property.units)
			// console.log('data', data)
			let 	unitsAssignment = []
			const bookingId 			= data.bookingId
			const roomRequests 		= data.roomRequests
			roomRequests.map(({ roomRequestId, count, unitTypeId }, index) => {
				// console.log('selectedUnits', selectedUnits)
				// console.log('units', this.props.property.units)
				// const units = selectedUnits.map(number => {
				// 	console.log('number', number)
				// 	return this.props.property.units[number]._id
				// })
				let realUnits
				const units = selectedUnits.map(number => 
						realUnits = number.map((uIndex) => this.props.property.units[uIndex]._id
					)
				)
				unitsAssignment.push({
					roomRequestId,
					unitsId: realUnits
				})
			})
			// console.log(unitsAssignment)
			const result = await API.colive.availability.assignUnit({ propertyId: this.props.property._id, bookingId: bookingId, unitsAssignment: JSON.stringify(unitsAssignment), reAssign: false })
			// console.log(result)
			this.closeModal()
			this.setState({ reload: true })
		}catch(error){ console.error(error) }
	}
	closeModal = () => this.props.modal.setModal({ active: false, component: undefined})
	checkOut = async (event, selectedUnits, data) => {
		try{
			const bookingId	= data.bookingId
			const result = await API.colive.booking.checkOut({ propertyId: this.props.property._id, bookingId: bookingId })
			// console.debug(result)
			this.closeModal()
			this.setState({ reload: true })
		}catch(error){ console.error(error) }
	}
	handleCheckOut = ({index,_id}) => {
		try{
			// const bookingObject = this.state.data[index]
			const bookingObject = this.state.data.find(({_id: x_id}) => x_id == _id )
			// console.log('bookingObject')
			if(bookingObject === undefined) throw new Error('State data not found')
			const data = {
				name          : bookingObject && bookingObject.customer ? bookingObject.customer.name : '',
				bookingId     : bookingObject._id,
				roomRequests  : bookingObject.roomRequests.map(({unitTypeId,count,checkInDate,checkOutDate,_id}) => ({
					roomRequestId	: _id,
					unitTypeId    : unitTypeId,
					unitTypeName  : this.props.property && this.props.property.unitTypes ? this.props.property.unitTypes.find(({_id}) => _id == unitTypeId).name || 'ERROR' : 'ERROR',
					count         : count,
					checkInDate   : checkInDate,
					checkOutDate  : checkOutDate,
				}))
			}
			this.props.modal.setModal({
				active: true,
				component: 
					<CheckOutModal 
						data={data}
						submit={this.checkOut}
						units={this.props.property.units.map(({ _id, name, unitTypeId }) => ({ _id, name, unitTypeId }))}/>
			})
			this.props.contextMenu.setContextMenu({
				active		: false,
				component	: undefined
			})
		}catch(error){ console.error(error) }
	}
	handleCancel = async ({_id, data}) => {
		try{
			const {cancel} = data
			if(this.props?.property?._id !== undefined){
				const result = await cancelBooking(this.props.property._id, _id, cancel === true ? false : true)
			}
			this.props.contextMenu.setContextMenu({ active: false, component: undefined})
			this.setState({ reload: true })
		}catch(error){ console.error(error) }
	}
	handleFree = async ({_id, data}) => {
		try{
			const {free} = data
			if(this.props?.property?._id !== undefined){
				const result = await markAsFree(this.props.property._id, _id, free === true ? false : true)
			}
			this.props.contextMenu.setContextMenu({ active: false, component: undefined})
			this.setState({ reload: true })
		}catch(error){ console.error(error) }
	}
	handleClose = async ({_id, data}) => {
		try{
			const {forcedClose} = data
			if(this.props?.property?._id !== undefined){
				const result = await markAsForcedClose(this.props.property._id, _id, forcedClose === true ? false : true)
			}
			this.props.contextMenu.setContextMenu({ active: false, component: undefined})
			this.setState({ reload: true })
		}catch(error){ console.error(error) }
	}
	handleContextMenu = ({index, data, x,y, _id}) => {
		this.props.contextMenu.setContextMenu({
			active		: true,
			component	: 
				<ContextMenu>
					<ContextMenuI
						label={'Edit'}
						onClick={ () => this.handleEdit({index,data, _id})}/>
					<ContextMenuI
						label={'Delete'}
						onClick={ () => this.handleDelete({index,data, _id})}/>
					<ContextMenuI
						label={'Assign Unit'}
						onClick={ () => this.handleUnitAssign({index,data,_id})}/>
					<ContextMenuI
						label={'Check Out'}
						onClick={ () => this.handleCheckOut({index,data, _id})}/>
					<ContextMenuI
						label={'Cancel Booking'}
						onClick={ () => this.handleCancel({index,data, _id})}/>
					<ContextMenuI
						label={'Mark as Free'}
						onClick={ () => this.handleFree({index,data, _id})}/>
					<ContextMenuI
						label={'Close'}
						onClick={ () => this.handleClose({index,data, _id})}/>
					{/* <ContextMenuI
						label={'Check In'}
						onClick={ () => this.handleCheckIn({index,data, _id})}/> */}
				</ContextMenu>,
			x,y
		})
	}
	render = () =>
		this.props.property ? 
		(
			<div 
				className={'TabPage Booking'} 
				style={{flexDirection: 'column', overflow: 'visible', marginBottom: 158}}>
				<Neu 
					onClick={this.handleAddBookingClick}
					style={{
						marginTop				: 8,
						width						: 'min-content',
						display					: 'flex', 
						justifyContent	: 'center', 
						alignItems			: 'center',
						marginLeft			: 16
					}}
					state={false}>
					<div style={{}}>
						<h5 style={{
							whiteSpace		: 'nowrap',
							padding				: '0px 16px'
						}}>Check In</h5>
					</div>
				</Neu>
				<div style={{ marginTop: 16, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<Neu
						onClick={this.prev}
						style={{ width: 24, height: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#2c2c2c', marginLeft: 12}}
						state={false}>
						<Left style={{height: '50%', width: '50%', fill: '#FFF'}}/>
					</Neu>
					<h2 style={{marginLeft: 12, marginRight: 12}}>{`${moment().month(this.state.month).format('MMMM')} ${this.state.year}`}</h2>
					<Neu 
						onClick={this.next}
						style={{ width: 24, height: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#2c2c2c'}}
						state={false}>
						<Right style={{height: '50%', width: '50%', fill: '#FFF'}}/>
					</Neu>
				</div>
				<div style={{ width: '100%', overflow: 'auto' }}>
					<div className={'tableContainer'} style={{ overflow: 'visible' }}>
						<div className={'tableInnerContainer'}>
						<Table 
							onItemClick={this.handleItemClick}
							title={'Daily Booking'}
							numbering={false}
							label={['Booking Id','Guest Name', 'Check In Date', 'Check Out Date', 'Unit Types', 'Unit', 'Origin', 'Booking Date', 'Avail']}
							data={this.parseBookingData()}/>
						</div>
					</div>
					</div>
					<div style={{ width: '100%', overflow: 'auto' }}>
					<div className={'tableContainer'} style={{overflow: 'visible'}}>
						<div className={'tableInnerContainer'}>
						<Table 
							onItemClick={this.handleItemClick}
							title={'Monthly Booking'}
							numbering={false}
							label={['Booking Id','Guest Name', 'Check In Date', 'Check Out Date', 'Unit Types', 'Unit','Insurance','Status','Actions', 'Avail']}
							data={this.parseBookingData('MONTHLY')}/>
						</div>
					</div>
				</div>
			</div>) : null
}

export default (props) => 
	<ContextMenuContext>{
		(contextMenuValue) => 
			<ModalContext.Consumer>{
				value => <Booking modal={value} {...props} contextMenu={contextMenuValue}/>
			}</ModalContext.Consumer>
	}</ContextMenuContext>
