import React, { Component } from 'react'
// * COMPONENT import
import Neu, {DropDown} from '../neu'
import UnitSelector from '../unitSelector'

// * API import
import API from '../../api'

// * UTILITY import
import getSafe from '../../utility/getSafe'

class AddUnitModal extends Component{
	constructor(props){
		super(props)
		// this.handleSelect = this.handleSelect.bind(this)
		this.onChangeSelect = this.onChangeSelect.bind(this)
	}
	state = {
		nameInput: '',
		selectedIndex : -1,
	}
	// handleSelect = (index) => {
	// 	console.log('handleSELECT', index)
	// 	this.setState({
	// 		selectedIndex: index
	// 	})
	// }
	onChangeSelect = (index) => this.setState({
		selectedIndex: index
	})
	render = () => 
		<div 
			className={'main_bg dropShadow modalPadding radius'} 
			style={{

			}}>
			<UnitSelector 
				// setSelected={this.handleSelect}
				// selected={this.state.selectedIndex}
				onChange={this.onChangeSelect}
				placeholder={'Choose unit type'}
				unit={false}
				style={{
					marginLeft: 0,
				}}
				data={this.props.unitTypes}/>
			<input 
				style={{
					marginTop: 16,
					width: 'auto',
					borderRadius: 5,
					border: 0,
					padding: 8
				}}
				onChange={(e) => 	this.setState({nameInput: e.target.value})}
				placeholder={'name'}/>
			<div 
				onClick={(e) => this.props.confirm(this.state.nameInput,this.state.selectedIndex)}
				className={'cursor pointer'}
				style={{
					width: 'auto',
					backgroundColor: 'white',
					borderRadius: 5,
					marginTop: 16,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: 8
				}}>
				<h5>Add</h5>
			</div>
		</div>
}

export default AddUnitModal