import React, { Component } from 'react'
import InputField from '../../components/InputField'
import TagInput,{Tag} from '../../components/tagInput'
import '../../styles/detail.scss'

import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

import Neu from '../../components/neu'
import DragDrop from '../../components/DragDrop'
import DragAndDrop from '../../components/DragAndDrop'
import Plus from '../../components/svg/plus'

// * CONTEXT import
import ModalContext from '../../context/modal'
import API from '../../api'

// * API import

const InputImage = (props) => 
	<div className={`InputImage ${props.src ? 'available' : ''}`}>{
		props.src ? 
			<img src={props.src} alt={props.alt || ''} style={{
				objectFit: 'cover'
			}}/> : 
			<div className={'inputPlus'}>
				<Plus/>
			</div>
	}</div>

const ActionBar = (props) => 
	<div className={`ActionBar ${props.className? props.className : ''}`}>
		<Neu 
			onClick={props.onSave}
			state={false}>
			<h5>Save</h5>
		</Neu>
		{/* <Neu state={false}>
			<h5>GMB Connect</h5>
		</Neu> */}
		{/* <Neu state={false}>
			<h5>Staah Connect</h5>
		</Neu> */}
	</div>

const Name = (props) => 
	<div 
		className={'inputFieldContainer'}>
		<h3 className={'title'}>Name</h3>
		<InputField 
			onChange={props.onChange}
			value={props.value}
			placeholder={'Name'}/>
	</div>

const Address = (props) => 
	<div className={'inputFieldContainer'}>
		<h3 className={'title'}>Address</h3>
		<InputField 
			onChange={props.onChange}
			value={props.value}
			placeholder={'Address'}/>
	</div>

const Coordinates = (props) => 
	<div className={'inputFieldContainer'}>
		<h3 className={'title'}>Coordinates</h3>
		<div className={'innerinputFieldContainer row'}>
			<InputField 
				onChange={props.changeX}
				value={props.value[0]}
				placeholder={'X coordinates'}/>
			<InputField 
				onChange={props.changeY}
				value={props.value[1]}
				placeholder={'Y coordinates'}/>
		</div>
	</div>

const Thumbnail = () => 
	<div className={'inputFieldContainer'}>
		<h3 className={'title'}>Thumbnail</h3>
		<DragDrop handleDrop={this.handleDrop}>
			<div style={{height: 300, width: 250}}>
				{this.state.files.map((file,i) =>
					<div key={i}>{file}</div>
				)}
			</div>
		</DragDrop>
	</div>

const SliderImages = () => 
	<div className={'inputFieldContainer'}>
		<h3 className={'title'}>SliderImages</h3>
		<InputField/>
	</div>

const Description = (props) => 
	<div className={'inputFieldContainer'}>
		<h3 className={'title'}>Description</h3>
		<InputField 
			placeholder={'Description'}
			{...props}/>
	</div>

const Facilities = () =>
	<div className={'inputFieldContainer'}>
		<h3 className={'title'}>Facilities</h3>
		<TagInput>
			<Tag label={'WiFi'}/>
			<Tag label={'Parkir'}/>
			<Tag label={'TV'}/>
			<Tag label={'AC'}/>
			<Tag label={'Kamar Mandi Dalam'}/>
		</TagInput>
	</div>

class Detail extends Component{
	constructor(props){
		super(props)
		this.setEdit 					= this.setEdit.bind(this)
		this.handleDrop 			= this.handleDrop.bind(this)
		this.handleDropSlider = this.handleDropSlider.bind(this)
		this.handleNameChange = this.handleNameChange.bind(this)
		this.handleAddressChange = this.handleAddressChange.bind(this)
		this.handleCoordinatesChangeX = this.handleCoordinatesChangeX.bind(this)
		this.handleCoordinatesChangeY = this.handleCoordinatesChangeY.bind(this)
		this.handleSave = this.handleSave.bind(this)
	}
	// * STATE COMPONENT
	state = {
		// State for blob file URL
		loading: false,
		files: [],
		edit: false,
		thumbnail: null,
		sliderThumbnail: [],
		data: {}
	}
	// * LIFECYCLE COMPONENT
	componentDidMount = () => {
		this.loadData()
	}
	componentDidUpdate = (prevProps,prevState) => {
		// console.log(this.props)
		if(prevProps != this.props){
			this.loadData()
		}
	}
	loadData = async () => {
		try{
			if(!this.props.property)
				return
			this.setState({loading:true})
			const result = await API.colive.detail({
				propertyId: this.props.property._id
			})
			console.log(result)
			if(result instanceof Error)
				throw result
			this.setState({data: result.data})
		}catch(e){
			console.error(e)
		}
	}
	setEdit = () => 
		this.setState({
			edit: !this.state.edit,
		})
	handleDrop = (blobArray) => {
		if(blobArray.length > 0)
			this.setState({
				thumbnail: blobArray[0],
			})
	}
	handleDropSlider = (blobArray, index = null) => {
		try{
			if(index === null){
				if(blobArray.length > 0)
					this.setState({
						sliderThumbnail: [
							...this.state.sliderThumbnail,
							...blobArray
						]
					})
			}else{
				let buffer = [...this.state.sliderThumbnail]
				if(blobArray.length > 0){
					buffer[index] = blobArray[0]
					this.setState({
						sliderThumbnail: [...buffer]
					})
				}
			}
		}catch(e){
			console.log(e)
		}
	}
	handleNameChange 					= (e) => this.setState({ data: { ...this.state.data, name: e.target.value }})
	handleAddressChange 			= (e) => this.setState({ data: { ...this.state.data, address: e.target.value}}) 
	handleCoordinatesChangeX 	= (e) => this.setState({ data: {...this.state.data, coordinates: [e.target.value,this.state.data.coordinates[1] || null]}})
	handleCoordinatesChangeY 	= (e) => this.setState({ data: {...this.state.data, coordinates: [this.state.data.coordinates[0] || null, e.target.value]}})
	handleSave = async () => {
		try{
			// console.log('handleSave')
			// console.log(this.state)
			this.setState({loading:true})
			const result = await API.colive.update.updateProperty({
				propertyId	: this.props.property._id,
				name				: this.state.data.name,
				address			: this.state.data.address,
				coordinates	: [Number(this.state.data.coordinates[0]),Number(this.state.data.coordinates[1])]
			})
			this.setState({loading:false})
			// console.log(result)
		}catch(e){
			console.error(e)
		}
	}
	render = () => 
		this.props.property ? 
		<div className={'Detail TabPage'}>
			<ActionBar
				onSave={this.handleSave}
				edit={this.state.edit}/>
			<Name 
				type={'string'}
				onChange={this.handleNameChange}
				value={this.state.data.name}
				edit={this.state.edit}/>
			<Address 
				value={this.state.data.address}
				onChange={this.handleAddressChange}
				type={'string'}
				edit={this.state.edit}/>
			<Coordinates 
				changeX={this.handleCoordinatesChangeX}
				changeY={this.handleCoordinatesChangeY}
				value={this.state.data.coordinates || []}
				type={'number'}
				edit={this.state.edit}/>
			<div className={'inputFieldContainer'}>
				<h3 className={'title'}>Thumbnail</h3>
				<DragAndDrop handleDrop={this.handleDrop}>
					<InputImage src={this.state.thumbnail}/>
				</DragAndDrop>
			</div>
			{/* Aspect Ratio Slider is 16:9 */}
			<div className={'inputFieldContainer slider'}>
				<h3 className={'title'}>Image Slider</h3>
				<div className={'innerinputFieldContainer row slider'}>
				{
					this.state.sliderThumbnail.map((source,index) => 
						<DragAndDrop 
							className={'InputField'}
							handleDrop={(args) => this.handleDropSlider(args,index)}>
							<InputImage src={source}/>
						</DragAndDrop>)
				}
				<DragAndDrop 
					multiple={true}
					className={'InputField'}
					handleDrop={(args) => this.handleDropSlider(args)}>
					<InputImage src={null}/>
				</DragAndDrop>
				</div>
			</div>
			<Description 
				textarea={true}
				type={'string'}
				edit={this.state.edit}/>
			<Facilities 
				edit={this.state.edit}/>
		</div> : null
}

// export default Detail

export default (props) => 
	<ModalContext.Consumer>
		{value => <Detail modal={value} {...props}/>}
	</ModalContext.Consumer>