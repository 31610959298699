import React, { Component } from 'react'
import '../../styles/units.scss'
import NeuToggle from '../../components/neuToggle'
import Neu from '../../components/neu'
import Pencil from '../../components/svg/pencil'
import Bin from '../../components/svg/bin'
import Plus from '../../components/svg/plus'

import Table, {ActionButton} from '../../components/table'

// * CONTEXT import
import ModalContext from '../../context/modal'

// * MODAL import
import NetworkInfoModal from '../../components/modals/networkInfoModal'
import ConfirmDeleteModal from '../../components/modals/confirmDeleteModal'
import AddUnitModal from '../../components/modals/addUnitModal'
import EditUnitModal from '../../components/modals/editUnitModal'


// * API import
import API from '../../api'

// * UTILITY import
import getSafe from '../../utility/getSafe'

const ActionContainer = (props) => 
	<div className={'actionContainer'}>
		<Neu 
			onClick={() => props.onEdit(props.index)}
			className={'actionButton'}
			state={false}>
			<Pencil/>
		</Neu>
		<Neu 
			onClick={() => props.onDelete(props.index)}
			className={'actionButton'}
			state={false}>
			<Bin/>
		</Neu>
	</div>

const DataTable = (props) => 
	<div className={'tableContainer'}>
		<div className={'tableInnerContainer'}>
		<Table {...props}/>
		</div>
	</div>

class Units extends Component{
	constructor(props){
		super(props)
		// * UNIT
		this.handleAddUnit = this.handleAddUnit.bind(this)
		this.handleUnitStatusChange = this.handleUnitStatusChange.bind(this)
		this.handleUnitDelete = this.handleUnitDelete.bind(this)
		this.handleUnitEdit = this.handleUnitEdit.bind(this)
		// *UNIT TYPE
		this.handleAddUnitType = this.handleAddUnitType.bind(this)
		this.handleUnitTypeStatusChange = this.handleUnitTypeStatusChange.bind(this)
		this.handleUnitTypeDelete = this.handleUnitTypeDelete.bind(this)
		this.handleUnitTypeEdit = this.handleUnitTypeEdit.bind(this)
		// * CONFIRM UNIT
		this.confirmAddUnit = this.confirmAddUnit.bind(this)
		this.confirmDeleteUnit = this.confirmDeleteUnit.bind(this)
		this.confirmEditUnit = this.confirmEditUnit.bind(this)
		// *CONFIRM UNIT TYPE
		this.confirmAddUnitType = this.confirmAddUnitType.bind(this)
		this.confirmDeleteUnitType = this.confirmDeleteUnitType.bind(this)
		this.confirmEditUnitType = this.confirmEditUnitType.bind(this)

		this.closeModal = this.closeModal.bind(this)
	}
	// * COMPONENT STATE
	state = {
		unit: [],
		unitType: [],
		loading: false
	}
	// * COMPONENT LIFECYCLE
	componentDidMount = () => {
		this.loadData()
	}
	componentDidUpdate = (prevProps,prevState) => {
		if(prevProps != this.props)
			this.loadData()
	}
	// * HANDLE LOAD DATA
	loadData = async () => {
		try{
			console.log('Loading unit Data')
			const propertyId = getSafe(() => this.props.property._id,undefined)
			if(!propertyId)
				return
			// * UNIT AND UNIT TYPE PROMISES
			const promises = Promise.all([API.colive.unit.all({propertyId: propertyId}), API.colive.unit.type.all({propertyId: propertyId})])
			const results = await promises
			const unitResult = results[0]
			const unitTypeResult = results[1]
			if(unitResult instanceof Error)
				throw unitResult
			if(unitTypeResult instanceof Error)
				throw unitTypeResult
			this.setState({
				unit: unitResult.data,
				unitType: unitTypeResult.data
			}, () => console.log(this.state))
		}catch(e){
			console.error(e)
		}
	}
	closeModal = () => this.props.modal.setModal({active: false, component: null})
	// * CONFIRM FUNCTIONS
	confirmAddUnit = async (name,selectedIndex) => {
		try{
			this.setState({loading: true})
			this.props.modal.setModal({
				active: true,
				component: <NetworkInfoModal loading={true}/>
			})
			const result = await API.colive.unit.add({
				propertyId: this.props.property._id,
				unitType: this.state.unitType[selectedIndex]._id,
				name: name
			})
			console.log(result)
			this.setState({loading: false})
			if(result instanceof Error){
				this.props.modal.setModal({
					active: true,
					component: 
						<NetworkInfoModal 
							loading={false} 
							closeModal={this.closeModal}
							error={true}
							errorResponse={result.response}/>})
				throw result
			}
			this.closeModal()
			this.setState({
				unit: [
					...this.state.unit,
					result.data
				]
			})
		}catch(e){
			this.props.modal.setModal({
				active: false,
				component: null
			})
			console.error(e)
		}
	}
	confirmDeleteUnit = async (index) => {
		try{
			this.setState({loading: true})
			this.props.modal.setModal({
				active: true,
				component: <NetworkInfoModal loading={true}/>
			})
			const result = await API.colive.unit.delete({
				propertyId: this.props.property._id,
				unitId: this.state.unit[index].id
			})
			if(result instanceof Error){
				this.props.modal.setModal({
					active: true,
					component: 
						<NetworkInfoModal 
							loading={false} 
							closeModal={this.closeModal}
							error={true}
							errorResponse={result.response}/>})
				throw result
			}
			this.closeModal()
			this.setState({loading: false})
		}catch(e){
			console.error(e)
		}
	}
	confirmEditUnit = async (name,unitIndex,selectedIndex) => {
		try{
			this.setState({loading: true})
			this.props.modal.setModal({
				active: true,
				component: <NetworkInfoModal loading={true}/>
			})
			const result = await API.colive.unit.edit({
				propertyId: this.props.property._id,
				unitId: this.state.unit[unitIndex].id,
				unitType: this.state.unitType[selectedIndex]._id,
				name: name
			})
			if(result instanceof Error){
				this.props.modal.setModal({
					active: true,
					component: 
						<NetworkInfoModal 
							loading={false} 
							closeModal={this.closeModal}
							error={true}
							errorResponse={result.response}/>})
				throw result
			}
			this.closeModal()
			this.setState({loading: false})
			// console.log(result)
		}catch(e){
			console.error(e)
		}
	}
	confirmAddUnitType = async () => {

	}
	confirmEditUnitType = async () => {

	}
	confirmDeleteUnitType = async () => {

	}
	// * UNIT CRUD FUNCTIONS
	handleAddUnit = () => {
		console.log('add unit')
		this.props.modal.setModal({
			active: true,
			component: 
				<AddUnitModal 
					unitTypes={this.state.unitType}
					propertyId={getSafe(() => this.props.property._id,undefined)} 
					confirm={this.confirmAddUnit}/>
		})
	}
	handleUnitStatusChange = async (index,disable) => {
		try{
			console.log('handleStatusChange')
			this.setState({loading: true})
			this.props.modal.setModal({
				active: true,
				component: <NetworkInfoModal loading={true}/>
			})
			let result
			result = await API.colive.unit.disable({
				propertyId: getSafe(() => this.props.property._id,undefined), 
				unitId: this.state.unit[index].id
			})
			this.setState({loading: false,})
			// console.log(result)
			if(result instanceof Error){
				this.props.modal.setModal({
					active: false,
					component: 
						<NetworkInfoModal 
							loading={false} 
							closeModal={this.closeModal}
							error={true}
							errorResponse={result.response}/>
				})
				throw result
			}
			this.props.modal.setModal({
				active: false,
				component: null
			})
			let units = this.state.unit
			units[index].disabled = disable
			// this.setState({
			// 	unit: units
			// })
		}catch(e){
			console.error(e)
		}
	}
	handleUnitDelete = (index) => {
		console.log('handleDelete')
		this.props.modal.setModal({
			active: true,
			component: 
				<ConfirmDeleteModal 
					index={index}
					name={this.state.unit[index].name}
					confirm={this.confirmDeleteUnit}
					closeModal={this.closeModal}/>
		})
	}
	handleUnitEdit = (index) => {
		console.log('handleEdit')
		this.props.modal.setModal({
			active: true,
			component: 
				<EditUnitModal 
					placeholder={this.state.unit[index].name}
					unitTypes={this.state.unitType}
					propertyId={getSafe(() => this.props.property._id,undefined)} 
					index={index}
					selectedIndex={this.state.unitType.findIndex((object) => object._id == this.state.unit[index].unitType)}
					confirm={this.confirmEditUnit}/>
		})
	}
	// * UNIT TYPE CRUD FUNCITIONS
	handleAddUnitType = () => {
		console.log('handle add unit type')
		// this.props.modal.setModal({
		// 	active: true,
		// 	component: <AddUnitModal/>
		// })
	}
	handleUnitTypeStatusChange = () => {
		console.log('handleStatusChange')
	}
	handleUnitTypeEdit = () => {
		console.log('handleEdit')
		// this.props.modal.setModal({
		// 	active: true,
		// 	component: <AddUnitModal/>
		// })
	}
	handleUnitTypeDelete = () => {
		console.log('handleDelete')
	}
	// * RENDER COMPONENT
	render = () => 
		this.props.property ? 
		<div className={'Units TabPage'}>
			<DataTable 
				actions={[<ActionButton title={'Add'} onClick={this.handleAddUnit}/>]}
				title={'Unit'} 
				label={['Name','Unit Type','Actions']}
				data={this.state.unit.map(({name,disabled,id,unitType,unitTypeName},index) => {
					return {
						name: name,
						'unit type': unitTypeName,
						status: 
							<NeuToggle 
								index={index}
								id={id}
								status={disabled} 
								onChange={this.handleUnitStatusChange}/>,
						actions: 
							<ActionContainer 
								index={index}
								id={id}
								onDelete={this.handleUnitDelete} 
								onEdit={this.handleUnitEdit}/> 
					}
				})}/>
			<DataTable 
				actions={[<ActionButton title={'Add'} onClick={this.handleAddUnitType}/>]}
				title={'Unit Type'} 
				label={['Name','Actions']}
				data={this.state.unitType.map(({name,disabled,id,unitType,unitTypeName},index) => {
					return {
						name: name,
						// 'unit type': unitTypeName,
						status: 
							<NeuToggle 
								index={index}
								id={id}
								status={disabled} 
								onChange={this.handleUnitTypeStatusChange}/>,
						actions: 
							<ActionContainer 
								index={index}
								id={id}
								onDelete={this.handleUnitTypeDelete} 
								onEdit={this.handleUnitTypeEdit}/> 
					}
				})}/>
		</div> : null
}

// export default Units
export default (props) => 
	<ModalContext.Consumer>
		{value => <Units modal={value} {...props}/>}
	</ModalContext.Consumer>