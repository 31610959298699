import { OverviewRoomTypeData, OverviewTableData } from "../../components/OverviewTableView"
import { OverviewData } from "../../pages/colive/all/nOverviewAll"
// export interface OverviewRoomTypeData {
//   _id:                string
//   name:               string
//   unitTypeName:       string
//   totalRoom:          number
//   dailyRoomBooked:    number
//   dailyRoomRate:      number
//   dailyRevenue:       number
//   monthlyRoomBooked:  number
//   monthlyRoomRate:    number
//   monthlyRevenue:     number
//   free:               number
//   renov:              number
//   emptyRoom:          number
//   totalRoomBooked:    number
//   avgRoomRate:        number
//   occRate:            number
//   revenue:            number
// }

const transformOverviewDataToTableViewData = (overviewData: OverviewData): OverviewTableData => {
  let data: OverviewRoomTypeData[] = []
  overviewData.property.forEach(ptData => {
    ptData.roomTypes.forEach((rtData) => {
      data.push({
        _id:                rtData._id,
        name:               ptData.name,
        unitTypeName:       rtData.unitTypeName,
        totalRoom:          rtData.totalRoom,
        dailyRoomBooked:    rtData.dailyRoomBooked,
        dailyRoomRate:      rtData.dailyRoomRate,
        dailyRevenue:       rtData.dailyRevenue,
        monthlyRoomBooked:  rtData.monthlyRoomBooked,
        monthlyRoomRate:    rtData.monthlyRoomRate,
        monthlyRevenue:     rtData.monthlyRevenue,
        free:               rtData.free,
        renov:              rtData.renov,
        emptyRoom:          rtData.emptyRoom,
        totalRoomBooked:    rtData.totalRoomBooked,
        avgRoomRate:        rtData.avgRoomRate,
        occRate:            rtData.occRate,
        revenue:            rtData.revenue,
      })
    })
  });
  return ({
    roomTypes: data,
    total: overviewData.total || ({
      totalRoom:            0,
      totalDailyBooked:     0,
      totalDailyRevenue:    0,
      totalMonthlyBooked:   0,
      totalMonthlyRevenue:  0,
      totalFree:            0,
      totalRenov:           0,
      totalEmpty:           0,
      totalBooked:          0,
      averageRoomRate:      0,
      averageOccRate:       0,
      totalRevenue:         0,
    })
  })
}

export default transformOverviewDataToTableViewData