import React, { Component } from 'react'
import qs from 'query-string'

import PropertySelector 		from '../components/propertySelector'
import TabMenu, {Tab} 			from '../components/tabMenu'

// import Overview 		from './colive/overview.jsx'
import Detail 			from './colive/detail'
import Units 				from './colive/units'
import Rates 				from './colive/nrates.jsx'
import Electricity 	from './colive/electricity.tsx'
import Booking 			from './colive/booking.jsx'
import Reports 			from './colive/reports.jsx'
import Availability from './colive/availability.jsx'

// import OverviewAll 	from './colive/all/overviewAll'

import NetworkInfoModal from '../components/modals/networkInfoModal'
import LoadIndicator 		from '../components/loadIndicator'

import API from '../api'

import '../styles/subPage.scss'

// * CONTEXT IMPORT
import ModalContext from '../context/modal'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	Redirect,
	withRouter
} from "react-router-dom"
import NOverview from './colive/nOverview'
import NOverviewAll from './colive/all/nOverviewAll'
import NAvailability from './colive/nAvailability'

const tabs = [{
	name: 'Overview'
},{
	name: 'Units'
},{
	name: 'Detail'
},{
	name: 'Rates'
},{
	name: 'Booking'
},{
	name: 'Reports'
},
// {
// 	name: 'Electricity'
// },
{
	name: 'Availability'
}]

class Colive extends Component{
	constructor(props){
		super(props)
		this.changeTab = this.changeTab.bind(this)
		this.setSelected = this.setSelected.bind(this)
		this.getColiveData = this.getColiveData.bind(this)
		this.refreshProperty = this.refreshProperty.bind(this)
	}
	state = {
		selected: -1,
		propertyData: [],
		tabSelected: 0,
		loading: false
	}
	setSelected = (index) => {
		try{
			const propertyLength = this.state.propertyData.length
			if(index > propertyLength-1 || index < 0)
				throw new Error('Selected index out of bounds')
			this.setState({
				selected: index
			})
		}catch(e){ console.error(e) }
	}
	changeTab = (index) => {
		try{
			const {title,history} = this.props
			this.setState({
				tabSelected: index
			})
			history.push(`/colive/${tabs[index].name.toLowerCase()}`)
		}catch(e){
			console.log(e)
		}
	}
	getColiveData = async () => {
		try{
			this.setState({loading: true})
			const result = await API.colive.all()
			this.setState({loading: false})
			if(result instanceof Error){
				
				throw result
			}
			const {data} =  result
			let propertyId
			if(this.state.selected >= 0){
				const {_id} = this.state.propertyData[this.state.selected]
				propertyId = _id
				console.log('PROPERTY ID')
				console.log(propertyId)
			}
			console.log('DATA')
			console.log(data)
			const index = data.findIndex((object) => object._id == propertyId)
			console.log('INDEX')
			console.log(index)
			const all = {
				_id: "ALL",
				name: "ALL"
			}
			this.setState({
				selected: index,
				propertyData: [...data,all]
			})
		}catch(e){
			console.error(e)
		}
	}
	componentDidMount = async () => {
		// TODO ! USE THIS AWESOME QUERY PROPERTY
		console.log('Query')
		console.log(qs.parse(this.props.location.search))
		this.getColiveData()
	}
	componentDidUpdate = () => {
		console.log('update',this.state.propertyData[this.state.selected]?._id )
	}
	refreshProperty = () => {
		this.getColiveData()
	}
	render = () => 
		<div className={'Colive SubPage'}>
			<div style={{
				display: 'flex', 
				flexDirection: 'row', 
				alignItems: 'center',
				paddingTop: 8
			}}>
				<PropertySelector
					loading				={this.state.loading}
					selected			={this.state.selected}
					setSelected		={this.setSelected}
					property			={this.state.propertyData}
					getColiveData	={this.getColiveData}/>
				<div 
					className={'cursor pointer'}
					style={{
						width						: 40,
						height					: 40,
						display					: 'flex',
						justifyContent	: 'center',
						alignItems			: 'center',
						marginLeft			: 8
					}}
					onClick={this.refreshProperty}>
					<LoadIndicator style={{height: '50%', width: '50%'}} className={this.state.loading ? 'animation rotate': ''}/>
				</div>
			</div>
			<TabMenu>{
				this.state.propertyData[this.state.selected]?._id !== "ALL" ?
					tabs.map((object,index) => 
						<Tab
							key={index}
							active={this.props.history.location.pathname.includes(object.name.toLowerCase())}
							label={object.name}
							onClick={() => this.changeTab(index)}/>) :
						<Tab
							// key={index}
							active={this.props.history.location.pathname.includes('Overview'.toLowerCase())}
							label={'Overview'}
							onClick={() => this.changeTab(0)}/>
				
			}</TabMenu>
			<Switch>
			{
				this.state.propertyData[this.state.selected]?._id !== "ALL" ?
				<>
				{/* <Route path={'/colive/overview'}>
					<Overview property={this.state.propertyData[this.state.selected]}/>
				</Route> */}
				<Route path={'/colive/overview'}>
					<NOverview property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route path={'/colive/units'}>
					<Units property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route path={'/colive/detail'}>
					<Detail property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route path={'/colive/rates'}>
					<Rates property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route path={'/colive/booking'}>
					<Booking property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route path={'/colive/reports'}>
					<Reports property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route path={'/colive/electricity'}>
					<Electricity property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route path={'/colive/availability'}>
					<NAvailability property={this.state.propertyData[this.state.selected]}/>
				</Route>
				<Route path={'/colive/'}>
					<Redirect to={{pathname: '/colive/overview'}}/>
				</Route>
				</> : 
				<Route path={'/colive/overview'}>
					<NOverviewAll propertyList={this.state.propertyData}/>
				</Route>
			}
			</Switch>
		</div>
}

const ColiveWithContext = (props) => 
	<ModalContext.Consumer>
	{value => <Colive {...props} modal={value}/>}
	</ModalContext.Consumer>

export default withRouter(Colive)