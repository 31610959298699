import React from 'react'

const Dashboard = (props) => 
<svg 
	className={`${props.className}`}
	focusable="false" 
	viewBox="0 0 24 24" 
	aria-hidden="true" 
	role="presentation">
		<path fill="none" d="M0 0h24v24H0z"></path>
		<path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z">
		</path>
</svg>

export default Dashboard