import React, { Component } from 'react'

class ConfirmDeleteModal extends Component{
	constructor(props){
		super(props)
		console.log(this.props.name)
	}
	render = () => 
		<div className={'main_bg dropShadow modalPadding radius'}>
			<h2>Delete {this.props.name || ''} ?</h2>
			<div style={{
				marginTop: 16,
				display: 'flex',
				flexDirection: 'row'
			}}>
				<div 
					onClick={() => this.props.confirm(this.props.index)}
					className={'radius dropShadow cursor pointer'}
					style={{
						flex: 1, 
						height: 24,
						backgroundColor: 'white',
						padding: 8,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}>
					<h5>Confirm</h5>
				</div>
				<div style={{width:16}}/>
				<div 
					onClick={this.props.closeModal}
					className={'radius dropShadow cursor pointer'}
					style={{
						flex: 1, 
						height: 24,
						backgroundColor: 'white',
						padding: 8,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}>
					<h5>Cancel</h5>
				</div>
			</div>
		</div>
}

export default ConfirmDeleteModal