import React, { Component } from 'react'
import Neu, { DropDown } from './neu'
import './styles/profileCard.scss'
import profile from '../assets/images/profile.svg'

import getSafe from '../utility/getSafe'
import AuthContext from '../context/auth'
import GoogleSVG from '../assets/images/google.svg'

import '../styles/components/profileCard.scss'

import Cookies 							from 'universal-cookie'
import API from '../api'

import firebase from "firebase/app";
import "firebase/auth";

import {firebaseConfig} from '../firebaseConfig'

const cookies = new Cookies()

class ProfileCard extends Component { 
	constructor(props){
		super(props)
		this.logOut = this.logOut.bind(this)
		this.bindGoogle = this.bindGoogle.bind(this)
	}
	componentDidMount = () => {
		// console.log('Profile card auth props..')
		// console.log(this.props.auth)
	}
	logOut = (e) => {
		try{
			// console.log(this.props.auth)
			this.props.auth.logOut()
		}catch(error){
			console.error(error)
		}
	}
	bindGoogle = async () => {
		try{
			if (firebase.apps.length === 0)	firebase.initializeApp(firebaseConfig)
			const provider 		= new firebase.auth.GoogleAuthProvider()
			const result 			= await firebase.auth().signInWithPopup(provider)
			const profile 		= await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
			const token 			= result && result.credential && result.credential.accessToken 	? result.credential.accessToken : undefined
			// const user 			= result && result.user 																				? result.user : undefined
			const email 			= result && result.user && result.user.email 										? result.user.email : undefined
			console.log('result', result	)
			console.log('token'	, token		)
			console.log('email'	, email		)
			const bindResult 	= await API.user.bindGoogle({email, firebaseToken: profile})
			console.log('bindResult', bindResult)
		}catch(error){ console.error(error) }
	}
	render = () => 
		<Neu className={'ProfileCard'}>
			<div className={'profilePicture'}>
				<img src={profile}/>
			</div>
			<div className={'badgeContainer'}>
				<div className={'profileName'}>
					<label>{getSafe(() => this.props.auth.credential.name, 'User') || 'User'}</label>
				</div>
				<div className={'roleContainer'}>
					<label>{this.props.auth.credential.role || 'Admin'}</label>
				</div>
			</div>
			<DropDown>
				<div 
					onClick={this.logOut}
					className={'dropDownItem'}>
					<p><b>LogOut</b></p>
				</div>
				<div 
					onClick={this.bindGoogle}
					className={'dropDownItem'}>
					<img style={{ height: '50%', marginRight: 8 }} src={GoogleSVG}/>
					<p><b>Bind Google</b></p>
				</div>
			</DropDown>
		</Neu>
}

export default (props) => 
	<AuthContext.Consumer>
	{ value => <ProfileCard auth={value}/>}
	</AuthContext.Consumer>
