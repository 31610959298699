import React, { Component, useEffect, useState } from 'react'
import { getColiveOverview, GetColiveOverviewData } from '../../v2'
import moment from 'moment'
import formatRupiah from '../../utility/rupiahFormator'

const NOverview = ({property}: {property: any | undefined}) => {
  const [overviewData, setOverviewData] = useState<GetColiveOverviewData | undefined>()
  const [selectedDate, setSelectedDate] = useState<string>(moment().format('YYYY-MM-DD'))
  const loadData = async () => {
    try{
      setOverviewData(undefined)
      const result = await getColiveOverview({ propertyId: property._id, startDate: selectedDate})
      console.log('result')
      if(result instanceof Error) throw result
      setOverviewData(result)
    }catch(error){
      console.error(error)
    }
  }
  useEffect(() => {
    loadData()
    return () => {
      
    }
  }, [property, selectedDate])
  return (
    <div className={'Overview TabPage'}>
      <div style={{ display: 'flex', flexDirection: 'column'}}>
        <div style={{
          marginLeft: 16,
          marginRight: 16,
          marginTop: 8,
          marginBottom:24,
          display: 'flex',
          flexDirection: 'row'
        }}>
          <div 
            className={'Neu'}
            style={{
              width: 'min-content',
              height: 40,
              borderRadius: 5,
              overflow: 'hidden'
            }}>
            <input 
              style={{height: '100%'}}
              value={selectedDate}
              // pattern={"\d{4}-\d{2}-\d{2}"}
              // format={'YYYY-MM-DD'}
              type={'date'}
              onChange={(e) => {
                setSelectedDate(e.target.value)
              }}/>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 24, userSelect: 'all'}}>
          <p >{overviewData?.name}</p>
          {
            overviewData?.unitTypes.map((unitTypeOverviewData, index) => 
              <table key={index}>
                <thead>
                  <tr>
                    <th colSpan={2} align={"left"}>{unitTypeOverviewData.unitTypeName}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total unit</td>
                    <td>{unitTypeOverviewData.totalUnit}</td>
                  </tr>
                  <tr>
                    <td>Free</td>
                    <td>{unitTypeOverviewData.free}</td>
                  </tr>
                  <tr>
                    <td>Renov,Etc</td>
                    <td>{unitTypeOverviewData.renovEtc}</td>
                  </tr>
                  <tr>
                    <td>Total booked</td>
                    <td>{unitTypeOverviewData.totalBooked}</td>
                  </tr>
                  <tr>
                    <td><b>Available</b></td>
                    <td><b>{unitTypeOverviewData.available}</b></td>
                  </tr>
                  <tr>
                    <td>Occupancy</td>
                    <td>{Math.floor(unitTypeOverviewData.occupancy * 100)}%</td>
                  </tr>
                  <tr>
                    <td>Price</td>
                    <td>Rp. {formatRupiah(unitTypeOverviewData.price.toString())},00</td>
                  </tr>
                  <tr>
                    <td>Revenue</td>
                    <td>Rp. {formatRupiah(unitTypeOverviewData.revenue.toString())},00</td>
                  </tr>
                </tbody>

              </table>
            )
          }
        </div>
      </div>
      
    </div>
  )
}


export default NOverview