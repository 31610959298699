import React, { Component } from 'react'

import Refresh from './svg/refresh'

class LoadIndicator extends Component{
	render = () => 
		<div className={` ${this.props.className ? this.props.className : ''}`} style={{
			width: 'min-content',
			height: 'min-content',
			...this.props.style,
			transition: 'all 300ms ease'
		}}>
			<Refresh/>
		</div>
}

export default LoadIndicator