import React, { Component } from 'react'
const dayName = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']

const DayCycle = (props) => {
	return <div 
		className={`cursor pointer ${props.className}`}
		onClick={props.onClick}
		style={{
			width: 32,
			height: 32,
			borderRadius: 500,
			backgroundColor: props.selected ? '#22304A' : 'white',
			color: props.selected ? 'white': 'black',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: '0 4px',
			transition: 'all 300ms ease',
			...props.style
		}}>
		<h5>{props.char}</h5>
	</div>}

class DaysSelector extends Component{
	constructor(props){
		super(props)
		this.handleClick = this.handleClick.bind(this)
	}
	state = {
		selected: {
			'0': false,
			'1': false,
			'2': false,
			'3': false,
			'4': false,
			'5': false,
			'6': false
		}
	}
	handleClick = (index) => {
		let nextState = {...this.state.selected}
		nextState[index.toString()] = !nextState[index.toString()]
		let arr = []
		for(let i=0;i<7;i++){
			if(nextState[i.toString()] === true)
				arr.push(i)
		}
		this.setState({
			selected: nextState
		},() => this.props.onChange(arr))
	}
	renderCycle = () => {
		let arr = []
		for(let i=0; i<7; i++){
			arr.push(
				<DayCycle 
					onClick={()=>this.handleClick(i)}
					key={i} 
					selected={this.state.selected[i.toString()]} 
					char={dayName[i]}/>
			)
		}
		return arr
	}
	render = () => 
		<div 
			className={`${this.props.className}`}
			style={{
				display: 'flex',
				flexDirection: 'row',
				margin: '0 -4px',
				...this.props.style
			}}>
			{
				this.renderCycle()
			}
		</div>
}

export default DaysSelector

// $mainColor: #E0E5EC;
// $lightShadow: #FFFFFF;
// $darkShadow: #A3B1C6;

// $color1: #FFFFFF;
// $color2: #E3EDF7;
// $color3: #7998BC;
// $color4: #22304A;