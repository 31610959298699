import React, {Component, CSSProperties} from 'react'
import Neu, { DropDown } from './neu'
import './styles/unitSelector.scss'

import next from '../assets/images/next.svg'
import Next from '../components/svg/next'
import getSafe from '../utility/getSafe'


interface IPropsDropItem{
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  name?   : string
  children?: any
}

export interface ISelectorData{
  name          : string
}

interface IPropsSelector{
  className?    : string
  initialIndex? : number
  selected?     : number
  onChange?     : Function
  style?        : CSSProperties
  placeholder?  : string
  data          : ISelectorData[]

}

interface IStateSelector{
  selected      : number
}

const DropItem = (props: IPropsDropItem) => 
	<div onClick={props.onClick} className={'DropItem'}>
		<h5>{props.name}</h5>
	</div>

class Selector extends Component<IPropsSelector,IStateSelector>{
	constructor(props: IPropsSelector){
    super(props)
    this.state = {
      selected : -1
    }
	}
	componentDidMount = () => {
		try{
			if(this.props.initialIndex)
				this.setState({selected: this.props.initialIndex})
		}catch(e){
			console.error(e)
		}
	}
	handleClick = (index: number) => this.props.selected === undefined ? this.setState({
		selected: index
	},() => this.props.onChange ? this.props.onChange(this.state.selected) : {}) :
		this.props.onChange ? this.props.onChange(index) : null
	render = () => 
		<Neu 
			className={`PropertySelector ${this.props.className ? this.props.className : ''}`} style={{ ...this.props.style }}>
			<div className={'labelContainer'}>
				<h5>
				{
					this.props.selected !== undefined ? 
						this.props.selected < 0 || this.props.selected === undefined ? 
							this.props.placeholder ? this.props.placeholder : 'Choose Unit' : 
							this.props.data[this.props.selected].name
						:					
						this.state.selected < 0 || this.state.selected === undefined ? 
							this.props.placeholder ? this.props.placeholder : 'Choose Unit' : 
							this.props.data[this.state.selected].name
				}
				</h5>
			</div>
			<div className={'dropIconContainer'}>
				<Next style={{ width: 15, height: 15 }}/>
			</div>
			<DropDown>
			{ getSafe(() => this.props.data, []).map((object: ISelectorData,index: number) => 
				<DropItem 
					onClick={(e) => this.handleClick(index)}
					key={index}
					name={object.name}/>)}
			</DropDown>
		</Neu>
}

export default Selector