import React, { Component } from 'react'
import AuthContext from '../context/auth'

import Authenticator from '../utility/Authenticator'

import { v4 as uuidv4 } 		from 'uuid'

import Cookies 							from 'universal-cookie'
import API 									from '../api'
import { create } from 'domain'
const cookies = new Cookies()

interface IProps{

}

class Auth extends Component{
	constructor(props: IProps){
		super(props)
		this.onChangeCookie = this.onChangeCookie.bind(this)
		this.listener 			= Authenticator.addListener(this.onChangeCookie)
	}
	
	private listener: Auth

	componentDidMount = () => this.initAuth()

	state = {
		loggedIn		: undefined,
		uuid				: {
			_id				: null,
			platform	: null,
			createdAt	: null,
			registered: false
		},
		credential	: {
			id				: null,
			name			: null,
			token			: null,
			role			: null
		} 
	}
	onChangeCookie = (data: any) => {
		try{
			console.log('CookieChange', data)
			const { name: cookieName, value } = data
			if(!cookieName) throw new Error('cookie name is undefined') 
			if(!value) 			throw new Error('cookie value is undefined')
			if(cookieName == 'auth'){
				const json = JSON.parse(value)
				const { id, name, token, role } = json
				console.log(json)
				this.setState({ loggedIn: true, credential: { id, name, token, role }})
			}else if(cookieName == 'UUID'){

			}
			// else console.error(`Cookie change unreadable ${cookieName} ${value}`)
		}catch(e){ console.log(e) }
	}
	logOut = () => {
		try{
			cookies.remove('auth')
			this.setState({
				loggedIn: false
			})
		}catch(error){ console.error(error) }
	}
	createUUID = async () => {
		try{
			const uuid: String = uuidv4()
			const platform = navigator.platform
			// TODO SEND UUID TO SERVER
			let result
			let registered = false
			try{
				result = await API.registerUUID({ uuid, platform })
				if(result instanceof Error) throw result
				if(result.message == 'OK') registered = true
			}catch(e){ console.error(`UUID set failed ${e}`) }
			// if(result.message == 'OK') 
			cookies.set('UUID', JSON.stringify({ _id: uuid, platform, createdAt: new Date().toISOString(), registered }), { path: '/', maxAge: 2147483647 })
		}catch(e){ throw e }
	}
	initAuth = async () => {
		try{
			const myUUID = cookies.get('UUID')
			console.log('My UUID', myUUID)
			if(!myUUID)	await this.createUUID()
			try{
				if(myUUID.registered == false) await API.registerUUID({ uuid: myUUID._id, platform: navigator && navigator.platform ? navigator.platform : undefined})
			}catch(e){ console.error(`UUID registration failed ${e}`) }
			console.log('Authenticator :', Authenticator.isLoggedIn())
			console.log('User :', Authenticator.getUser())
			if(Authenticator.isLoggedIn())
				this.setState({
					...this.state,
					uuid				: myUUID,
					credential	: Authenticator.getUser(),
					loggedIn		: true
				})
			else this.setState({ uuid: myUUID, loggedIn: false })
		}catch(e){ console.error(e) }
	}
	render = () =>
		<AuthContext.Provider value={{
			loggedIn		: this.state.loggedIn,
			credential	: this.state.credential,
			logOut			: this.logOut
		}}>
			{this.props.children}
		</AuthContext.Provider>
}

export default Auth
	