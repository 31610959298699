import React, { Component } from 'react'
import {Helmet} 						from "react-helmet"
import Pages 								from './pages'
import './styles/global.scss'

import Auth 								from './state/auth'
import SidebarState 				from './state/sideBar'
import Modal 								from './state/modal'
import CloudMessaging 			from './state/cloudMessaging'
import ContextMenu 					from './state/contextMenuState'
import { BrowserRouter } 		from 'react-router-dom'

import { v4 as uuidv4 } 		from 'uuid'

import Cookies 							from 'universal-cookie'

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase 				from "firebase/app";
// Add the Firebase services that you want to use
import "firebase/auth"
import "firebase/firestore"
import "firebase/messaging"

import { config as firebaseConfig } from './configs/firebaseConfig'

const cookies = new Cookies()

interface IProps{

}

const sendTokenToServer = async (token: String) => {
	console.log(`SENDING ${token} to server`)
}

class App extends Component {
	constructor(props: IProps){ super(props) }
	componentDidMount = () => {
		try{
			if(!firebase.apps.length && firebase.messaging.isSupported()) firebase.initializeApp(firebaseConfig)
		}catch(err){ console.error(err) }
	}
	render = () => 
		// <BrowserRouter basename={'/pms/'}>
		<BrowserRouter>
		<CloudMessaging>
		<ContextMenu>
		<Modal>
		<Auth>
		<SidebarState>
		<div className={'App'}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>PMS</title>
				<link href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet"/>
				<link href="https://fonts.googleapis.com/css?family=Poppins&display=swap" 	rel="stylesheet"/>
				<link rel="shortcut icon" type="image/png" sizes="16x16"/>
				<link rel="canonical" href="https://dse.id"/>
				{/* https://realfavicongenerator.net/favicon_result?file_id=p1duh5rsod1jui156irkc3to7be6#.Xh1HzMYzZk8 */}
				<link rel="apple-touch-icon" 			sizes="180x180" href="/apple-touch-icon.png"/>
				<link rel="icon" type="image/png" sizes="32x32" 	href="/favicon-32x32.png"/>
				<link rel="icon" type="image/png" sizes="16x16" 	href="/favicon-16x16.png"/>
				<link rel="manifest" href="/site.webmanifest"/>
				{/* <link rel="manifest" href="/manifest.json"></link> */}
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000"/>
				<meta name="msapplication-TileColor" 	content="#2b5797"/>
				<meta name="theme-color" 							content="#000000"/>
				<meta property={'og:title' } 					content={'DS PMS'}/>
				<meta property={'og:url' } 						content={'https://pms.dse.id'}/>
				<meta property={'og:image' } 					content={'/favicon-32x32s.png'}/>
				<meta property={'og:description' } 		content={'DS Property management System'}/>
				<meta property={'og:type' } 					content={'website'}/>
				{/* <script src={'http://localhost:8097'}/> */}
				{/* ============================================ END ================================================ */}
			</Helmet>
			<Pages/>
		</div>
		</SidebarState>
		</Auth>
		</Modal>
		</ContextMenu>
		</CloudMessaging>
		</BrowserRouter>
}

export default App