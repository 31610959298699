import React, { Component, Fragment } from 'react'
import moment 												from 'moment'
import { object } 										from 'prop-types'
import Neu														from '../components/neu'
import Right 													from '../components/svg/right'
import Left 													from '../components/svg/left'

const dateArray = [14,15,16,17,18,19,20,21,22,23,24,25]
const unitTypeDate = [101, 102,103]

const rowStyle = {
	height					: 24,
	content					: '',
	display					: 'flex',
	justifyContent	: 'center',
	alignItems			: 'center'
}

const itemBoxStyle = {
	...rowStyle,
	width						: '100%',
}

const bookingItemStyle = {
	flex: 1,
	// backgroundColor: 'rebeccapurple',
	// borderRadius: 5
}

const bookingItemOptions = [{
	label: 'Check Out',
}]

const BookingItem = (props) => 
	<div 
		onContextMenu={props.onContextMenu}
		onClick={props.onClick}
		className={`bookingHover ${props.data.bookingId}`}
		style={{...bookingItemStyle, ...props.style, minWidth: 0}}>
		<h5 style={{minWidth: 0,overflow: 'hidden', whiteSpace: 'nowrap', color: 'white', textOverflow: 'ellipsis'}}>
			{props.data.customerName}
		</h5>
	</div>

class AvailabilityTable extends Component{
	constructor(props){
		super(props)
		this.scrollRef = React.createRef()
	}
	daily = []
	monthly = []
	state = {
		dateArray: [],
		dateIndex: 0,
		matrix: {}
	}
	handleTableItemClick = (e,stringDate, unitId) => {
		// console.log('FIRST!!')
		if (e.type === 'click') {
			// console.log('Left click')
		}else if(e.type === 'contextmenu'){
			// console.log('Right click')
			// console.log(e.clientX, e.clientY)
			e.preventDefault()
			const x = e.clientX
			const y = e.clientY
			// ? CHECK IF MATRIX IS EMPTY ELSE DO NOTHING
			console.log('StringDate', stringDate)
			console.log('UnitId', unitId)
			this.props.optionMenu([{
				label: 'Add Booking',
				onClick: () => console.log('Add Booking Clicked')
			}],x,y)
		}
	}
	handleBookingItemClick = (e, bookingObject) => {
		console.log('SECOND!!')
		e.stopPropagation()
		e.preventDefault()
		if (e.type === 'click') {
			// console.log('Left click')
		}else if(e.type === 'contextmenu'){
			// console.log('Right click')
			// console.log([],e.clientX, e.clientY)
			e.preventDefault()
			const x = e.clientX
			const y = e.clientY
			// ? HOW TO DECIDE WHICH OPTIONS TO SHOW ? NEED TO SPECIFY BOOKING ID
			// ? UNREPORTED CHECKOUT THERE IS A CHECKOUT OPTION
			const checkOut = false
			const reported = false
			// ? UNREPORTED ELECTRICITY THERE IS A REPORT OPTION
			// ? SHOW DETAIL ON ALL OCCATION // REMEMBER THE DISBURSE OPTION FOR MONTHLY
			// ? EARLY MONTHLY CHECKOUT OPTION SO WE CAN SELL THE ROOM FOR THE NEXT DAYS
			// ?
			console.log('bookingObject', bookingObject)
			this.props.optionMenu([{
					label: 'Show Detail',
					onClick: (event) => this.props.showDetail(bookingObject)
				},
				// ...(!checkOut ? [{
				// 	label: 'Check Out',
				// 	onClick: () => console.log('Check Out Clicked')
				// }]: []),
				// ...(!reported ? [{
				// 	label: 'Report',
				// 	onClick: () => console.log('Report Clicked')
				// }]: [])
			],x,y)
		}
	}
	calculateMatrix = () => {
		if(this.props.data.daily && this.props.data.monthly){
			let matrix = []
			if(matrix === undefined)
				matrix = {}
			const momentStartDate = moment(this.props.startDate)
			const momentEndDate = moment(this.props.endDate)
			let daily = this.props.data.daily
			daily.map(bookingObject => {
				let bookingId = bookingObject._id
				let term = bookingObject.term
				let customerName
				console.log('BOOKING OBJECT', bookingObject)
				if(bookingObject.customer && bookingObject.customer.name)
					customerName = bookingObject.customer.name
				// bookingObject.units.map((unitObject) => {
				// 	let checkInDate = moment(unitObject.checkInDate)
				// 	let checkOutDate = moment(unitObject.checkOutDate)
				// 	// Check if check in before assignd timeline
				// 	let dateDiff = checkInDate.diff(momentStartDate,'days')
				// 	let flagCheckInOverflow = false
				// 	let flagCheckOutOverflow = false
				// 	// ? What to do
				// 	// * put the bookings to start date, then add flag marker to indicate that check in is before assigned timeline
				// 	if(dateDiff < 0) // then check in is before timeline
				// 		flagCheckInOverflow = true
				// 	// Check if check out before assigned timeline
				// 	dateDiff = momentEndDate.diff(checkOutDate,'days')
				// 	if(dateDiff < 0)
				// 		flagCheckOutOverflow = true
					
				// 	if(flagCheckInOverflow)
				// 		checkInDate = momentStartDate.clone()
				// 	if(flagCheckOutOverflow)
				// 		checkOutDate = momentEndDate.clone()
				// 	// console.log('CheckOutDate', checkOutDate.format('YYYY-MM-DD'))
				// 	let daysLength = checkOutDate.diff(checkInDate,'days') + 1
				// 	// console.log(daysLength)
				// 	// console.log('checkOut diff', dateDiff)
				// 	if(matrix[checkInDate.format('YYYY-MM-DD')] === undefined)
				// 		matrix[checkInDate.format('YYYY-MM-DD')] = {}
				// 	if(matrix[checkInDate.format('YYYY-MM-DD')][unitObject.unitId] === undefined)
				// 		matrix[checkInDate.format('YYYY-MM-DD')][unitObject.unitId] = []
				// 	matrix[checkInDate.format('YYYY-MM-DD')][unitObject.unitId] = [
				// 		matrix[checkInDate.format('YYYY-MM-DD')][unitObject.unitId], 
				// 		{ bookingId, term, checkInOverflow: flagCheckInOverflow, checkOutOverflow: flagCheckOutOverflow, daysLength,customerName}
				// 	]
				// })
				bookingObject.roomRequests.map((roomRequest) => {
					let checkInDate = moment(roomRequest.checkInDate)
					let checkOutDate = moment(roomRequest.checkOutDate)
					// Check if check in before assignd timeline
					let dateDiff = checkInDate.diff(momentStartDate,'days')
					let flagCheckInOverflow = false
					let flagCheckOutOverflow = false
					// ? What to do
					// * put the bookings to start date, then add flag marker to indicate that check in is before assigned timeline
					if(dateDiff < 0) // then check in is before timeline
						flagCheckInOverflow = true
					// Check if check out before assigned timeline
					dateDiff = momentEndDate.diff(checkOutDate,'days')
					if(dateDiff < 0)
						flagCheckOutOverflow = true
					
					if(flagCheckInOverflow)
						checkInDate = momentStartDate.clone()
					if(flagCheckOutOverflow)
						checkOutDate = momentEndDate.clone()
					// console.log('CheckOutDate', checkOutDate.format('YYYY-MM-DD'))
					let daysLength = checkOutDate.diff(checkInDate,'days')
					// console.log(daysLength)
					// console.log('checkOut diff', dateDiff)
					if(matrix[checkInDate.format('YYYY-MM-DD')] === undefined)
						matrix[checkInDate.format('YYYY-MM-DD')] = {}
					roomRequest.units.map((unitId) => {
						if(matrix[checkInDate.format('YYYY-MM-DD')][unitId] === undefined)
							matrix[checkInDate.format('YYYY-MM-DD')][unitId] = []
						matrix[checkInDate.format('YYYY-MM-DD')][unitId] = [
							...matrix[checkInDate.format('YYYY-MM-DD')][unitId], 
							{ bookingId, term, checkInOverflow: flagCheckInOverflow, checkOutOverflow: flagCheckOutOverflow, daysLength,customerName}
						]
					})
					
				})
			})
			
			let monthly = this.props.data.monthly
			monthly.map(bookingObject => {
				let bookingId = bookingObject._id
				let term = bookingObject.term
				let customerName
				if(bookingObject.customer && bookingObject.customer.name)
					customerName = bookingObject.customer.name
				// if(bookingObject && bookingObject.units)
				// 	bookingObject.units.map((unitObject) => {
				// 		let checkInDate = moment(unitObject.checkInDate)
				// 		let checkOutDate = moment(unitObject.checkOutDate)
				// 		// Check if check in before assignd timeline
				// 		let dateDiff = checkInDate.diff(momentStartDate,'days')
				// 		let flagCheckInOverflow = false
				// 		let flagCheckOutOverflow = false
				// 		// ? What to do
				// 		// * put the bookings to start date, then add flag marker to indicate that check in is before assigned timeline
				// 		if(dateDiff < 0) // then check in is before timeline
				// 			flagCheckInOverflow = true
				// 		// Check if check out before assigned timeline
				// 		dateDiff = momentEndDate.diff(checkOutDate,'days')
				// 		if(dateDiff < 0)
				// 			flagCheckOutOverflow = true
						
				// 		if(flagCheckInOverflow)
				// 			checkInDate = momentStartDate.clone()
				// 		if(flagCheckOutOverflow)
				// 			checkOutDate = momentEndDate.clone()

				// 		let daysLength = checkOutDate.diff(checkInDate,'days')
				// 		// console.log(daysLength)
				// 		// console.log('checkOut diff', dateDiff)
				// 		if(matrix[checkInDate.format('YYYY-MM-DD')] === undefined)
				// 			matrix[checkInDate.format('YYYY-MM-DD')] = {}
				// 		if(matrix[checkInDate.format('YYYY-MM-DD')][unitObject.unitId] === undefined)
				// 			matrix[checkInDate.format('YYYY-MM-DD')][unitObject.unitId] = []
				// 		matrix[checkInDate.format('YYYY-MM-DD')][unitObject.unitId] = [
				// 			...matrix[checkInDate.format('YYYY-MM-DD')][unitObject.unitId], 
				// 			{ 
				// 				bookingId, 
				// 				term, 
				// 				checkInOverflow: flagCheckInOverflow, 
				// 				checkOutOverflow: flagCheckOutOverflow, 
				// 				daysLength,
				// 				customerName
				// 			}
				// 		]
				// 	})
				bookingObject.roomRequests.map((roomRequest) => {
					let checkInDate = moment(roomRequest.checkInDate)
					let checkOutDate = moment(roomRequest.checkOutDate)
					// Check if check in before assignd timeline
					let dateDiff = checkInDate.diff(momentStartDate,'days')
					let flagCheckInOverflow = false
					let flagCheckOutOverflow = false
					// ? What to do
					// * put the bookings to start date, then add flag marker to indicate that check in is before assigned timeline
					if(dateDiff < 0) // then check in is before timeline
						flagCheckInOverflow = true
					// Check if check out before assigned timeline
					dateDiff = momentEndDate.diff(checkOutDate,'days')
					if(dateDiff < 0)
						flagCheckOutOverflow = true
					console.log(roomRequest)
					console.error('flagCheckInOverflow', flagCheckInOverflow)
					if(flagCheckInOverflow)
						checkInDate = momentStartDate.clone()
					if(flagCheckOutOverflow)
						checkOutDate = momentEndDate.clone()
					// console.log('CheckOutDate', checkOutDate.format('YYYY-MM-DD'))
					let daysLength = checkOutDate.diff(checkInDate,'days') + 1
					if(flagCheckInOverflow)
						daysLength -= 1
					// console.log(daysLength)
					// console.log('checkOut diff', dateDiff)
					if(matrix[checkInDate.format('YYYY-MM-DD')] === undefined)
						matrix[checkInDate.format('YYYY-MM-DD')] = {}
					roomRequest.units.map((unitId) => {
						if(matrix[checkInDate.format('YYYY-MM-DD')][unitId] === undefined)
							matrix[checkInDate.format('YYYY-MM-DD')][unitId] = []
						matrix[checkInDate.format('YYYY-MM-DD')][unitId] = [
							...matrix[checkInDate.format('YYYY-MM-DD')][unitId], 
							{ bookingId, term, checkInOverflow: flagCheckInOverflow, checkOutOverflow: flagCheckOutOverflow, daysLength,customerName}
						]
					})
				})
			})
			console.debug('matrix', matrix)
			return matrix
		}
	}
	fillDateArray = (prevProps) => {
		try{
			if(this.props.startDate && this.props.endDate){
				let momentStartDate,momentEndDate 
				momentStartDate = moment(this.props.startDate, 'YYYY-MM-DD')
				momentEndDate = moment(this.props.endDate, 'YYYY-MM-DD')
				if(!momentStartDate.isValid() || !momentEndDate.isValid()) throw new Error('Invalid date ')
				let dateArray = []
				let diff = momentEndDate.diff(momentStartDate,'days') + 1
				for(let i=0;i<diff;i++) dateArray.push(momentStartDate.clone().add(i,'days').format('YYYY-MM-DD'))
				if(prevProps && prevProps.startDate && this.props.startDate && (this.props.startDate != prevProps.startDate)){
					let momentStartDate = moment(this.props.startDate,'YYYY-MM-DD')
					let prevMomentStartDate = moment(prevProps.startDate,'YYYY-MM-DD')
					diff = prevMomentStartDate.diff(momentStartDate,'days')
					console.log('diff', diff)
					this.initialScrollOffset = diff * 48
					console.log(this.initialScrollOffset)
				}else diff = undefined
				this.setState({
					dateArray: dateArray,
					matrix: this.calculateMatrix()
				})
			}	
		}catch(e){ console.error(e) }
	}
	componentDidMount = () => {
		if(this.props.data){
			if(this.props.data.daily)
				this.daily = this.props.data.daily
			if(this.props.data.monthly)
				this.monthly = this.props.data.monthly
		}
		this.fillDateArray()
	}
	componentDidUpdate = (prevProps, prevStates) => {
		if(	this.props.data 			!== prevProps.data 			|| 
				this.props.startDate 	!== prevProps.startDate || 
				this.props.endDate 		!== prevProps.endDate){
			if(this.props.data.daily) 	this.daily = this.props.data.daily
			if(this.props.data.monthly) this.monthly = this.props.data.monthly
			this.fillDateArray(prevProps)
		}
		// if(this.state.dateArray !== prevStates.dateArray && this.scrollRef && this.initialScrollOffset){
		// 	this.scrollRef.current.scrollLeft = this.initialScrollOffset
		// 	this.initialScrollOffset = undefined
		// }
	}
	handlePrevMonthLoad = () => {

	}
	handleNextMonthLoad = () => {
		
	}
	handleScrollEvent = (e) => {
		let element = e.target
		const index = Math.ceil(element.scrollLeft / 48)
		if(this.state.dateIndex !== index && (
			moment(this.state.dateArray[this.state.dateIndex],'YYYY-MM-DD').month() !== 
			moment(this.state.dateArray[index],'YYYY-MM-DD').month()
		)) this.setState({dateIndex: index})
		if(element.scrollWidth - element.scrollLeft === element.clientWidth)
			if(this.props.handleNextMonthLoad)
				this.props.handleNextMonthLoad()
		
		if(element.scrollLeft === 0)
			if(this.props.handlePrevMonthLoad)
				this.props.handlePrevMonthLoad()
	}
	renderMonth = () => this.state.dateArray && this.state.dateIndex >= 0 ? moment(this.state.dateArray[this.state.dateIndex]).format('YYYY MMMM') : null
	renderUnitLabel = (data) => {
		if(this.props.data && this.props.data.units)
			return (
				<>{ 
					this.props.data.units.map((object,index) => 
					<div 
						key={index}
						style={{
							...rowStyle,
							justifyContent: 'flex-end',
							marginBottom: 2,
							marginTop: 2
						}}>
						<h5>{object.name || 'Error'}</h5>
					</div>
				)}</>
			)
		else
			return null
	}
	renderBookingItem = (stringDate, unitId) => {
		try{
			// console.log(stringDate, unitId)
			// console.log(this.state.matrix[stringDate])
			if(this.state.matrix === undefined || !this.state.matrix instanceof Object)
				throw new Error('state matrix undefined')
			if(this.state.matrix[stringDate] === undefined || this.state.matrix[stringDate][unitId] === undefined)
				return null
			const array = this.state.matrix[stringDate][unitId]
			// console.log('THIS!!!', unitId)
			// console.log(stringDate)
			// console.log(array)
			let arr = []
			let currLength
			let customerName
			console.log('ARRAY', array)
			array.map((object,index) => {
				try{
					// console.log(object.bookingId)
					// console.log(object.daysLength)
					arr.push(
					<BookingItem key={index}
						onClick={(e) => this.handleBookingItemClick(e,object)}
						// onClick={(e) => console.log('eventx',e)}
						onContextMenu={(e) => this.handleBookingItemClick(e,object)}
						data={object}
						style={{
							zIndex: 999,
							flex: 1, 
							...(index == 0 && array.length == 1 ? {
								borderTopRightRadius: 5,
								borderBottomRightRadius: 5,
								marginRight: 4,
								borderBottomLeftRadius: 5,
								borderTopLeftRadius: 5,
								marginLeft: 4,
								width: 40}: {}),
							...(index == 0 ? {
									borderBottomLeftRadius: 5,
									borderTopLeftRadius: 5,
									marginLeft: 4,
									width: 44
								} : 
								index == array.length - 1 ? {
									borderTopRightRadius: 5,
									borderBottomRightRadius: 5,
									marginRight: 4,
									width: 44
								}: {}
						)}}/>)
					currLength = object.daysLength 
					customerName = object.customerName
				}catch(e){ console.error(e) }
			})
			console.debug('customerName', customerName)
			console.debug('currLength',currLength)
			return (
				<div 
					className={'bookingItemContainer'} 
					style={{
						position: "absolute",
						left: '50%',
						display: 'flex',
						flexDirection: 'row',
						height: 16,
						width: (48 * (currLength) + 2 * (currLength -1)),
						zIndex: 3
					}}>
					{/* <div style={{position: 'absolute', left: 5, width: '100%', zIndex: 9999}}>
						<h5 style={{width: '100%',color: 'white', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>
						{customerName}
						</h5>
					</div> */}
					{arr}
				</div>
			)
		}catch(e){ 
			console.error(e)
			return null
		}
	}
	renderUnitData = (stringDate) => {
		// * MAGICK HAPPENDS HERE
		console.log('magick', this.props.data)
		if(this.props.data && this.props.data.units){
			return (
				<>{
					this.props.data.units.map((object,index) => 
						<div key={index}  
							onClick={(e) => this.handleTableItemClick(e,stringDate, object._id)}
							onContextMenu={(e) => this.handleTableItemClick(e,stringDate, object._id)}
							className={'hover tableItem'} 
							style={{
								...itemBoxStyle,
								justifyContent	: 'flex-start',
								position				: 'relative',
								border					: '0.5px solid #2c2c2c'
							}}>
							{this.renderBookingItem(stringDate,object._id)}
						</div>)
				}</>
			)
		} else return null
	}
	renderData = () => {
		if(this.state.dateArray instanceof Array && this.state.dateArray.length != 0){
			let arr = []
			const now = moment()
			this.state.dateArray.map((string,i) => {
				let momentClone = moment(string,'YYYY-MM-DD')
				arr.push(
					<div 
						key={i}
						style={{
							display				: 'flex',
							flexDirection	: 'column',
							width					: 48,
							minWidth			: 48,
							maxWidth			: 48,
							border				: '0.5px solid #fff'
						}}>
						{/* DATE STRING */}
						<div style={{
							...itemBoxStyle, 
							backgroundColor					: momentClone.format('YYYY-MM-DD') == now.format('YYYY-MM-DD') ? 'yellow' : momentClone.day() == 0 ? '#ED3237' : (momentClone.day() == 6 || momentClone.day() == 5) ? 'green' : '#2c2c2c', 
							color										: momentClone.format('YYYY-MM-DD') == now.format('YYYY-MM-DD') ? 'red' : 'white',
							borderRadius						: 5,
							borderBottomLeftRadius	: 0,
							borderBottomRightRadius	: 0,
							display									: 'flex',
							flexDirection						: 'column',
							height									: 36
						}}>
							<h5>{momentClone.format('DD')}</h5>
							<h5>{momentClone.format('ddd')}</h5>
						</div>
						{this.renderUnitData(string)}
					</div>
				)
			})
			return arr
		}else return null
	}
	
	render = () => 
		this.state.dateArray.length == 0 ? null : 
		<div style={{
			padding: 16,
			display: 'flex',
			flexDirection: "row",
		}}>
			{/* LEFT */}
			<div style={{
				width: 'min-content',
				marginRight: 16,
			}}>
				<div style={{...rowStyle, height: 36}}/> {/* FIXED MONTH HEADER COMPENSATOR */}
				<div style={{...rowStyle, height: 36}}/> {/* FIXED DATE HEADER COMPENSATOR */}
				{this.renderUnitLabel()}
			</div>
			{/* RIGHT SCROLLABLE */}
			<div style={{
				display: 'flex',
				flex: 1,
				overflow: 'hidden',
				position: 'relative'
			}}>
				<div style={{ position: 'absolute', width: '100%', height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Neu
						onClick={() => this.props.prev(this.props.term, false)}
						style={{ width: 24, height: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#2c2c2c'}}
						state={false}>
						<Left style={{height: '50%', width: '50%', fill: '#FFF'}}/>
					</Neu>
					<h5 style={{marginLeft: 12, marginRight: 12}}>{this.renderMonth()}</h5>
					<Neu 
						onClick={() => this.props.next(this.props.term, true)}
						style={{ width: 24, height: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#2c2c2c'}}
						state={false}>
						<Right style={{height: '50%', width: '50%', fill: '#FFF'}}/>
					</Neu>
				</div>
				<div 
					ref={this.scrollRef}
					// onScroll={this.handleScrollEvent}
					style={{
						display: 'flex',
						flexDirection: 'column',
						flex: 1,
						overflowX: 'auto',
					}}>
					
					<div style={{height: 36}}/>
					{/* DATA RENDERER & SCROLLER */}
					<div style={{
						flex: 1,
						display: 'flex',
						flexDirection: "row",
					}}>
					{this.renderData()}
					</div>
				</div>
			</div>
		</div>
}

export default AvailabilityTable