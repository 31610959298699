import React, { Component } from 'react'
import './styles/tagInput.scss'
import Close from './svg/close'

export const Tag = (props) => 
	<div className={'TagLabel'}>
		<div className={'tagText'}>
			<h3>{props.label}</h3>
		</div>
		<div className={'TagDelete'}>
			<Close/>
		</div>
	</div>

class TagInput extends Component{
	constructor(props){
		super(props)
	}
	render = () => 
		<div className={'TagInput'}>
		{this.props.children}
		</div>
}

export default TagInput