import React, { Component } from 'react'
import './styles/inputField.scss'

class InputField extends Component{
	constructor(props){
		super(props)
	}
	render = () => 
		<div 
			className={`
				InputField ${this.props.className ? 
					this.props.className : ''} ${this.props.textarea ? 'textarea' : ''}`}>
		{
			!this.props.textarea ? 
				<input
					value={this.props.value}
					onChange={this.props.onChange}
					type={this.props.type} 
					placeholder={this.props.placeholder}/> :
				<textarea placeholder={this.props.placeholder}/>}
		</div>
}
InputField.defaultProps = {
	textarea:false
}

export default InputField