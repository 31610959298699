import React, { Component } from 'react'
import './styles/sidebar.scss'
// import logo from '../components/svg/logo'
import logo from '../assets/images/dsLogo.svg'
import Neu from './neu'

import SidebarState from '../context/sideBar'

import Dashboard from './svg/dashboard'
import detectMobile from '../utility/detectMobile'

export const Item = (props) => 
	<div 
		onClick={props.onClick}
		className={`Item ${props.active ? 'active' : ''}`}>
		<Dashboard className={'icon'}/>
		<label>{props.label}</label>
	</div>

class SideBar extends Component{
	constructor(props){
		super(props)
		this.handleMouseDown = this.handleMouseDown.bind(this)
	}
	state = {
		selected: 0,
	}
	handleMouseDown = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if(	this.props.sidebar.active && 
				this.navRef && 
				!this.navRef.contains(e.target)){
			// console.log('detected')			
			this.props.sidebar.setActive()
		}
	}
	componentDidMount = () => {
			// document.addEventListener('mousedown',this.handleMouseDown)
	}
	componentDidUpdate = () => {

	}
	componentWillUnmount = () => {
		// document.removeEventListener('mousedown',this.handleMouseDown)
	}
		
	render = () => 
		<div
			onMouseDown={this.handleMouseDown}
			style={{
				position: "fixed",
				left: 0,
				top: 0,
				width: this.props.sidebar.active ? '100vw' : 0,
				height: '100vh',
				zIndex: 111111111,
			}}>
		<div className={'blockPadding'}/>
		<nav 
			ref={(ref) => this.navRef = ref}
			className={`SideBar ${this.props.sidebar.active ? 'active' : ''}`}>
			<div 
				className={'overlay'}/>
			<div className={'titleContainer'}>
				<div className={'sideBarLogoContainer'}>
					<img src={logo}/>
				</div>
				<h1 className={'title'}>PMS</h1>
				{/* <Neu/> */}
			</div>
			<div className={'titleSeparator'}></div>
			{this.props.children}
		</nav>
		</div>
}
SideBar.defaultProps = {
	list: []
}
// export default SideBar

export default (props) =>
	<SidebarState.Consumer>
	{
		(value) => <SideBar sidebar={value} {...props}/>
	}	
	</SidebarState.Consumer>
	